<header *ngIf="title" class="box-dialog-header">
  <box-dialog-header [title]="title" alignment="center" (closeDialog)="closeDialog()"></box-dialog-header>
</header>
<main>
  <div *ngIf="messages.length > 0" class="dialog-text">
    <p *ngFor="let message of messages">{{ message | translate }}</p>
  </div>
</main>
<footer>
  <div *ngIf="userAction" class="dialog-buttons">
    <button box-button theme="primary" (click)="closeDialog()">{{ btnText | translate }}</button>
  </div>
</footer>
