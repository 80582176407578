import { GetTextByKeyType, ShopDetails } from '@box-types';

export { generateSEODescriptionHTML, generateShopDishesText, generateShopRatingText };

function generateShopDishesText(name: string, dishes: string[], translateFn: GetTextByKeyType): string {
  if (!name || !dishes?.length) return '';
  return translateFn('the_options_we_recommend_from_the_shop', {
    _SHOP_NAME: name,
    _DISHES: dishes.join(', ')
  });
}

function generateShopRatingText(name: string, location: string, rating: number, translateFn: GetTextByKeyType): string {
  if (!name || !location || !rating) return '';
  return translateFn('shop_rating_in_location', {
    _SHOP_NAME: name,
    _LOCATION: location,
    _RATING: String(rating)
  });
}

function generateSEODescriptionHTML(shopDetails: ShopDetails, template: string, translateFn: GetTextByKeyType): string {
  if (!shopDetails || !template) return undefined;
  const { location, deliveryLocations, name, address, ratingOverall, popularDishes } = shopDetails;
  return template
    .replace(/__SHOP_LOCATION__/gi, location.name)
    .replace(/__SHOP_DELIVERY_LOCATIONS__/gi, deliveryLocations.join(', '))
    .replace(/__SHOP_NAME__/gi, name)
    .replace(/__SHOP_ADDRESS__/gi, `${address?.street} ${address?.streetNo}`)
    .replace(/__SHOP_RATING_TEXT__/gi, generateShopRatingText(name, location.name, ratingOverall, translateFn))
    .replace(/__SHOP_DISHES_TEXT__/gi, generateShopDishesText(name, popularDishes, translateFn));
}
