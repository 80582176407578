<div class="info-notification-wrapper">
  <header class="info-notification-header">
    <mat-icon fontSet="material-icons-round" class="info-notification-header-icon">{{ matIcon }}</mat-icon>
    <h4 class="info-notification-header-title">{{ title | translate }}</h4>
    <button class="info-notification-header-close" mat-icon-button (click)="onClose()">
      <mat-icon fontSet="material-icons-round">close</mat-icon>
    </button>
  </header>
  <main class="info-notification-body">
    <p>{{ message | translate }}</p>
  </main>
</div>
