import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { CheckoutSuggestionGroup, PromoCampaign, Shop, CheckoutSuggestionBanner, APIError } from '@box-types';
import { PromoCampaignsService, SentryService, ShopService, CartService } from '@box-core/services';
import {
  filterOffersCompatibleWithPromoCampaign,
  filterProductsCompatibleWithPromoCampaign,
  promoCampaignToCheckoutSuggestionGroup,
  isPromoCampaignCartSuggestion,
  getPromoCampaignImage,
  getPromoCampaignText,
  getSuggestedProducts,
  normalizeOffer,
  normalizeProduct,
  generateImageSrc,
  PromoCampaignsItems,
  sortPromoCampaigns
} from '@box/utils';
import uniq from 'lodash-es/uniq';
import orderBy from 'lodash-es/orderBy';

@Injectable()
export class CartSuggestionService {
  public suggestionPromoCampaigns = new BehaviorSubject<PromoCampaign[]>([]);
  public readonly reminderCampaignsAddedInCheckout = new BehaviorSubject<string[]>([]);

  constructor(
    private promoCampaignsService: PromoCampaignsService,
    private shopService: ShopService,
    private cartService: CartService,
    private sentryService: SentryService
  ) {}

  public getCheckoutSuggestionBanners$(): Observable<CheckoutSuggestionBanner[]> {
    const shop = this.shopService.getShop();
    const checkoutPromoCampaigns = this.getCheckoutSuggestionBannerCampaigns(shop);
    if (!checkoutPromoCampaigns?.length) return of([] as CheckoutSuggestionBanner[]);

    const { collectionType, supermarketGroup } = shop;
    const options = {
      promoCampaigns: checkoutPromoCampaigns.map((promoCampaign) => promoCampaign._id),
      supermarketGroup
    };

    return this.promoCampaignsService.fetchPromoCampaignsItems(collectionType, options).pipe(
      map((response) => {
        const decoratedCheckoutPromoCampaigns = this.decoratePromoCampaignsWithItems(
          checkoutPromoCampaigns,
          response.campaigns
        );
        const bestCampaignsWithUniqueSynergy = this.getBestCampaignsWithUniqueSynergy(decoratedCheckoutPromoCampaigns);
        const suggestionBanners = bestCampaignsWithUniqueSynergy.reduce((banners, bestCampaignOfSynergy) => {
          const groups = this.getShopCheckoutSuggestionGroups(bestCampaignOfSynergy, decoratedCheckoutPromoCampaigns);
          if (!groups?.length) return banners;
          const banner = {
            campaignName: bestCampaignOfSynergy.name,
            subtitle: getPromoCampaignText(bestCampaignOfSynergy, 'reminderTitle'),
            groups,
            campaignType: bestCampaignOfSynergy.type,
            points: bestCampaignOfSynergy.marketPlacePointsAmount,
            multiplier: bestCampaignOfSynergy.multiplier,
            image: generateImageSrc(getPromoCampaignImage(bestCampaignOfSynergy, 'inShopPlacementImage')),
            cardImage: generateImageSrc(getPromoCampaignImage(bestCampaignOfSynergy, 'smallCartSuggestionLogo')),
            priority: bestCampaignOfSynergy.priority
          };
          banners.push(banner);
          return banners;
        }, [] as CheckoutSuggestionBanner[]);
        return orderBy(suggestionBanners, ['multiplier', 'points', 'priority'], ['desc', 'desc', 'desc']);
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Checkout Page',
          domainDetails: 'Checkout Suggestion Banners',
          severity: 'error'
        });
        return of([] as CheckoutSuggestionBanner[]);
      })
    );
  }

  private decoratePromoCampaignsWithItems(
    promoCampaigns: PromoCampaign[],
    campaignsItems: PromoCampaignsItems[]
  ): PromoCampaign[] {
    if (!promoCampaigns?.length) return [];
    if (!campaignsItems?.length) return promoCampaigns;

    return promoCampaigns.map((campaign) => {
      const campaignItems = campaignsItems.find((campaignItem) => campaignItem.name === campaign.name);
      if (!campaignItems) return campaign;

      const normalizedResponseOffers = campaignItems.offers.map((offer) => normalizeOffer(offer));
      const normalizedResponseProducts = campaignItems.products.map((product) => normalizeProduct(product));
      const usersFrequentPlates = this.shopService.getUserFrequentPlates();
      const suggestedProducts = getSuggestedProducts(normalizedResponseProducts, usersFrequentPlates);
      const suggestedProductsIds = suggestedProducts.map((product) => product._id);
      const decoratedOffers = normalizedResponseOffers.map((offer) =>
        this.shopService.offerDecorator(offer, suggestedProductsIds)
      );
      const decoratedProducts = normalizedResponseProducts.map((product) => this.shopService.decorateProduct(product));

      return { ...campaign, products: decoratedProducts, offers: decoratedOffers };
    });
  }

  /* The getTriggeredByTheClientCartPromoCampaigns and getCheckoutSuggestionBannerCampaigns have a lot of
shared code that we could remove during Housekeeping. */
  private getTriggeredByTheClientCartPromoCampaigns(): PromoCampaign[] {
    const shop = this.shopService.getShop();
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    return promoCampaigns.filter((promoCampaign) => {
      if (!promoCampaign.triggeredByClient) return false;
      const isCartSuggestion = isPromoCampaignCartSuggestion(promoCampaign);
      if (!isCartSuggestion) return false;
      const isShopPromoCampaign = shop.promoCampaigns.includes(promoCampaign.name);
      if (!isShopPromoCampaign) return false;
      const isUsed = this.cartService.cartHasPromoCampaign(promoCampaign);
      if (isUsed) return true;
      const usedCategory = this.shopService.cartHasProductsThatBelongToCategory(promoCampaign);
      if (usedCategory) return true;
      return false;
    });
  }

  private getCheckoutSuggestionBannerCampaigns(shop: Shop): PromoCampaign[] {
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    return promoCampaigns.filter((promoCampaign) => {
      const isCartSuggestion = isPromoCampaignCartSuggestion(promoCampaign);
      if (!isCartSuggestion) return false;
      const isShopPromoCampaign = shop.promoCampaigns.includes(promoCampaign.name);
      if (!isShopPromoCampaign) return false;
      const isUsed = this.cartService.cartHasPromoCampaign(promoCampaign);
      if (isUsed) return false;
      const usedCategory = this.shopService.cartHasProductsThatBelongToCategory(promoCampaign);
      if (usedCategory) return false;
      return true;
    });
  }

  public getEligibleReminderCampaignsTriggeredByClient(): string[] {
    const reminderCampaigns = this.getTriggeredByTheClientCartPromoCampaigns();
    if (!reminderCampaigns?.length) return [];
    const productsAddedFromCheckout = this.shopService.getCartProductsAddedFromCheckout();
    const offersAddedFromCheckout = this.shopService.getCartOffersAddedFromCheckout();
    if (!productsAddedFromCheckout?.length && !offersAddedFromCheckout?.length) return [];
    return reminderCampaigns
      .filter((campaign) => {
        const productMatch = Boolean(
          filterProductsCompatibleWithPromoCampaign(campaign, productsAddedFromCheckout)?.length
        );
        if (productMatch) return true;
        const offerMatch = Boolean(filterOffersCompatibleWithPromoCampaign(campaign, offersAddedFromCheckout)?.length);
        if (offerMatch) return true;
      })
      .map((campaign) => campaign.name);
  }

  private getShopCheckoutSuggestionGroups(
    decoratedTargetCampaign: PromoCampaign,
    decoratedPromoCampaignsWithItems: PromoCampaign[]
  ): CheckoutSuggestionGroup[] {
    const campaignsWithSameSynergy = decoratedPromoCampaignsWithItems.filter(
      (campaign) =>
        Boolean(decoratedTargetCampaign.synergyTitle) && campaign.synergyTitle === decoratedTargetCampaign.synergyTitle
    );
    const campaignsToGenerateGroupsFor =
      campaignsWithSameSynergy?.length > 0 ? campaignsWithSameSynergy : [decoratedTargetCampaign];
    const sortedCampaignsWithSameSynergy = sortPromoCampaigns(campaignsToGenerateGroupsFor);

    const previousGroupProductsIds: string[] = [];
    return sortedCampaignsWithSameSynergy.reduce((groups, promoCampaign) => {
      // each group must not show products that belong in the previous group
      const filteredPromoProducts = promoCampaign.products.filter(
        (product) => !previousGroupProductsIds.includes(product._id)
      );
      const filteredPromoProductsIds = filteredPromoProducts.map((product) => product._id);
      previousGroupProductsIds.push(...filteredPromoProductsIds);
      if (!filteredPromoProducts?.length) return groups;

      /**
       * We found a gap in the Web Applciation with the new Offer Types feature, where we cannot use Offer Wizard
       * and Product MYO on the Checkout, making the usage of Cart Suggestion Banners with Offers obsolete. We will
       * be disabling all the Cart Suggestion Offers from the Checkout until we find the solution, to minimize the
       * production impact. This logic needs to be updated when we implement offers logic in checkout suggestion banners
       * */
      const group = promoCampaignToCheckoutSuggestionGroup(promoCampaign, filteredPromoProducts, []);
      groups.push(group);
      return groups;
    }, [] as CheckoutSuggestionGroup[]);
  }

  private getBestCampaignsWithUniqueSynergy(promoCampaigns: PromoCampaign[]): PromoCampaign[] {
    if (!promoCampaigns?.length) return [];
    // if a campaign has no synergyTitle it will also be returned
    const campaignsWithoutSynergy = promoCampaigns.filter((campaign) => !campaign.synergyTitle);
    const campaignsWithSynergy = promoCampaigns.filter((campaign) => campaign.synergyTitle);
    const uniqueSynergies = uniq(campaignsWithSynergy.map((campaign) => campaign.synergyTitle));
    const bestCampaignsWithUniqueSynergy = uniqueSynergies.map((title) => {
      const sameSynergyCampaigns = campaignsWithSynergy.filter((campaign) => campaign.synergyTitle === title);
      return sortPromoCampaigns(sameSynergyCampaigns)[0];
    });
    return sortPromoCampaigns([...campaignsWithoutSynergy, ...bestCampaignsWithUniqueSynergy]);
  }
}
