import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'allergens-info',
  templateUrl: './allergens-info.component.html',
  styleUrls: ['./allergens-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllergensInfoComponent {
  @Input() public shopPhone: string;
}
