<div class="box-dialog-wrapper">
  <box-dialog-header
    class="gb-promo-dialog-header transparent-header"
    [alternativeTheme]="true"
    [buttonBackground]="true"
    (closeDialog)="closeDialog()"
  ></box-dialog-header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <figure class="gb-promo-dialog-image">
      <img [src]="imageUrl" [alt]="title" />
    </figure>
    <section class="gb-promo-dialog-section">
      <h2 class="gb-promo-dialog-title">{{ title }}</h2>
      <rewards-promo-banner></rewards-promo-banner>
    </section>
  </main>
  <footer *ngIf="true" #dialogFooter class="box-dialog-footer">
    <section class="gb-promo-dialog-footer-section">
      <button box-button theme="main" (click)="onOrderNowClick()">{{ 'order_now_exclamation' | translate }}</button>
    </section>
  </footer>
</div>
