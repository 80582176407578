<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <header class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main class="box-dialog-content">
    <section class="gdpr-consent-dialog-section">
      <p class="gdpr-consent-dialog-text ods-typography-body-m-bold gdpr-consent-dialog-description">
        {{ description }}
      </p>
      <div class="gdpr-consent-dialog-container">
        <div class="gdpr-consent-dialog-blocks">
          <div *ngFor="let block of consentBlocks" class="gdpr-consent-dialog-block">
            <img [src]="block.imageSrc" class="gdpr-consent-dialog-block-image" />
            <p class="gdpr-consent-dialog-block-text ods-typography-body-m-bold gdpr-consent-dialog-text">
              {{ block.text }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer class="box-dialog-footer gdpr-consent-dialog-footer">
    <div class="gdpr-consent-dialog-footer-buttons-container">
      <button box-button theme="secondary" class="gdpr-consent-dialog-footer-button" (click)="onGDPRConsent(false)">
        {{ 'no_do_not_want' | translate }}
      </button>
      <button box-button theme="primary" class="gdpr-consent-dialog-footer-button" (click)="onGDPRConsent(true)">
        {{ 'yes_want' | translate }}
      </button>
    </div>
    <div class="gdpr-consent-dialog-footer-buttons-container gdpr-consent-dialog-footer-buttons-container-last">
      <button
        box-button
        theme="secondary"
        borders="none"
        size="small"
        class="gdpr-consent-dialog-footer-button"
        (click)="onClickReadMore()"
      >
        {{ 'see_in_depth' | translate }}
      </button>
    </div>
  </footer>
</div>
