import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService, DialogService } from '@box-core/services';
import { Review, APIError, CreateReviewOptions, GAItemRatingConfig } from '@box-types';
import { finalize } from 'rxjs/operators';
import { ReviewDialogService } from './review-dialog.service';
import {
  ReviewDialogData,
  ReviewDialogRecommendation,
  ReviewDialogState,
  ReviewDialogResponse
} from './review-dialog.types';
import { Subscription } from 'rxjs';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { FormControl, Validators } from '@angular/forms';
import { ratingValidator } from '@box-shared/validators';

@Component({
  selector: 'review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss'],
  providers: [ReviewDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public ratingFormControl: FormControl;
  public loading: boolean;
  public state: ReviewDialogState = 'PRE_SUBMIT';
  public placeholder: string;
  public recommendations: ReviewDialogRecommendation[];
  public isOrderReviewPositive: boolean;
  public rating: number;
  public showDeliveryRating: boolean;
  public deliveryRating: number;
  public deliveryRatingTitle: string;
  public recommendationsTitle: string;
  public claimablePoints: number;
  public canSubmit: boolean;

  private orderFriendlyId: string;
  private review: Review;
  private stateSubscription: Subscription;
  private canSubmitSubscription: Subscription;

  constructor(
    public renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) private data: ReviewDialogData,
    private dialogRef: MatDialogRef<ReviewDialogComponent>,
    private dialogService: DialogService,
    private reviewDialogService: ReviewDialogService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setStateSubscription();
    this.showDeliveryRating = this.reviewDialogService.shouldShowDeliveryRating(this.data.order);
    this.deliveryRatingTitle = this.reviewDialogService.generateDeliveryRatingTitle(this.data.order);
    this.recommendations = this.reviewDialogService.generateOrderRecommendations(this.data.order);
    this.recommendationsTitle = this.reviewDialogService.getRecommendationsTitle(this.data.order);
    this.setDeliveryRating(this.data.deliveryRating);
    this.setRating(this.data.rating);
    this.orderFriendlyId = this.data.order.friendlyId;
    this.claimablePoints = this.reviewDialogService.getClaimablePoints();
    this.canSubmit = this.getCanSubmitValue();
    this.setRatingFormControl();
    this.setCanSubmitValueSubscription();
    this.initializeRatingFormControlValue();
  }

  ngOnDestroy(): void {
    this.stateSubscription?.unsubscribe();
    this.canSubmitSubscription?.unsubscribe();
  }

  private setStateSubscription(): void {
    this.stateSubscription = this.reviewDialogService.state$.subscribe((state) => (this.state = state));
  }

  private getCanSubmitValue(): boolean {
    if (!this.showDeliveryRating) return Boolean(this.rating);
    return Boolean(this.rating) && Boolean(this.deliveryRating) && this.isRatingFormValid();
  }

  private setCanSubmitValueSubscription(): void {
    this.canSubmitSubscription = this.ratingFormControl.valueChanges.subscribe(
      () => (this.canSubmit = this.getCanSubmitValue())
    );
  }

  public closeDialog(): void {
    this.dialogRef.close({ review: this.review } as ReviewDialogResponse);
  }

  public onChangeDeliveryRating(rating: number): void {
    this.setDeliveryRating(rating);
    this.canSubmit = this.getCanSubmitValue();
  }

  public onChangeRating(rating: number): void {
    this.setRating(rating);
    this.canSubmit = this.getCanSubmitValue();
  }

  public onSubmit(): void {
    if (!this.canSubmit) return;
    const products = this.reviewDialogService.getReviewProductsFromRecommendations(this.rating, this.recommendations);
    const options = { orderFriendlyId: this.orderFriendlyId, rating: this.rating, products } as CreateReviewOptions;
    if (this.showDeliveryRating) options.deliveryRating = this.deliveryRating;
    if (this.getRatingComment()) options.comment = this.getRatingComment();
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.reviewDialogService
      .createReview(options)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (review: Review) => {
          this.review = review;
          this.state = 'POST_SUBMIT';
          if (this.claimablePoints) this.reviewDialogService.addUserPoints(this.claimablePoints);
          this.triggerAnalyticsRatingEvent(this.data.source, this.rating);
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private setDeliveryRating(rating: number): void {
    this.deliveryRating = rating ?? 0;
  }

  private setRating(rating: number): void {
    this.rating = rating ?? 0;
    this.isOrderReviewPositive = this.reviewDialogService.isRatingPositive(rating);
    this.placeholder = this.reviewDialogService.getReviewPlaceholder(rating);
  }

  private triggerAnalyticsRatingEvent(source: string, rating: number): void {
    const gaConfig = {
      rating: String(rating),
      source: source
    } as GAItemRatingConfig;
    this.analyticsService.addGACustomEvent('item_rating_submit', gaConfig);
  }

  private initializeRatingFormControlValue(): void {
    this.ratingFormControl.setValue('');
    this.ratingFormControl.markAsTouched({ onlySelf: true });
  }

  private setRatingFormControl(): void {
    this.ratingFormControl = new FormControl('', Validators.compose([Validators.maxLength(1500), ratingValidator()]));
  }

  private isRatingFormValid(): boolean {
    if (!this.ratingFormControl.valid) return false;
    return true;
  }

  private getRatingComment(): string {
    const comment = this.ratingFormControl.value as string;
    return comment;
  }
}
