import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@box-env/environment';
import { AuthenticationService } from '@box-core/services';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private readonly COSMOTE_ACCOUNT_URL: string = environment.cosmote.ACCOUNT_URL;
  private readonly BOX_API_URL: string = environment.application.API_URL;
  private readonly ORDERS_API_URL: string = environment.application.ORDERS_API_URL;

  constructor(private authenticationService: AuthenticationService, private languageService: LanguageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = {} as Record<string, string>;

    if (request.url.includes(this.COSMOTE_ACCOUNT_URL)) {
      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';
      const token = this.authenticationService.COSMOTE_ACCESS_TOKEN;
      if (token) headers.Authorization = `Bearer ${token}`;
    }

    if (request.url.includes(this.BOX_API_URL) || request.url.includes(this.ORDERS_API_URL)) {
      headers.appId = 'box-web';
      headers['App-Version'] = String(environment.application.VERSION);
      headers['App-Build-Number'] = String(environment.application.BUILD);
      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';
      const languageCode = this.languageService.getLanguageCode();
      if (languageCode) headers['Accept-Language'] = languageCode;

      if (!request.url.includes('initiateBoxSession')) {
        const token = this.authenticationService.BOX_TOKEN;
        if (token) headers.Authorization = `Bearer ${token}`;
      }
    }

    // if you don't set the headers you are going to get a 401 unauthorized error
    // even if you are a guest user
    return next.handle(request.clone({ setHeaders: headers }));
  }
}
