<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="mobile_confirmation" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="verification-dialog-content">
      <div class="verification-dialog-image-container">
        <figure class="verification-dialog-image">
          <img src="/assets/images/logo/logo.svg" alt="BOX" />
        </figure>
      </div>

      <p class="verification-dialog-description">{{ description | translate }}</p>

      <ng-container *ngIf="state === 'PHONE'; else verificationField">
        <mat-form-field class="verification-dialog-field">
          <mat-label>{{ 'mobile_' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            name="phoneInput"
            (keydown.enter)="onSendCode()"
            [formControl]="phoneControl"
          />
          <mat-error>{{ 'mobile_number_is_invalid' | translate }}</mat-error>
        </mat-form-field>
        <button
          box-button
          theme="primary"
          class="verification-dialog-button"
          [disabled]="phoneControl.invalid"
          (click)="onSendCode()"
        >
          {{ 'next_' | translate }}
        </button>
      </ng-container>
      <ng-template #verificationField>
        <mat-form-field class="verification-dialog-field">
          <mat-label>{{ 'verification_code' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            name="verificationCode"
            (keydown.enter)="onVerification()"
            [formControl]="verificationControl"
          />
          <mat-error>{{ 'verification_code_error_text' | translate }}</mat-error>
        </mat-form-field>
        <button
          box-button
          theme="primary"
          class="verification-dialog-button"
          [disabled]="verificationControl.invalid"
          (click)="onVerification()"
        >
          {{ 'next_' | translate }}
        </button>
      </ng-template>

      <footer class="verification-dialog-footer">
        <p class="verification-dialog-footer-description ods-typography-body-m-regular">
          {{ 'for_every_problem_call_our_team' | translate }}
          <a [attr.href]="'tel:' + supportPhoneNumber" class="verification-dialog-footer-description-number">{{
            supportPhoneNumber
          }}</a
          >{{ 'every_day_time_for_help' | translate }}
        </p>
        <verification-resend-code
          #verificationResendCodeComponent
          *ngIf="state === 'PASSWORD'"
          (send)="onSendCode()"
        ></verification-resend-code>
      </footer>
    </div>
  </main>
</div>
