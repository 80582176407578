<section *ngIf="breadcrubmData.length > 0" class="page-section breadcrumb-section">
  <div class="container">
    <div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-absolute': isShopPage }">
      <div class="static-block"></div>
      <div class="breadcrumb">
        <li
          *ngFor="let breadcrumb of breadcrubmData; let last = last"
          class="breadcrumb-item ods-typography-body-m-bold"
        >
          <a *ngIf="!last" [routerLink]="breadcrumb.item" routerLinkActive="router-link-active" class="breadcrumb-link">
            {{ breadcrumb.name | translate }}
          </a>
          <span *ngIf="last" class="active">
            {{ breadcrumb.name | translate }}
          </span>
          <mat-icon [ngClass]="{ 'last-child': last }" class="icon" fontSet="material-icons-round"
            >keyboard_arrow_right
          </mat-icon>
        </li>
      </div>
    </div>
  </div>
</section>
