<ng-container *ngIf="visible">
  <ng-container *ngIf="loading; else loadedContent">
    <div class="bank-points-wrapper bank-points-wrapper-skeleton"></div>
  </ng-container>
  <ng-template #loadedContent>
    <div class="bank-points-wrapper">
      <div class="bank-points-left-container" [ngSwitch]="state">
        <ng-container *ngSwitchCase="'UNVERIFIED'">
          <figure *ngIf="checkoutBigLogoUrl" class="bank-points-left-container-image">
            <img [src]="checkoutBigLogoUrl" [alt]="loyaltyName" />
          </figure>
        </ng-container>
        <ng-container *ngSwitchCase="'VERIFIED'">
          <div class="bank-points-left-container-bg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 64">
              <g fill-rule="evenodd">
                <path d="M0 0h76L64 64H0z" />
                <path opacity=".597" d="M0 64h76L64 0H0z" />
              </g>
            </svg>
            <figure *ngIf="checkoutSmallLogoUrl" class="bank-points-left-container-bg-image">
              <img [src]="checkoutSmallLogoUrl" [alt]="loyaltyName" />
            </figure>
            <span class="bank-points-left-container-bg-euros">
              {{ verifiedViewEuros }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'SELECTED'">
          <div class="bank-points-left-container-bg bank-points-left-container-bg-active">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 64">
              <g fill-rule="evenodd">
                <path d="M0 0h76L64 64H0z" />
                <path opacity=".597" d="M0 64h76L64 0H0z" />
              </g>
            </svg>
            <figure *ngIf="checkoutSmallLogoUrl" class="bank-points-left-container-bg-image">
              <img [src]="checkoutSmallLogoUrl" [alt]="loyaltyName" />
            </figure>
            <span class="bank-points-left-container-bg-euros"> {{ discount / 100 }}{{ 'currency' | translate }} </span>
          </div>
        </ng-container>
      </div>
      <div class="bank-points-right-container" [ngSwitch]="state">
        <ng-container *ngSwitchCase="'UNVERIFIED'">
          <div class="bank-points-right-container-points">
            <span class="ods-typography-body-s-bold">{{ 'redeem_' | translate }} </span>
            <ng-container *ngIf="checkoutPointsImageUrl; else noPointsImage">
              <figure class="bank-points-right-container-small-image">
                <img [src]="checkoutPointsImageUrl" [alt]="loyaltyName" />
              </figure>
            </ng-container>
            <ng-template #noPointsImage
              ><span class="ods-typography-body-s-bold">{{ 'the_points' | translate }}</span></ng-template
            >
          </div>
          <span class="ods-typography-body-s-bold">{{ 'and_get_a_discount' | translate }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'VERIFIED'">
          <div class="bank-points-right-container-points bank-points-right-container-budget">
            <span class="ods-typography-body-s-bold">{{ 'you_have' | translate }} </span>
            <span class="ods-typography-body-s-bold">{{ cardLoyaltyInfo?.points ?? 0 }} </span>
            <ng-container *ngIf="checkoutPointsImageUrl; else noPointsImage">
              <figure class="bank-points-right-container-small-image">
                <img [src]="checkoutPointsImageUrl" [alt]="loyaltyName" />
              </figure>
            </ng-container>
            <ng-template #noPointsImage
              ><span class="ods-typography-body-s-bold">{{ 'the_points' | translate }}</span></ng-template
            >
          </div>
          <span class="ods-typography-body-s-bold">{{
            'redeem_points_and_earn_discount_2'
              | translate: { _POINTS: verifiedViewPoints, _DISCOUNT: verifiedViewEuros }
          }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'SELECTED'">
          <div class="bank-points-right-container-points">
            <span class="ods-typography-body-s-bold">{{ 'redeeming_' | translate }} </span>
            <span class="ods-typography-body-s-bold">{{ pointsToRedeem }} </span>
            <ng-container *ngIf="checkoutPointsImageUrl; else noPointsImage">
              <figure class="bank-points-right-container-small-image">
                <img [src]="checkoutPointsImageUrl" [alt]="loyaltyName" />
              </figure>
            </ng-container>
            <ng-template #noPointsImage
              ><span class="ods-typography-body-s-bold">{{ 'the_points' | translate }}</span></ng-template
            >
          </div>
          <span class="ods-typography-body-s-bold">{{
            'and_earn_x_discount' | translate: { _DISCOUNT: (discount / 100).toString() }
          }}</span>
        </ng-container>
      </div>
      <div class="bank-points-action">
        <div class="bank-points-slide-container">
          <mat-slide-toggle
            color="primary"
            class="bank-points-slide"
            [checked]="state === 'SELECTED'"
            (change)="onSlideChange($event)"
          ></mat-slide-toggle>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
