import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { LanguageService } from '@box-core/services/language.service';
import { CheckoutBagsConfig } from '@box-types';

@Injectable()
export class CheckoutBagsService {
  constructor(private decimalPipe: DecimalPipe, private languageService: LanguageService) {}

  public getBagsQuantity(bagsConfig: CheckoutBagsConfig): number {
    if (!bagsConfig) return 0;
    if (bagsConfig.dontCalculate) {
      return bagsConfig.bagsQuantity > 0 ? bagsConfig.bagsQuantity : 0;
    }
    if (bagsConfig.cartQuantity <= 0) return 0;
    return Math.ceil((bagsConfig.cartQuantity ?? 0) / 8);
  }

  public getBagsTotalPrice(bagsConfig: CheckoutBagsConfig): number {
    if (!bagsConfig) return 0;
    if (bagsConfig.plasticBagPrice <= 0) return 0;
    return this.getBagsQuantity(bagsConfig) * bagsConfig.plasticBagPrice;
  }

  public getBagsDescription(bagsConfig: CheckoutBagsConfig): string {
    if (!bagsConfig) return '';
    const bagsQuantity = this.getBagsQuantity(bagsConfig);
    if (!bagsQuantity || bagsQuantity <= 0) return '';
    const priceText = this.decimalPipe.transform((bagsConfig.plasticBagPrice ?? 0) / 100, '1.2-2');
    return this.languageService.getTextByKey(bagsQuantity === 1 ? 'bag_quantity_env_fee' : 'bags_quantity_env_fee', {
      _BAGS_QUANTITY: String(bagsQuantity),
      _PRICE_TEXT: priceText
    });
  }
}
