import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckoutSynergyDialogData, GetTextByKeyType, PromoCampaignBanner, Shop } from '@box-types';
import { CheckoutSynergyDialogService } from '@box-checkout/components/checkout-synergy-dialog/checkout-synergy-dialog.service';
import { LanguageService } from '@box-core/services/language.service';

@Component({
  selector: 'checkout-synergy-dialog',
  templateUrl: './checkout-synergy-dialog.component.html',
  styleUrls: ['./checkout-synergy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CheckoutSynergyDialogService]
})
export class CheckoutSynergyDialogComponent implements OnInit {
  public synergyBanners: PromoCampaignBanner[];
  public dialogHeaderImage: string;
  public dialogHeaderAlt: string;
  public shopName: string;

  private shop: Shop;
  public readonly t: GetTextByKeyType; // for the template to use

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CheckoutSynergyDialogData,
    private dialogRef: MatDialogRef<CheckoutSynergyDialogComponent>,
    private checkoutSynergyDialogService: CheckoutSynergyDialogService,
    private languageService: LanguageService
  ) {
    this.t = this.languageService.getTextByKey.bind(this.languageService);
  }

  ngOnInit(): void {
    const { shop, synergyBanners } = this.data;
    this.synergyBanners = this.checkoutSynergyDialogService.initializeSynergyBanners(synergyBanners, shop);
    this.shopName = this.shop?.name;
    this.dialogHeaderImage = this.checkoutSynergyDialogService.getSynergyDialogImage(this.synergyBanners);
    this.dialogHeaderAlt = this.checkoutSynergyDialogService.getSynergyDialogImageAlt(this.synergyBanners);
  }

  public onClose(data?: unknown): void {
    this.dialogRef.close(data);
  }
}
