import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { Address } from '@box-types';
import { getAddressIcon } from '@box/utils';

@Component({
  selector: 'address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent implements OnChanges {
  @Input() public address: Address;
  public addressIconPath: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.address = changes.address.currentValue as Address;
    this.addressIconPath = getAddressIcon(this.address);
  }
}
