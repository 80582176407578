import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@box-env/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIResponse, Coupon, CouponCheckOptions, FetchCouponsForCheckoutOptions } from '@box-types';
import {
  isCouponValid,
  sortAvailableCoupons,
  sortUnavailableCoupons,
  isCouponKnown,
  ObservableCacheDecorator
} from '@box/utils';
import pickBy from 'lodash-es/pickBy';
import { ConfigurationService } from './configuration.service';

const AVAILABLE_COUPONS_SESSION_EXPIRATION = 2 * 60 * 1000; // 2 minutes
const UNAVAILABLE_COUPONS_SESSION_EXPIRATION = 2 * 60 * 1000; // 2 minutes
const invalidationSignalCouponShopSource = new Subject<string>();
const invalidationSignalCouponShop$: Observable<string> = invalidationSignalCouponShopSource.asObservable();

@Injectable({ providedIn: 'root' })
export class CouponsService {
  private readonly BOX_API = environment.application.API_URL;

  private readonly availableCouponsSource = new BehaviorSubject<Coupon[]>([]);
  public readonly availableCoupons$ = this.availableCouponsSource.asObservable();

  private readonly unavailableCouponsSource = new BehaviorSubject<Coupon[]>([]);
  public readonly unavailableCoupons$ = this.unavailableCouponsSource.asObservable();

  constructor(private http: HttpClient, private configService: ConfigurationService) {}

  public getAvailableCoupons(): Coupon[] {
    return this.availableCouponsSource.getValue();
  }

  // this function is never called but is here as an example of the invalidation mechanism
  public triggerShopCouponInvalidation(collectionType: number): void {
    invalidationSignalCouponShopSource.next(collectionType.toString());
  }

  public setAvailableCoupons(coupons: Coupon[]): void {
    this.availableCouponsSource.next(coupons);
  }

  public addAvailableCoupon(coupon: Coupon): void {
    const currentCoupons = this.availableCouponsSource.getValue();
    const coupons = sortAvailableCoupons([...currentCoupons, coupon]);
    this.availableCouponsSource.next(coupons);
  }

  @ObservableCacheDecorator<Coupon[]>({ expirationTimeInMs: AVAILABLE_COUPONS_SESSION_EXPIRATION })
  public fetchAvailableCoupons$(): Observable<Coupon[]> {
    return this.http.get(`${this.BOX_API}/coupon-wallet/main-view/available`).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        const dummyFilteredCoupons = this.filterDummyCoupons(coupons);
        const validAndKnownCoupons = dummyFilteredCoupons.filter(
          (coupon) => isCouponValid(coupon) && isCouponKnown(coupon)
        );
        return sortAvailableCoupons(validAndKnownCoupons);
      })
    );
  }

  public getUnavailableCoupons(): Coupon[] {
    return this.unavailableCouponsSource.getValue();
  }

  public setUnavailableCoupons(coupons: Coupon[]): void {
    this.unavailableCouponsSource.next(coupons);
  }

  public addUnavailableCoupon(coupon: Coupon): void {
    const currentCoupons = this.unavailableCouponsSource.getValue();
    const coupons = sortUnavailableCoupons([...currentCoupons, coupon]);
    this.unavailableCouponsSource.next(coupons);
  }

  @ObservableCacheDecorator<Coupon[]>({ expirationTimeInMs: UNAVAILABLE_COUPONS_SESSION_EXPIRATION })
  public fetchUnavailableCoupons$(): Observable<Coupon[]> {
    return this.http.get(`${this.BOX_API}/coupon-wallet/main-view/unavailable`).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        const dummyFilteredCoupons = this.filterDummyCoupons(coupons);
        const filteredCoupons = dummyFilteredCoupons.filter((coupon) => isCouponValid(coupon) && isCouponKnown(coupon));
        this.setUnavailableCoupons(filteredCoupons);
        return filteredCoupons;
      })
    );
  }

  public checkCoupon(code: string, options?: CouponCheckOptions): Observable<Coupon> {
    const pickedOptions = pickBy(options);
    const params = new HttpParams({ fromObject: pickedOptions });
    return this.http
      .get(`${this.BOX_API}/coupons/${code}/check`, { params })
      .pipe(map((response: APIResponse<{ coupon: Coupon }>) => response.payload.coupon));
  }

  public redeemCoupon(code: string): Observable<Coupon> {
    return this.http
      .post(`${this.BOX_API}/coupons/${code}/redeem`, {})
      .pipe(map((response: APIResponse<{ coupon: Coupon }>) => response.payload.coupon));
  }

  @ObservableCacheDecorator<Coupon[], number>({
    expirationTimeInMs: AVAILABLE_COUPONS_SESSION_EXPIRATION,
    functionKeyArgumentIndexes: [0],
    invalidateSignal$: invalidationSignalCouponShop$
  })
  public fetchShopCoupons(collectionType: number): Observable<Coupon[]> {
    return this.http.get(`${this.BOX_API}/coupon-wallet/shop/${collectionType}`).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        const dummyFilteredCoupons = this.filterDummyCoupons(coupons);
        return dummyFilteredCoupons.filter((coupon) => isCouponValid(coupon) && isCouponKnown(coupon));
      })
    );
  }

  public addCouponToWallet(code: string): Observable<Coupon> {
    return this.http
      .post(`${this.BOX_API}/coupon-wallet`, { code })
      .pipe(map((response: APIResponse<{ coupon: Coupon }>) => response.payload.coupon));
  }

  public fetchCheckoutCoupons(collectionType: number, options: FetchCouponsForCheckoutOptions): Observable<Coupon[]> {
    return this.http.post(`${this.BOX_API}/coupon-wallet/checkout/${collectionType}`, options).pipe(
      map((response: APIResponse<{ coupons: Coupon[] }>) => {
        const coupons = response.payload.coupons;
        const dummyFilteredCoupons = this.filterDummyCoupons(coupons);
        const validCoupons = dummyFilteredCoupons.filter((coupon) => isCouponValid(coupon) && isCouponKnown(coupon));
        return this.normalizeCoupons(validCoupons);
      })
    );
  }

  // we have added a FE switch in the config file that dictates whether we show dummy coupons or not
  public filterDummyCoupons(coupons: Coupon[]): Coupon[] {
    const showDummyCoupons = this.configService.getConfiguration()?.startWUSynergy;
    return coupons.filter((coupon: Coupon) => (showDummyCoupons ? true : !coupon.dummy));
  }

  private normalizeCoupons(coupons: Coupon[]): Coupon[] {
    if (!coupons?.length) return [];
    return coupons.map((coupon) => {
      const isCombinedWithPointsRedemption =
        coupon.isCombinedWithPointsRedemption === undefined || coupon.isCombinedWithPointsRedemption === true;
      return { ...coupon, isCombinedWithPointsRedemption };
    });
  }
}
