<div class="order-completion-ratings-container" *ngIf="visibleOrders?.length">
  <h4 class="order-completion-ratings-title ods-typography-subtitle-s">{{ 'help_us_get_better' | translate }}</h4>
  <p class="order-completion-ratings-instructions ods-typography-body-s-regular">
    {{ 'help_us_highlight_the_best_shops' | translate }}
  </p>
  <order-completion-ratings-order
    *ngFor="let order of visibleOrders; let i = index"
    [style.--animation-order]="i"
    [order]="order"
    (rateOrder)="onRateOrder(order, $event)"
  ></order-completion-ratings-order>

  <div *ngIf="showExpandButton" class="order-completion-ratings-expand-button-container">
    <button
      box-button
      theme="secondary"
      borders="none"
      class="order-completion-ratings-expand-button"
      (click)="onMoreToggle()"
    >
      {{ expandButtonText | translate }}
    </button>
  </div>
</div>
