import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Shop, DaasAvailability, DaasAvailabilityFetchOptions, APIResponse } from '@box-types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@box-env/environment';
import { map } from 'rxjs/operators';
import { isDaasAvailabilityUpdated, storageSet, storageGet, storageRemove } from '@box/utils';
import { DaasDelayComponent } from '@box-daas-widget/components';
import { NotificationsService } from '@box-core/services/notifications.service';
import { DialogService } from '@box-core/services/dialog.service';
import { Router } from '@angular/router';
import { NotificationRef } from '@box-core/components';
import { pickBy } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class DaasAvailabilityService {
  public daasAvailability = new BehaviorSubject<DaasAvailability>(null);
  private readonly BOX_API = environment.application.API_URL;
  private readonly DAAS_AVAILABIILITY_KEY = 'Box:daasAvailability';
  private daasAvailabilityNotificationRef: NotificationRef;

  constructor(
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  public fetchDaasAvailability(
    shop: Shop,
    options: Partial<DaasAvailabilityFetchOptions>
  ): Observable<DaasAvailability> {
    const pickedOptions = pickBy(options);
    const params: HttpParams = new HttpParams({ fromObject: pickedOptions });
    return this.http
      .get(`${this.BOX_API}/shops/${shop.collectionType}/daas-available`, { params })
      .pipe(map((response: APIResponse<DaasAvailability>) => response.payload));
  }

  public postFetchActions(shop: Shop, availability: DaasAvailability): void {
    this.daasAvailabilityNotificationRef = this.showAvailabilityNotificationIfNecessary(availability, shop);
    this.saveDaasAvailability(shop, availability);
  }

  public removeNotification(): void {
    if (this.daasAvailabilityNotificationRef) {
      this.notificationsService.removeNotification(this.daasAvailabilityNotificationRef);
    }
  }

  public removeDaasAvailabilityFromStorage(): void {
    storageRemove(this.DAAS_AVAILABIILITY_KEY, window.sessionStorage);
  }

  public clearDaasAvailability(shop: Shop): void {
    this.saveDaasAvailability(shop, null);
    this.removeDaasAvailabilityFromStorage();
  }

  private saveDaasAvailability(shop: Shop, availability: DaasAvailability): void {
    this.saveDaasAvailabilityToStorage(shop, availability);
    this.daasAvailability.next(availability);
  }

  public saveDaasAvailabilityToStorage(shop: Shop, availability: DaasAvailability): void {
    const key: number = shop.collectionType;
    const collections =
      storageGet(this.DAAS_AVAILABIILITY_KEY, window.sessionStorage) ?? ({} as Record<number, DaasAvailability>);
    collections[key] = availability ? { ...availability } : null;
    storageSet(this.DAAS_AVAILABIILITY_KEY, collections, window.sessionStorage);
  }

  public getDaasAvailabilityFromStorage(shop: Shop): DaasAvailability {
    const availabilities =
      storageGet(this.DAAS_AVAILABIILITY_KEY, window.sessionStorage) ?? ({} as Record<number, DaasAvailability>);
    if (!availabilities) return;
    return availabilities[shop.collectionType];
  }

  private showAvailabilityNotificationIfNecessary(newAvailability: DaasAvailability, shop: Shop): NotificationRef {
    if (!newAvailability) return;
    const previousAvailability = this.getDaasAvailabilityFromStorage(shop);
    if (!isDaasAvailabilityUpdated(previousAvailability, newAvailability)) return;
    if (newAvailability.estimatedDelay === 0) return;
    return this.notificationsService.addNotification(DaasDelayComponent, { data: { availability: newAvailability } });
  }

  public showUnavailableTimeslotsDialog(): void {
    this.dialogService
      .openInfoDialog({
        title: 'the_shop_closed',
        messages: ['due_to_increased_traffic_unfortunately_the_shop_was_closed', 'you_can_order_from_other_shops'],
        btnText: 'see_other_shops'
      })
      .afterClosed()
      .subscribe(() => {
        void this.router.navigate(['/discover']);
      });
  }
}
