// By using this component you can change the background color of a svg file {svgPath}
import {
  Component,
  Input,
  Renderer2,
  ElementRef,
  HostBinding,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent implements OnChanges {
  @Input() public svgPath: string;
  @HostBinding('class') public hostClass = 'svg-icon';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.svgPath) {
      this.svgPath = changes.svgPath.currentValue as string;
      this.setImage();
    }
  }

  setImage(): void {
    const addressIconStyle = `url('${this.svgPath}') no-repeat center`;
    this.renderer.setStyle(this.elementRef.nativeElement, 'mask', addressIconStyle);
    this.renderer.setStyle(this.elementRef.nativeElement, '-webkit-mask', addressIconStyle);
  }
}
