import { ValidatorFn } from '@angular/forms';
import { invalidCharsValidator } from '@box-shared/validators/validator.utils';

const REG_EXP = new RegExp(
  /^[A-Za-zΑ-Ωα-ω\dΆΈΉΊΌΎΏΪΫάέήίόύώϊΐϋΰŠŒŽšarkœžŸ¡¦§ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ›¤¶±¹º²³¼½¾©®™+ ÷_\-=!@#$€£¥%^&*()''΄:;¨?,.\\/\\n\\r°•·–…]*$/
);

export function couponCodeValidator(): ValidatorFn {
  return invalidCharsValidator(REG_EXP);
}
