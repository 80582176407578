<header class="four-oh-four-header">
  <div class="container">
    <div class="four-oh-four-header-wrapper">
      <a routerLink="/" class="four-oh-four-header-logo"></a>
    </div>
  </div>
</header>
<section class="section four-oh-four-section">
  <div class="container four-oh-four-container">
    <figure class="image contain-image four-oh-four-image">
      <img src="/assets/images/general/404.png" alt="BOX 404" />
    </figure>
    <h1 class="four-oh-four-title">{{ 'sorry_page_not_found' | translate }}</h1>
    <button box-button theme="primary" class="four-oh-four-cta" routerLink="/home">
      {{ 'back_to_home_page' | translate }}
    </button>
  </div>
</section>
<box-footer></box-footer>
