import { ProductReplacementOption } from '../types';

export const productReplacementOptions: { [key: string]: ProductReplacementOption } = {
  phone: {
    name: 'phone',
    label: 'contact_by_phone',
    description: 'we_will_call_you_to_choose_the_replacement_product_together',
    shopProperty: 'phoneProductReplacement'
  },
  smart: {
    name: 'smart',
    label: 'smart_replacement',
    description: 'we_will_replace_the_product_with_one_of_equivalent_quality_and_value',
    shopProperty: 'smartProductReplacement'
  },
  none: {
    name: 'none',
    label: 'no_change',
    description: 'we_will_remove_the_product_from_your_order_and_the_final_cost',
    shopProperty: 'noProductReplacement'
  }
};
