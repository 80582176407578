import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { Timeslot, DeliveryDate } from '@box-types';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { ConfigurationService } from '@box-core/services';
import {
  getDeliveryDates,
  getClosestTimeslot,
  generateTimeslots,
  timeslotToText,
  getNextTimeslot,
  timeslotsExactMatch
} from '@box/utils';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import dayjs from 'dayjs';
import { TimeslotDialogData } from '@box-shared/components/timeslot-dialog/timeslot-dialog-data.interface';
import { TimeslotDialogResponse } from '@box-shared/components/timeslot-dialog/timeslot-dialog-response.interface';
import { LanguageService } from '@box-core/services/language.service';

declare interface TimeslotRadioOption {
  name: string;
  label: string;
  disabled?: boolean;
}

const SOONEST_OPTION: TimeslotRadioOption = { name: 'soonest', label: 'asap_extended' };
const SOONEST_OPTION_DISABLED: TimeslotRadioOption = {
  name: 'soonest',
  label: 'asap_extended',
  disabled: true
};
const DEFAULT_OPTION: TimeslotRadioOption = { name: 'select_slot', label: 'select_receive_time' };

@Component({
  selector: 'timeslot-dialog',
  templateUrl: './timeslot-dialog.component.html',
  styleUrls: ['./timeslot-dialog.component.scss']
})
export class TimeslotDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public radioButtonOptions: TimeslotRadioOption[];
  public selectedRadioButton: string;
  public selectedDate: DeliveryDate;
  public deliveryDates: DeliveryDate[];
  public showNextDayOption: boolean;

  private timeslot: Timeslot;
  private timeslots: Timeslot[];
  private nextAvailableTimeslot: Timeslot;
  private isSuperMarket: boolean;

  constructor(
    public renderer: Renderer2,
    private timeslotsService: TimeslotsService,
    private configService: ConfigurationService,
    private dialogRef: MatDialogRef<TimeslotDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: TimeslotDialogData,
    private languageService: LanguageService
  ) {
    super(renderer);
    this.isSuperMarket = this.data?.isSuperMarket;
    this.nextAvailableTimeslot = this.data?.nextAvailableTimeslot;
  }

  ngOnInit(): void {
    this.timeslot = this.timeslotsService.getTimeslot();
    this.timeslots = this.timeslotsService.getTimeslots() || this.generateDefaultTimeslots();
    if (this.isSuperMarket) this.nextAvailableTimeslot = getNextTimeslot(this.timeslots);
    this.radioButtonOptions = this.getRadioButtonOptions();
    this.initializeDates();
    this.setLastDayMessageVisibility();
  }

  private generateDefaultTimeslots(): Timeslot[] {
    const config = this.configService.getConfiguration();
    if (!config?.scheduledOrdersLimit) return generateTimeslots(30, 5, 60);
    const timeslotDaysLimit: number = config.scheduledOrdersLimit;
    return generateTimeslots(30, timeslotDaysLimit, 60);
  }

  private getRadioButtonOptions(): TimeslotRadioOption[] {
    const options: TimeslotRadioOption[] = [];
    if (this.nextAvailableTimeslot) {
      if (this.isSuperMarket) {
        const asapTranslated = this.languageService.getTextByKey('asap_');
        const option = {
          name: 'next_available',
          label: asapTranslated + ' - ' + timeslotToText(this.nextAvailableTimeslot)
        };
        options.push(option);
      } else {
        options.push(SOONEST_OPTION_DISABLED);
      }
    } else {
      options.push(SOONEST_OPTION);
    }
    if (this.timeslots?.length) options.push(DEFAULT_OPTION);
    return options;
  }

  private initializeFoodDates(): void {
    this.deliveryDates = getDeliveryDates(this.timeslots);
    if (this.nextAvailableTimeslot) {
      const timeslotDate: string = dayjs.unix(this.nextAvailableTimeslot.timeSlotStart).format('YYYY-MM-DD');
      this.selectedRadioButton = this.radioButtonOptions[1].name;
      this.selectedDate = this.deliveryDates.find((d) => d.date === timeslotDate);
      this.selectedDate.timeslot = this.nextAvailableTimeslot;
    } else if (this.timeslot) {
      const timeslotDate: string = dayjs.unix(this.timeslot.timeSlotStart).format('YYYY-MM-DD');
      this.selectedRadioButton = this.radioButtonOptions[1].name;
      this.selectedDate = this.deliveryDates.find((d) => d.date === timeslotDate);
      if (!this.selectedDate) return;
      this.selectedDate.timeslot = getClosestTimeslot(this.timeslot, this.selectedDate.timeslots);
    } else {
      this.selectedRadioButton = this.radioButtonOptions[0].name;
      this.selectedDate = this.deliveryDates[0];
    }
  }

  private initializeMarketDates(): void {
    this.deliveryDates = getDeliveryDates(this.timeslots);
    if (timeslotsExactMatch(this.timeslot, this.nextAvailableTimeslot)) {
      const timeslotDate: string = dayjs.unix(this.nextAvailableTimeslot.timeSlotStart).format('YYYY-MM-DD');
      this.selectedRadioButton = this.radioButtonOptions[0].name;
      this.selectedDate = this.deliveryDates.find((d) => d.date === timeslotDate);
      if (!this.selectedDate) return;
      this.selectedDate.timeslot = this.nextAvailableTimeslot;
    } else {
      const timeslotDate: string = dayjs.unix(this.timeslot.timeSlotStart).format('YYYY-MM-DD');
      this.selectedRadioButton = this.radioButtonOptions[0].name;
      this.selectedDate = this.deliveryDates.find((d) => d.date === timeslotDate);
      if (!this.selectedDate) return;
      this.selectedDate.timeslot = this.timeslot;
    }
  }

  private initializeDates(): void {
    if (this.isSuperMarket) return this.initializeMarketDates();
    return this.initializeFoodDates();
  }

  public onDateChange(date: DeliveryDate): void {
    if (!date) return this.initializeDates();
    const previousTimeslot: Timeslot = this.selectedDate.timeslot;
    const timeslots: Timeslot[] = date.timeslots;
    this.selectedDate = date;
    this.selectedDate.timeslot = getClosestTimeslot(previousTimeslot, timeslots);
    this.setLastDayMessageVisibility();
  }

  public setLastDayMessageVisibility(): void {
    this.showNextDayOption = this.deliveryDates.indexOf(this.selectedDate) !== this.deliveryDates.length - 1;
  }

  public onChangeRadioButton(event: MatRadioChange) {
    this.selectedRadioButton = event.value as string;
  }

  public closeDialog(data?: TimeslotDialogResponse) {
    this.dialogRef.close(data);
  }

  public onSubmit(): void {
    const type = this.selectedRadioButton;
    if (type === 'soonest') return this.closeDialog({ timeslot: undefined, reset: true });
    if (type === 'next_available') return this.closeDialog({ timeslot: this.nextAvailableTimeslot });
    if (type === 'select_slot') return this.closeDialog({ timeslot: this.selectedDate.timeslot });
  }
}
