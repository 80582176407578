import { Injectable } from '@angular/core';
import { LanguageService, translate as t } from '@box-core/services/language.service';
import { toInteger } from '@box/utils';

@Injectable()
export class CheckoutPointsDialogService {
  private readonly NOT_ENOUGH_POINTS_MESSAGE = 'your_available_points_balance_is_not_sufficient';

  constructor(private languageService: LanguageService) {}

  public generatePointsSteps(stepValue: number, maximumValue: number): string[] {
    if (!stepValue || !maximumValue) return [];
    const length = toInteger(maximumValue / stepValue) + 1;
    return Array.from({ length }).map((value, index) => String(index * stepValue));
  }

  public generateEurosSteps(stepValue: number, maximumValue: number): string[] {
    if (!stepValue || !maximumValue) return [];
    const length = toInteger(maximumValue / stepValue) + 1;
    return Array.from({ length }).map((value, index) => `${index}${t('currency')}`);
  }

  public getErrorMessage(
    points: number,
    availablePoints: number,
    maximumPointsInEuro: number,
    euroRedemptionRate: number
  ): string {
    if (points > availablePoints) return this.NOT_ENOUGH_POINTS_MESSAGE;
    const wantedPrice = maximumPointsInEuro * euroRedemptionRate;
    return this.languageService.getTextByKey('your_cart_must_have_at_least_wanted_price', {
      _MAX_POINTS: String(maximumPointsInEuro),
      _WANTED_PRICE: String(wantedPrice)
    });
  }
}
