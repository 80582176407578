/**
   pointsToEurosRatio:
     amount of points that correspond to 1euro (default = 1600)
   euroRedemptionRate:
     the amount of euros needed to redeem 1€ in points
     X = 4 for food stores
     X = 10 for sm stores
     for k*X€ value in your cart you can have a discount of k€
 * */
import { GetTextByKeyType, CurrencyCode } from '@box-types';
import { CurrencyFormatOptions, currencyFormat } from '../core';

export function getCheckoutPointsMaximumValue(
  cartPrice: number,
  euroRedemptionRate: number,
  pointsToEurosRatio: number
): number {
  if (!euroRedemptionRate || !pointsToEurosRatio) return 0;
  return Math.ceil(cartPrice / 100 / euroRedemptionRate) * pointsToEurosRatio;
}

export function pointsToCents(points: number, pointsToEurosRatio: number): number {
  if (!points || !pointsToEurosRatio) return 0;
  return (points / pointsToEurosRatio) * 100;
}

export function getCheckoutPointsBannerEuros(
  checked: boolean,
  pointsToRedeem: number,
  redeemablePoints: number,
  pointsToEurosRatio: number,
  currencyCode: CurrencyCode
): string {
  if (!pointsToEurosRatio) return '0€';
  const currencyOptions: CurrencyFormatOptions = { maximumFractionDigits: 0, symbolSpace: false, currencyCode };
  if (checked) return currencyFormat((pointsToRedeem / pointsToEurosRatio) * 100, currencyOptions);
  return currencyFormat((redeemablePoints / pointsToEurosRatio) * 100, currencyOptions);
}

export function getCheckoutPointsBannerText(
  checked: boolean,
  pointsToRedeem: number,
  redeemablePoints: number,
  pointsToEurosRatio: number,
  translateFn: GetTextByKeyType,
  currencyCode: CurrencyCode
): string {
  if (!pointsToEurosRatio) return;
  const bannerEuros = getCheckoutPointsBannerEuros(
    checked,
    pointsToRedeem,
    redeemablePoints,
    pointsToEurosRatio,
    currencyCode
  );
  if (checked)
    return translateFn('earn_discount_benefits', {
      _BENEFITS_TEXT: bannerEuros
    });
  return translateFn('redeem_points_and_earn_up_to_benefits_discount', {
    _BENEFITS_TEXT: bannerEuros
  });
}

export function getCheckoutPointsRedeemablePoints(
  cartPriceWhichCanBeDiscounted: number,
  userAvailablePoints: number,
  pointsToEurosRatio: number,
  euroRedemptionRate: number
): number {
  if (!pointsToEurosRatio || !euroRedemptionRate) return 0;
  const userClaimableEuros = Math.floor(userAvailablePoints / pointsToEurosRatio);
  const cartClaimableEuros = Math.floor(cartPriceWhichCanBeDiscounted / 100 / euroRedemptionRate);
  return Math.min(userClaimableEuros, cartClaimableEuros) * pointsToEurosRatio;
}
