import { Injectable } from '@angular/core';
import { Order, User } from '@box-types';
import { LoyaltyService } from '@box-core/services';
import {
  currencyFormat,
  getUserCurrencyBalanceText,
  getUserPointsBalanceText,
  pointsToCents,
  formatPoints
} from '@box/utils';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { RewardsBalanceBadgeState } from '@box-rewards-widget/components/rewards-balance-badge/rewards-balance-badge.types';
import { currencyCode } from '@box-core/services/currency.service';

@Injectable({ providedIn: 'root' })
export class PointsAnimationService {
  private readonly STATE_INTERVAL_DELAY = 5000;
  private readonly stateSource = new BehaviorSubject<RewardsBalanceBadgeState>('POINTS');
  public readonly state$ = this.stateSource.asObservable();

  private intervalSubscription: Subscription;

  constructor(private loyaltyService: LoyaltyService) {}

  public getUserPointsBalanceText(user: User): string {
    return getUserPointsBalanceText(user);
  }

  public getUserCurrencyBalanceText(user: User): string {
    const pointsToRedeemRate = this.loyaltyService.getLoyaltyPointsToRedeemRate();
    return getUserCurrencyBalanceText(user, pointsToRedeemRate, currencyCode);
  }

  public getOrderPointsBalanceText(order: Order): string {
    const orderPoints = order?.marketPlacePoints?.collected;
    if (!orderPoints) return;
    return formatPoints(orderPoints);
  }

  public getOrderCurrencyBalanceText(order: Order): string {
    const orderPoints = order?.marketPlacePoints?.collected;
    if (!orderPoints) return;
    const pointsToRedeemRate = this.loyaltyService.getLoyaltyPointsToRedeemRate();
    const pointsInCents = pointsToCents(orderPoints, pointsToRedeemRate);
    const formatOptions = {
      style: 'currency',
      currencyCode: currencyCode,
      minimumFractionDigits: 2
    };
    return currencyFormat(pointsInCents, formatOptions);
  }

  public toggleState(): void {
    if (this.stateSource.getValue() === 'POINTS') return this.stateSource.next('CURRENCY');
    return this.stateSource.next('POINTS');
  }

  public startStateInterval(): void {
    this.intervalSubscription = interval(this.STATE_INTERVAL_DELAY).subscribe(() => this.toggleState());
  }

  public stopStateInterval(): void {
    this.intervalSubscription?.unsubscribe();
    this.intervalSubscription = undefined;
  }
}
