<div class="product-myo-choice-description">
  <span class="product-myo-choice-title ods-typography-body-s-regular">{{ title }}</span>
  <span *ngIf="price !== 0" class="product-myo-choice-price">
    <span *ngIf="showPlus" class="product-myo-choice-plus">&#43; </span>
    {{ price | currencyPipe }}
  </span>
</div>
<span *ngIf="envFee > 0" class="product-myo-choice-env-fee">
  {{ 'includes_fee' | translate }} {{ envFee | currencyPipe }}
</span>
