import {
  Order,
  Product,
  Offer,
  OrderReceiptProduct,
  OrderReceiptItemsGroup,
  OrderReceiptOffer,
  CurrencyCode
} from '@box-types';
import { getProductSelectedChoicesTitles } from '../products';
import { currencyFormat } from '../core';
import { groupBy } from 'lodash-es';
import { getOfferBadgeOptions } from '../offers';

function generateOrderProductDescription(product: Product): string {
  const descriptionParts = getProductSelectedChoicesTitles(product);
  if (product.comments) descriptionParts.push(`"${product.comments}"`);
  return descriptionParts.join(', ');
}

function orderProductToOrderReceiptProduct(product: Product, currencyCode: CurrencyCode): OrderReceiptProduct {
  const description = generateOrderProductDescription(product);
  const quantity = product.quantity;
  const price = currencyFormat(product.finalPrice, { minimumFractionDigits: 2, symbolSpace: false, currencyCode });
  return { description, quantity, price };
}

function generateOrderReceiptProductGroups(
  order: Order,
  currencyCode: CurrencyCode
): OrderReceiptItemsGroup<OrderReceiptProduct>[] {
  const orderProducts = order.products;
  if (!orderProducts?.length) return [];
  const groupedProductsById = groupBy(orderProducts, 'productId');
  return Object.keys(groupedProductsById).map((productId) => {
    const groupProducts = groupedProductsById[productId];
    return {
      name: groupProducts[0].name,
      items: groupProducts.map((product) => orderProductToOrderReceiptProduct(product, currencyCode))
    };
  });
}

function orderOfferToOrderReceiptOffer(offer: Offer, currencyCode: CurrencyCode): OrderReceiptOffer {
  const quantity = offer.quantity;
  const price = currencyFormat(offer.price, { minimumFractionDigits: 2, symbolSpace: false, currencyCode });
  const products = offer.products.map((product) => ({
    name: product.name,
    description: generateOrderProductDescription(product)
  }));
  return { products, quantity, price };
}

function generateOrderReceiptOfferGroups(
  order: Order,
  currencyCode: CurrencyCode
): OrderReceiptItemsGroup<OrderReceiptOffer>[] {
  const orderOffers = order.offers;
  if (!orderOffers?.length) return [];
  const groupedOffersById = groupBy(orderOffers, 'offerId');
  return Object.keys(groupedOffersById).map((offerId) => {
    const firstOffer = groupedOffersById[offerId][0];
    const group: OrderReceiptItemsGroup<OrderReceiptOffer> = {
      name: firstOffer.name,
      items: groupedOffersById[offerId].map((offer) => orderOfferToOrderReceiptOffer(offer, currencyCode))
    };
    const badgeOptions = getOfferBadgeOptions(firstOffer, []);
    if (badgeOptions) group.badgeOptions = badgeOptions;
    return group;
  });
}

export {
  generateOrderProductDescription,
  orderProductToOrderReceiptProduct,
  generateOrderReceiptProductGroups,
  orderOfferToOrderReceiptOffer,
  generateOrderReceiptOfferGroups
};
