import { OrderCompletionContactInfo, Address, Order, DeliveryMethod, GetTextByKeyType } from '@box-types';
import pickBy from 'lodash-es/pickBy';
import { addressToString, generateGoogleMapsDirectionsUrl, getAddressDescription, getAddressType } from '../address';
import { isOrderDaas, isOrderDaasBeforeShipping } from './order-daas.utils';

export {
  BOX_SUPPORT_CONTACT_PHONE,
  getOrderCompletionAddressIcon,
  getOrderCompletionAddressContactInfo,
  getOrderCompletionPhoneContactInfo,
  getDeclinedOrderContactText,
  getDeclinedOrderContactPhone
};

const BOX_SUPPORT_CONTACT_PHONE = '+302106241990';

function getOrderCompletionAddressIcon(address: Address, deliveryMethod: DeliveryMethod = 'delivery'): string {
  const type = deliveryMethod === 'delivery' ? getAddressType(address.type) : 'takeAway';
  return `/assets/images/order-acceptance/contact-info-address_${type}.svg`;
}

function getOrderCompletionAddressContactInfo(
  order: Order,
  translateFn: GetTextByKeyType
): Partial<OrderCompletionContactInfo> {
  const deliveryMethod = order.orderShippingType;
  const address = deliveryMethod === 'delivery' ? order.address : order.shop.address;
  const icon = getOrderCompletionAddressIcon(address, deliveryMethod);

  const title =
    deliveryMethod === 'delivery'
      ? translateFn('delivery_to_address', { _ADDRESS_TYPE: address.type })
      : 'take_away_from_shop';
  const subtitle =
    deliveryMethod === 'delivery' ? addressToString(address, translateFn) : getAddressDescription(address);
  const anchorHref = deliveryMethod === 'takeAway' ? generateGoogleMapsDirectionsUrl(address) : null;
  const anchorText = deliveryMethod === 'takeAway' ? 'instructions_' : null;
  return pickBy<OrderCompletionContactInfo>({ icon, title, subtitle, anchorHref, anchorText });
}

function getOrderCompletionPhoneContactInfo(order: Order): Partial<OrderCompletionContactInfo> {
  const shopPhone = order.shop.phone;
  const icon = `/assets/images/order-acceptance/contact-info-phone.svg`;
  const anchorText = 'call_';

  let title: string;
  let subtitle: string;
  let anchorHref: string;

  if (isOrderDaas(order) && !isOrderDaasBeforeShipping(order)) {
    title = 'contact_us';
    subtitle = BOX_SUPPORT_CONTACT_PHONE;
    anchorHref = `tel:+30${BOX_SUPPORT_CONTACT_PHONE}`;
  } else {
    title = 'shop_telephone';
    subtitle = shopPhone;
    anchorHref = `tel:${shopPhone}`;
  }

  return { icon, title, subtitle, anchorHref, anchorText };
}

function getDeclinedOrderContactText(order: Order): string {
  const shopResponseStatus = order.shopResponse.status;
  if (shopResponseStatus === 'DECLINED_AUTOMATICALLY') return 'contact_us';
  if (shopResponseStatus === 'DECLINED_MANUALLY') return 'contact_with_shop';
}

function getDeclinedOrderContactPhone(order: Order): string {
  const shopResponseStatus = order.shopResponse.status;
  if (shopResponseStatus === 'DECLINED_AUTOMATICALLY') return `+30${BOX_SUPPORT_CONTACT_PHONE}`;
  if (shopResponseStatus === 'DECLINED_MANUALLY') return `${order.shop.phone}`;
}
