import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from '@box-core/services/language.service';
import { AddressType } from '@box-types';
import { getAddressType } from '@box/utils/src';

@Component({
  selector: 'address-type',
  templateUrl: './address-type.component.html',
  styleUrls: ['./address-type.component.scss']
})
export class AddressTypeComponent implements OnInit {
  @Input() public value = 'home_';
  @Output() private typeChange: EventEmitter<string> = new EventEmitter<string>();

  public type: AddressType;
  public customType: string;
  public customTypeFormControl: FormControl;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.type = getAddressType(this.value);
    this.setCustomTypeFormControl();
  }

  public onTypeClick(event: Event, type: AddressType): void {
    this.type = type;
    this.typeChange.emit(this.getValueToEmit());
  }

  public onCustomTypeChange(type: string): void {
    this.typeChange.emit(type);
  }

  private getValueToEmit(): string {
    // https://www.notion.so/desquared/Address-Type-93114ffafeb347a088f6c18c0cfc74c9?pvs=4#618cfc1a3617416797939e1c89342a3f
    if (this.type === 'home') return this.languageService.getTextByKey('home_');
    if (this.type === 'work') return this.languageService.getTextByKey('work_');
    return this.customTypeFormControl.value || this.languageService.getTextByKey('other_');
  }

  private setCustomTypeFormControl(): void {
    const value = this.type === 'other' ? this.value : '';
    this.customTypeFormControl = new FormControl(value);
    this.customTypeFormControl.valueChanges.subscribe((value) => this.typeChange.emit(value));
  }
}
