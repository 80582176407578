<div class="checkout-bags-wrapper">
  <div class="checkout-bags-header">
    <h4 class="checkout-bags-title">{{ 'bags_with_charge' | translate }}</h4>
    <div class="checkout-bags-slide-container">
      <mat-slide-toggle
        color="primary"
        class="checkout-bags-slide"
        [checked]="checked"
        [disabled]="disabled"
        (change)="onSlideChange($event)"
      ></mat-slide-toggle>
    </div>
  </div>
  <div *ngIf="checked" class="checkout-bags-description">
    <span>{{ description }}</span>
  </div>
</div>
