import { Injectable } from '@angular/core';
import { Currency, CurrencyCode } from '@box-types';

const replacementVariable = '_CURRENCY';
const replacementKey = 'currency';

const currencies: Record<string, Currency> = {
  EUR: {
    currencySymbol: '€',
    currencyCode: 'EUR'
  }
};

export let currencyCode: CurrencyCode;

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  constructor() {
    currencyCode = this.getCurrencyCode();
  }

  public currencyTextReplace(string: string): string {
    if (!string) return;
    const selectedCurrency = this.getSelectedCurrency();
    if (string === replacementKey) return selectedCurrency?.currencySymbol;
    const regex = new RegExp(replacementVariable, 'g');
    return string.replace(regex, selectedCurrency?.currencySymbol);
  }

  public getCurrencyCode(): CurrencyCode {
    return this.getSelectedCurrency()?.currencyCode;
  }

  private getSelectedCurrency(): Currency {
    return currencies?.['EUR'];
  }
}
