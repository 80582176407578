import { DiscoverFilteringOption } from '../types';

export const discoverDeliveryOption: DiscoverFilteringOption = {
  slug: 'takeway',
  label: 'takeaway_',
  shortLabel: 'Takeaway',
  checked: false,
  disabled: false,
  type: 'delivery'
};
