import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { getOutputBlockHtml } from '@box/utils';
import { PromoBanner } from '@box-types';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class PromoBannerDetailsDialogService {
  constructor(private snackBar: MatSnackBar, private languageService: LanguageService) {}
  public getPromoBannerBlocksHtml(promoBanner: PromoBanner): string {
    if (!promoBanner?.detailsDescription?.blocks?.length) return '';
    return promoBanner.detailsDescription.blocks
      .map((block) => this.normalizeHTMLAnchor(getOutputBlockHtml(block)))
      .join('');
  }

  public showCopySnackBar(): void {
    const snackConfig: MatSnackBarConfig = { duration: 3000 };
    const messageText = this.languageService.getTextByKey('coupon_code_has_been_copied_to_the_clipboard');
    const actionText = this.languageService.getTextByKey('ok_');
    this.snackBar.open(messageText, actionText, snackConfig);
  }

  private normalizeHTMLAnchor(html: string): string {
    return html.replace('<a ', '<a target="_blank" ');
  }
}
