import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function regExToValidatorFunction(regExp: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!regExp) return null;
    const input = control?.value as string;
    if (!input?.length) return null;
    const valid = regExp.test(input);
    if (valid) return null;
    return { invalid: { value: input } };
  };
}

/**
 * Returns a validation error if there is any invalid character in the input.
 * For example if A,B,C are the valid characters and input is ABCDDEE will return "D, E".
 *
 * @param regExp - A regular expression containing all valid characters
 *
 */
export function invalidCharsValidator(regExp: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!regExp) return null;
    const input = control?.value as string;
    if (!input?.length) return null;
    // using spread operator instead of split() ensures that surrogate pairs like emojis are not broken into separate characters
    const invalidChars = [...input].filter((char) => !regExp.test(char));
    if (!invalidChars?.length) return null;
    // remove duplicates
    const uniqueInvalidChars = [...new Set(invalidChars)];
    const message = uniqueInvalidChars.join(', ');
    return { invalid: { value: message } };
  };
}
