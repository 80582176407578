<div class="payment-type-wrapper">
  <ng-container *ngIf="paymentTypeImage">
    <svg-icon
      *ngIf="dynamicColor"
      class="payment-type-svg-icon"
      [attr.aria-label]="paymentType.title"
      [svgPath]="paymentTypeImage"
    ></svg-icon>
    <figure *ngIf="!dynamicColor" class="image contain-image payment-type-image">
      <img [src]="paymentTypeImage" [alt]="paymentType.title" />
    </figure>
  </ng-container>
  <div class="payment-type-title ods-typography-body-s-bold">{{ paymentType.title | translate }}</div>
  <div *ngIf="showCampaign && hasCampaign" class="payment-type-campaign">
    <ng-container *ngIf="campaignImages?.length; else normalView">
      <checkout-payment-badge
        [images]="campaignImages"
        [multiplier]="campaignMultiplier"
        [points]="campaignPoints"
      ></checkout-payment-badge>
    </ng-container>
    <ng-template #normalView>
      <div *ngIf="campaignMultiplier" class="payment-type-campaign-multiplier">x{{ campaignMultiplier }}</div>
      <points-tag *ngIf="campaignPoints" class="points-tag" sign="+">{{ campaignPoints | number: '1.0-0' }}</points-tag>
    </ng-template>
  </div>
  <div *ngIf="paymentType.disabledText" class="payment-type-disabled-text">{{ paymentType.disabledText }}</div>
</div>
