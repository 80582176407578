import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoDialogData } from '@box-types';

@Component({
  selector: 'box-info-dialog',
  templateUrl: './box-info-dialog.component.html',
  styleUrls: ['./box-info-dialog.component.scss']
})
export class BoxInfoDialogComponent implements OnInit {
  public title: string;
  public messages: string[];
  public btnText: string;
  public userAction: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InfoDialogData,
    private dialogRef: MatDialogRef<BoxInfoDialogComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.data.title ?? '';
    this.messages = this.data.messages ?? [];
    this.btnText = this.data.btnText ?? 'confirm_';
    this.userAction = this.data.userAction ?? true;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
