import { PromoCampaign, Shop, PromoVisualOptions, ShopPromo, PromoColorsOptions } from '@box-types';
import {
  getPromoCampaignText,
  getPromoCampaignColor,
  getOfferPromoVisuals,
  getPointsPromoVisuals,
  getBestShopOfferType,
  formatPoints
} from '@box/utils';
import { ConfigurationService } from '@box-core/services/index';
import { Injectable, Injector } from '@angular/core';
import { LanguageService } from '@box-core/services/language.service';

// Template Method Pattern
// https://refactoring.guru/design-patterns/template-method
export abstract class PromoVisualOptionsService {
  private configService: ConfigurationService;

  constructor(injector: Injector) {
    this.configService = injector.get(ConfigurationService);
  }

  public generateFirstGroupOptions(promo: ShopPromo): PromoVisualOptions {
    if (promo?.explorePromoCampaign) return this.generateExplorePromoOptions(promo);
    if (promo?.multiplierSum) return this.generateMultiplierPromoOptions(promo);
    if (promo?.pointsSum) return this.generatePointsPromoOptions(promo);
  }

  public generateSecondGroupOptions(promo: ShopPromo, shop: Shop): PromoVisualOptions {
    if (promo?.bestSpecialCampaign) return this.generateSpecialCampaignOptions(promo);
    return this.generateOfferPromoOptions(shop);
  }

  private generateExplorePromoOptions(promo: ShopPromo): PromoVisualOptions {
    return {
      ...getPointsPromoVisuals(),
      text: getPromoCampaignText(promo.explorePromoCampaign, 'lowOrderProbabilityActiveBadge')
    };
  }

  public generateOfferPromoOptions(shop: Shop): PromoVisualOptions {
    if (shop.isSuperMarket) return { text: 'Offer' };
    const offersPriority = this.configService.getConfiguration()?.offersPriority;
    const bestOfferType = getBestShopOfferType(shop, offersPriority);
    if (bestOfferType) {
      return {
        ...getOfferPromoVisuals(),
        text: bestOfferType
      };
    }
  }

  private getSpecialCampaignColors(campaign: PromoCampaign): PromoColorsOptions {
    return {
      foreground: getPromoCampaignColor(campaign, 'foregroundTag'),
      background: getPromoCampaignColor(campaign, 'backgroundTag'),
      triangle: getPromoCampaignColor(campaign, 'triangleTag')
    };
  }

  private generateSpecialCampaignOptions(promo: ShopPromo): PromoVisualOptions {
    return {
      text: this.getPromoCampaignText(promo?.bestSpecialCampaign),
      colors: this.getSpecialCampaignColors(promo?.bestSpecialCampaign)
    };
  }

  abstract generatePointsPromoOptions(promo: ShopPromo): PromoVisualOptions;
  abstract generateMultiplierPromoOptions(promo: ShopPromo): PromoVisualOptions;
  abstract getPromoCampaignText(campaign: PromoCampaign): string;
}

@Injectable({ providedIn: 'root' })
export class ShortPromoVisualOptionsService extends PromoVisualOptionsService {
  constructor(injector: Injector) {
    super(injector);
  }

  generatePointsPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedPoints = formatPoints(promo.pointsSum);
    return { text: `+${formattedPoints}` };
  }

  generateMultiplierPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedMultiplier = formatPoints(promo.multiplierSum);
    return { ...getPointsPromoVisuals(), text: `x${formattedMultiplier}` };
  }

  getPromoCampaignText(campaign: PromoCampaign): string {
    return getPromoCampaignText(campaign, 'shortTag');
  }
}

@Injectable({ providedIn: 'root' })
export class LongPromoVisualOptionsService extends PromoVisualOptionsService {
  constructor(injector: Injector, private languageService: LanguageService) {
    super(injector);
  }

  generatePointsPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedPoints = formatPoints(promo.pointsSum);
    return { text: `+${formattedPoints} ${this.languageService.getTextByKey('points_')}` };
  }

  generateMultiplierPromoOptions(promo: ShopPromo): PromoVisualOptions {
    const formattedMultiplier = formatPoints(promo.multiplierSum);
    return {
      ...getPointsPromoVisuals(),
      text: `x${formattedMultiplier} ${this.languageService.getTextByKey('points_')}`
    };
  }

  getPromoCampaignText(campaign: PromoCampaign): string {
    return getPromoCampaignText(campaign, 'longTag');
  }
}
