<div class="order-wrapper hoverable">
  <div class="order-main">
    <div *ngIf="status === 'COMPLETED'" class="ods-typography-body-s-bold">
      {{ orderCreatedAtText }}
    </div>
    <order-tile-status *ngIf="status === 'SCHEDULED' || status === 'INPROGRESS'" [order]="order"></order-tile-status>
    <div class="order-items">
      <div *ngFor="let item of itemsToShow" class="order-item ods-typography-body-s-regular">
        x{{ item.quantity }} {{ item.name }}
      </div>
      <div *ngIf="showHiddenItemsQuantity" class="order-item ods-typography-body-s-regular">
        + {{ hiddenItemsQuantity }} {{ 'more_products' | translate }}
      </div>
    </div>
  </div>
  <div class="order-side">
    <div class="order-price ods-typography-body-s-bold">{{ orderPrice | currencyPipe }}</div>
    <div *ngIf="orderPoints" class="order-points">
      <points-tag [sign]="'+'">{{ orderPoints | number: '1.0-0' }}</points-tag>
    </div>
  </div>
</div>
