import { NavItem } from '@box-types';

export { filterValidNavItemsForUser, removeLanguageNavItem };

function filterValidNavItemsForUser(items: NavItem[], isGuest: boolean): NavItem[] {
  if (isGuest) return items.filter((menuItem) => menuItem.visibleToGuest);
  if (!isGuest) return items.filter((menuItem) => menuItem.visibleToAuthenticatedUser);
}

function removeLanguageNavItem(items: NavItem[]): NavItem[] {
  return items.filter((menuItem) => menuItem.routerLink !== '/account/settings/language');
}
