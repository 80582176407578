import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoOptions } from '@box-types';
import {
  generateDescriptionContent,
  generateImageContent,
  generateKeywordsContent,
  generateTitleContent,
  generateUrlContent
} from '@box/utils';
import { translate as t } from '@box-core/services/language.service';

@Injectable({ providedIn: 'root' })
export class SEOService {
  constructor(private meta: Meta, private title: Title) {}

  public setTags(options: SeoOptions): void {
    const titleContent = generateTitleContent(t(options.title));
    const descriptionContent = generateDescriptionContent(options.description, t);
    const keywordsContent = generateKeywordsContent(options.keywords, t);
    const urlContent = generateUrlContent(options.url);
    const imageContent = generateImageContent(options.img);
    /* We have to change the Canonical URL logic here. We should specify
    that logic on the specific Page, not for all possible URLs*/
    this.setCanonicalUrl(options.url);
    this.title.setTitle(titleContent);
    this.meta.updateTag({ name: 'description', content: descriptionContent });
    this.meta.updateTag({ name: 'keywords', content: keywordsContent });
    this.meta.updateTag({ name: 'twitter:title', content: titleContent });
    this.meta.updateTag({ name: 'twitter:description', content: descriptionContent });
    this.meta.updateTag({ name: 'twitter:image:src', content: imageContent });
    this.meta.updateTag({ property: 'og:title', content: titleContent });
    this.meta.updateTag({ property: 'og:url', content: urlContent });
    this.meta.updateTag({ property: 'og:image', content: imageContent });
    this.meta.updateTag({ property: 'og:description', content: descriptionContent });
    this.meta.updateTag({ property: 'og:keywords', content: keywordsContent });
  }

  // https://docs.prerender.io/docs/11-best-practices
  public setPrerenderLocation(url: string): void {
    const generatedUrlContent = generateUrlContent(url);
    const content = `Location: ${generatedUrlContent}`;
    this.meta.updateTag({ name: 'prerender-header', content });
  }

  public removePrerenderLocation(): void {
    this.meta.removeTag("name='prerender-header'");
  }

  // https://docs.prerender.io/docs/11-best-practices
  public setPrerenderStatusCode(content: string): void {
    this.meta.updateTag({ name: 'prerender-status-code', content });
  }

  public removePrerenderStatusCode(): void {
    this.meta.removeTag("name='prerender-status-code'");
  }

  public setCanonicalUrl(url: string): void {
    const content = generateUrlContent(url);
    const existingLink = window.document.head.querySelector('link[rel="canonical"]');
    if (existingLink) return existingLink.setAttribute('href', content);
    const link = window.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', content);
    window.document.head.appendChild(link);
  }

  public removeCanonicalUrl(): void {
    const link: HTMLElement = window.document.querySelector('link[rel="canonical"]');
    if (link) window.document.head.removeChild(link);
  }
}
