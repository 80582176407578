<ng-container [ngSwitch]="shopStatus">
  <ng-container *ngSwitchCase="'PENDING'">
    <h4 class="order-status-details-title">{{ 'waiting_restaurant_order_acceptance' | translate }}</h4>
    <progress-bar *ngIf="fragments?.length" [fragments]="fragments"></progress-bar>
    <p class="order-status-details-instructions">{{ 'you_can_close_page' | translate }}</p>
    <svg
      class="order-status-details-notification"
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
    >
      <g clip-path="url(#a)">
        <circle cx="40" cy="40" r="40" class="order-status-details-bell-background" />
        <path fill="url(#b)" d="M40.19 59.253a5.904 5.904 0 0 0 5.403-3.538H34.787a5.904 5.904 0 0 0 5.403 3.538Z" />
        <path
          fill="url(#c)"
          d="M52.376 38.487v-2.584c0-5.49-3.65-10.142-8.648-11.662v-1.703A3.542 3.542 0 0 0 40.19 19a3.542 3.542 0 0 0-3.538 3.538v1.703c-5 1.52-8.649 6.173-8.649 11.662v2.584c0 4.822-1.837 9.394-5.175 12.874a1.18 1.18 0 0 0 .851 1.996H56.7a1.18 1.18 0 0 0 .852-1.996 18.518 18.518 0 0 1-5.175-12.874ZM41.369 23.774a12.293 12.293 0 0 0-2.359 0v-1.236a1.18 1.18 0 0 1 2.359 0v1.236Z"
        />
        <path
          fill="url(#d)"
          d="M55.52 35.903a1.18 1.18 0 0 0 2.359 0c0-4.725-1.84-9.167-5.181-12.508a1.18 1.18 0 0 0-1.668 1.668 15.23 15.23 0 0 1 4.49 10.84Z"
        />
        <path
          fill="url(#e)"
          d="M23.68 37.083a1.18 1.18 0 0 0 1.179-1.18 15.23 15.23 0 0 1 4.49-10.84 1.18 1.18 0 1 0-1.668-1.668A17.574 17.574 0 0 0 22.5 35.903c0 .652.528 1.18 1.18 1.18Z"
        />
      </g>
      <defs>
        <linearGradient id="b" x1="40.19" x2="40.19" y1="55.715" y2="59.253" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F4781E" />
          <stop offset="1" stop-color="#FE9F36" />
        </linearGradient>
        <linearGradient id="c" x1="40.19" x2="40.19" y1="19" y2="53.357" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F4781E" />
          <stop offset="1" stop-color="#FE9F36" />
        </linearGradient>
        <linearGradient id="d" x1="54.282" x2="54.282" y1="23.049" y2="37.083" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F4781E" />
          <stop offset="1" stop-color="#FE9F36" />
        </linearGradient>
        <linearGradient id="e" x1="26.097" x2="26.097" y1="23.049" y2="37.083" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F4781E" />
          <stop offset="1" stop-color="#FE9F36" />
        </linearGradient>
        <clipPath id="a"><path fill="#fff" d="M0 0h80v80H0z" /></clipPath>
      </defs>
    </svg>
    <p class="order-status-details-instructions">
      {{ 'you_will_soon_receive_order_progress_notification' | translate }}
    </p>
  </ng-container>
  <ng-container *ngSwitchCase="'ACCEPTED_COMPLETED'">
    <ng-container *ngIf="showDaasTimingsBeforeProgress">
      <p *ngIf="estimationPreTitle" class="order-status-details-instructions">{{ estimationPreTitle | translate }}</p>
      <p *ngIf="estimationTitle" class="order-status-details-estimation-time-big ods-typography-display-l">
        {{ estimationTitle }}
      </p>
    </ng-container>
    <h4 class="order-status-details-title">{{ acceptedTitle | translate }}</h4>
    <progress-bar *ngIf="fragments?.length" [fragments]="fragments"></progress-bar>
    <ng-container *ngIf="!showDaasTimingsBeforeProgress">
      <p *ngIf="estimationPreTitle" class="order-status-details-instructions">{{ estimationPreTitle | translate }}</p>
      <p *ngIf="estimationTitle" class="order-status-details-estimation-time">{{ estimationTitle }}</p>
      <p *ngIf="estimationPostTitle" class="order-status-details-instructions">{{ estimationPostTitle | translate }}</p>
    </ng-container>
    <!-- todo faropoulos rate order component-->
  </ng-container>
  <ng-container *ngSwitchCase="'REJECTED'">
    <h4 class="order-status-details-title">{{ 'your_order_has_been_rejected' | translate }}</h4>
    <p class="order-status-details-instructions" *ngFor="let rejectedText of rejectedTexts">
      {{ rejectedText | translate }}
    </p>
    <ng-container *ngIf="contactPhone">
      <p class="order-status-details-instructions order-status-details-instructions-more-info">
        {{ 'for_more_info' | translate }}
      </p>
      <a
        *ngIf="contactPhone"
        [attr.href]="'tel:' + contactPhone"
        class="order-status-details-instructions-clickable ods-typography-body-m-regular"
        (click)="onContactTextClick()"
        >{{ contactText | translate }}</a
      >
    </ng-container>
  </ng-container>
</ng-container>
