<div class="contest-wrapper" [class.small-version-wrapper]="smallVersionOnly" (click)="goToContestPage()">
  <div class="contest-flags">
    <ng-container *ngIf="hasWon && smallVersionOnly; else pending">
      <contest-flag
        [flagType]="'WIN'"
        [smallVersionOnly]="true"
        class="contest-flags-win"
        [class.small-version-only]="smallVersionOnly"
        >{{ 'congrats_' | translate }}
      </contest-flag>
    </ng-container>
    <ng-template #pending>
      <contest-flag [flagType]="'DATE'" class="contest-flags-expiration">{{ remainingTime }}</contest-flag>
      <contest-flag [flagType]="'PARTICIPATIONS'" *ngIf="hasParticipated" class="contest-flags-participations">{{
        participations
      }}</contest-flag>
    </ng-template>
  </div>
  <figure class="contest-figure" [class.small-version]="smallVersionOnly">
    <img [src]="imageUrl" [alt]="title" />
  </figure>
</div>
