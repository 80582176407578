import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges
} from '@angular/core';
import { Selection, Ingredient, ProductInstance } from '@box-types';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { BoxInfoDialogComponent } from '@box-shared/components';
import { setSelections } from '@box/utils';
import { LanguageService } from '@box-core/services/language.service';

@Component({
  selector: 'product-myo-selections',
  templateUrl: './product-myo-selections.component.html',
  styleUrls: ['./product-myo-selections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYOSelectionsComponent implements OnChanges {
  @Input() public productInstance: ProductInstance;
  @Input() public commentsAllowed = false;
  @Input() public discount: number;
  @Input() public extraIngredientDiscount: number;

  @Input() private maxLimitType: string;

  @Output() private optionChange: EventEmitter<ProductInstance> = new EventEmitter<ProductInstance>();
  @Output() private afterGroupCollapse = new EventEmitter();
  @Output() private afterGroupExpand = new EventEmitter();

  private dialogConfig: MatDialogConfig;

  constructor(private dialog: MatDialog, private languageService: LanguageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productInstance) this.productInstance = changes.productInstance.currentValue as ProductInstance;
    if (changes.commentsAllowed) this.commentsAllowed = changes.commentsAllowed.currentValue as boolean;
    if (changes.discount) this.discount = changes.discount.currentValue as number;
    if (changes.extraIngredientDiscount) {
      this.extraIngredientDiscount = changes.extraIngredientDiscount.currentValue as number;
    }
    if (changes.maxLimitType) {
      this.maxLimitType = changes.maxLimitType.currentValue as string;
      /* This method helps us initialize the possible disabled state if the user opens a Product MYO
      from the Cart or other areas in the app, that the Product Instance has already pre selectted choices.
      It handles the case of the Max Limit being reached on Product MYO Open
      We also HAVE TO use this mutation (forEach) due to the way Cart is implemented. Will be removed
      when we refactor the Cart */
      this.initializeMaxLimitType();
    }
  }

  public onAfterGroupCollapse(): void {
    this.afterGroupCollapse.emit();
  }
  public onAfterGroupExpand(): void {
    this.afterGroupExpand.emit();
  }

  public onOptionChange(selection: Selection): void {
    this.handleSelectionMaxLimit(selection);
    this.optionChange.emit(this.productInstance);
  }

  public selectOption(selection: Selection, choice: Ingredient): void {
    selection.choices.forEach((option) => (option.checked = option._id === choice._id));
    setSelections(this.productInstance);
  }

  public getSelectionLimitsText(selection: Selection): string {
    let limitsText: string;

    if (selection.maxItems && selection.freeChoices) {
      limitsText = this.languageService.getTextByKey(
        selection.freeChoices === 1 ? 'up_to_max_ingredients_free' : 'up_to_max_ingredients_free_many',
        {
          _MAX_ITEMS: String(selection.maxItems),
          _FREE_CHOICES: String(selection.freeChoices)
        }
      );
    } else if (selection.maxItems) {
      limitsText = this.languageService.getTextByKey('up_to_max_ingredients', {
        _MAX_ITEMS: String(selection.maxItems)
      });
    } else if (selection.freeChoices) {
      limitsText = this.languageService.getTextByKey(
        selection.freeChoices === 1 ? 'free_choices' : 'free_choices_many',
        {
          _FREE_CHOICES: String(selection.freeChoices)
        }
      );
    }

    return limitsText;
  }

  private initializeMaxLimitType(): void {
    const hasSelections = Boolean(this.productInstance.selections?.length);
    const isKnownMaxLimitType = this.maxLimitType === 'selection' || this.maxLimitType === 'product';
    if (!hasSelections || !isKnownMaxLimitType) return;
    this.productInstance.selections?.forEach((selection) => this.handleSelectionMaxLimit(selection));
  }

  private handleSelectionMaxLimit(selection: Selection): void {
    if (selection.multipleSelection) {
      if (this.maxLimitType === 'product') this.checkProductMaxLimit();
      if (this.maxLimitType === 'selection') this.checkSelectionMaxLimit(selection);
    }
  }

  private checkProductMaxLimit(): void {
    if (this.productInstance.maxItems) {
      const choicesCounter: number = this.getProductChoicesCounter();
      const disableChoices: boolean = this.productInstance.maxItems === choicesCounter;
      this.setDisabledProductChoices(disableChoices);
    }
  }

  private getProductChoicesCounter(): number {
    let counter = 0;
    if (this.productInstance.selections) {
      this.productInstance.selections.forEach(
        (selection) =>
          selection.multipleSelection &&
          selection.choices &&
          (counter += selection.choices.filter((choice) => choice.checked).length)
      );
    }
    return counter;
  }

  private setDisabledProductChoices(disabled: boolean): void {
    if (this.productInstance.selections) {
      this.productInstance.selections.forEach(
        (selection) =>
          selection.multipleSelection &&
          selection.choices.forEach((choice) => !choice.checked && (choice.disabled = disabled))
      );
    }
  }

  private checkSelectionMaxLimit(selection: Selection): void {
    if (selection.maxItems) {
      const choicesCounter: number = this.getSelectionChoicesCounter(selection);
      const disableChoices: boolean = selection.maxItems === choicesCounter;
      this.setDisabledSelectionChoices(selection, disableChoices);
    }
  }

  private getSelectionChoicesCounter(selection: Selection): number {
    let counter = 0;
    if (selection.choices) counter = selection.choices.filter((choice) => choice.checked).length;
    return counter;
  }

  private setDisabledSelectionChoices(selection: Selection, disabled: boolean): void {
    if (selection.choices) selection.choices.forEach((choice) => !choice.checked && (choice.disabled = disabled));
  }

  public onChoiceClick(choice: Ingredient, selection: Selection): void {
    let counter: number;
    if (this.maxLimitType) {
      this.dialogConfig = new MatDialogConfig();

      if (this.maxLimitType === 'product') {
        counter = this.getProductChoicesCounter();
        const TranslatedMessage = this.languageService.getTextByKey('you_have_exceeded_the_max_ingredients_number', {
          _MAX_ITEMS: String(this.productInstance.maxItems)
        });
        this.dialogConfig.data = {
          messages: [TranslatedMessage, 'you_first_have_to_remove_some_of_the_already_selected']
        };

        if (choice.disabled && this.productInstance.maxItems === counter) {
          this.dialog.open(BoxInfoDialogComponent, this.dialogConfig);
        }
      } else if (this.maxLimitType === 'selection') {
        counter = this.getSelectionChoicesCounter(selection);
        const TranslatedMessage = this.languageService.getTextByKey(
          'you_have_exceeded_the_max_ingredients_category_number',
          {
            _MAX_ITEMS: String(selection.maxItems)
          }
        );
        this.dialogConfig.data = {
          messages: [TranslatedMessage, 'you_first_have_to_remove_some_of_the_already_selected']
        };

        if (choice.disabled && selection.maxItems === counter) {
          this.dialog.open(BoxInfoDialogComponent, this.dialogConfig);
        }
      }
    }
  }
}
