import { getOfferMaxItemsPerOrder } from './offer.utils';
import { GetTextByKeyType, Offer, CurrencyCode } from '@box-types';
import { CurrencyFormatOptions, currencyFormat } from '../core';

export { getOfferMinOrderItemsPriceText, getOfferMaxQuantityPerOrderText };

function getOfferMinOrderItemsPriceText(
  offer: Offer,
  translateFn: GetTextByKeyType,
  currencyCode: CurrencyCode
): string {
  const { minOrderItemsPrice } = offer;
  if (!minOrderItemsPrice) return;
  const formatOptions: CurrencyFormatOptions = { currencyCode, minimumFractionDigits: 0, symbolSpace: false };
  const formattedPrice = currencyFormat(minOrderItemsPrice, formatOptions);
  return translateFn('with_minimum_order_price', {
    _PRICE: formattedPrice
  });
}

function getOfferMaxQuantityPerOrderText(offer: Offer, translateFn: GetTextByKeyType): string {
  const maxItemsPerOrder = getOfferMaxItemsPerOrder(offer);
  if (!maxItemsPerOrder) return;
  return translateFn(maxItemsPerOrder === 1 ? 'up_to_x_time_per_order' : 'up_to_x_times_per_order', {
    _MAX_ITEMS_PER_ORDER: String(maxItemsPerOrder)
  });
}
