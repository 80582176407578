<div class="box-dialog-wrapper collection-campaigns-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      class="collection-campaigns-dialog-header"
      [title]="title"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main
    #dialogBody
    bodyScroll
    (bodyScroll)="onBodyScroll($event)"
    class="box-dialog-content collection-campaigns-dialog"
  >
    <section class="collection-campaigns-dialog-section">
      <div class="collection-campaigns-dialog-nav-wrapper">
        <div
          *ngFor="let collectionCampaign of collectionCampaigns"
          matRipple
          [matRippleDisabled]="collectionCampaigns?.length <= 1"
          [class.hover]="collectionCampaigns?.length > 1"
          [matRippleColor]="'rgba(255, 134, 0, 0.2)'"
          [class.active]="selectedCampaign === collectionCampaign"
          class="collection-campaigns-dialog-nav-item"
          (click)="onTabClick(collectionCampaign)"
        >
          <img
            *ngIf="collectionCampaign.tabImageUrl"
            class="collection-campaigns-dialog-nav-item-image"
            [src]="collectionCampaign.tabImageUrl"
            [alt]="collectionCampaign.shortTabTitle"
          />
          <span
            class="collection-campaigns-dialog-nav-item-title"
            [attr.content]="
              collectionCampaigns.length > 1 ? collectionCampaign.shortTabTitle : collectionCampaign.longTabTitle
            "
            >{{
              collectionCampaigns.length > 1 ? collectionCampaign.shortTabTitle : collectionCampaign.longTabTitle
            }}</span
          >
        </div>
      </div>
      <div *ngIf="descriptionText" class="collection-campaigns-dialog-description" [innerHTML]="descriptionText"></div>
      <div *ngIf="countdown" class="collection-campaigns-dialog-countdown-container">
        <campaign-countdown [campaignName]="selectedCampaign.campaignName" class="small-size"></campaign-countdown>
      </div>
      <shop-tile-compact
        *ngFor="let shop of sortedShops"
        visibilityObserver
        [triggerOnce]="true"
        [intersectionRoot]="dialogBody"
        [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
        [intersectionRootMargin]="INTERSECTION_ROOT_MARGIN"
        (enteredViewport)="onShopEnteredViewport(shop)"
        [shop]="shop"
        class="hoverable"
        (click)="onShopCardClick(shop)"
      >
      </shop-tile-compact>
    </section>
    <div class="collection-campaigns-dialog-cta">
      <button box-button theme="primary" (click)="onShowAllShops()">{{ 'see_all_shops' | translate }}</button>
    </div>
  </main>
</div>
