import { Resolve } from '@angular/router';
import { GlobalPopupsService } from '@box-core/services';
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalPopupsResolver implements Resolve<boolean> {
  constructor(private globalPopupsService: GlobalPopupsService) {}

  resolve(): Observable<boolean> {
    this.globalPopupsService.initializeGlobalPopups();
    return of(true);
  }
}
