import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DialogService } from '@box-core/services';
import { ShopSuggestionBanner } from '@box-types';
import { ShopSuggestionBannerInfoDialogComponent } from '../shop-suggestion-banner-info-dialog/shop-suggestion-banner-info-dialog.component';
import { formatPoints } from '@box/utils';

@Component({
  selector: 'shop-suggestion-banner',
  templateUrl: './shop-suggestion-banner.component.html',
  styleUrls: ['./shop-suggestion-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopSuggestionBannerComponent implements OnInit {
  @Input() public suggestionBanner: ShopSuggestionBanner;
  @Output() private action: EventEmitter<ShopSuggestionBanner> = new EventEmitter<ShopSuggestionBanner>();
  public promoHasItems: boolean;
  public tagSign: string;
  public tagContent: string;
  public showDetails: boolean;

  constructor(private dialogService: DialogService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    const hasProducts = Boolean(this.suggestionBanner.products?.length);
    const hasOffers = Boolean(this.suggestionBanner.offers?.length);
    this.showDetails = this.showDetailsVisibility();
    this.promoHasItems = hasOffers || hasProducts;
    this.tagSign = this.generateTagSign();
    this.tagContent = this.generageTagContent();
    this.changeDetectorRef.detectChanges();
  }

  public onAction(): void {
    this.action.emit(this.suggestionBanner);
  }

  public onOpenDetails(suggestionBanner: ShopSuggestionBanner): void {
    if (!this.showDetails) return;
    const dialogConfig = { data: { suggestionBanner: suggestionBanner } };
    this.dialogService.openDialog(ShopSuggestionBannerInfoDialogComponent, dialogConfig);
  }

  private generateTagSign(): string {
    const { multiplier } = this.suggestionBanner;
    if (multiplier) return;
    return '+';
  }

  private generageTagContent(): string {
    const { pointsAmount, multiplier } = this.suggestionBanner;
    if (multiplier) return multiplier;
    return formatPoints(pointsAmount);
  }

  private showDetailsVisibility(): boolean {
    if (!this.suggestionBanner?.shopModalTitle) return false;
    if (!this.suggestionBanner?.shopModalDescription) return false;
    return true;
  }
}
