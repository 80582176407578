<div *ngIf="showDisabledBackground" class="coupon-disabled-background">
  <span class="coupon-disabled-text">{{ disabledText }}</span>
</div>
<div class="coupon-container">
  <div class="coupon-background">
    <ng-container *ngIf="available; else unavailableBackground">
      <svg *ngIf="active" class="coupon-background-overlay" width="296" height="92" viewBox="0 0 296 92">
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
          <path
            stroke="#F87A1D"
            d="M288 6.5c.967 0 1.842.392 2.475 1.025A3.489 3.489 0 0 1 291.501 10v27.515c-2.224.12-4.195 1.034-5.645 2.451A8.399 8.399 0 0 0 283.32 46c0 2.347.952 4.472 2.49 6.01a8.472 8.472 0 0 0 5.513 2.476L291.501 82c0 .966-.392 1.841-1.026 2.475a3.489 3.489 0 0 1-2.474 1.025H8a3.489 3.489 0 0 1-2.475-1.025A3.49 3.49 0 0 1 4.5 82L4.5 54.469a8.56 8.56 0 0 0 5.406-2.544A8.442 8.442 0 0 0 12.32 46c0-2.313-.923-4.41-2.422-5.942A8.474 8.474 0 0 0 4.5 37.527V10c0-.966.392-1.841 1.026-2.475A3.489 3.489 0 0 1 8 6.5Z"
          />
        </g>
      </svg>

      <svg width="101" height="92" viewBox="0 0 101 92">
        <defs>
          <path
            id="coupon-stub-available-svg-path"
            d="M284 0a4 4 0 0 1 4 4v28.002A8 8 0 1 0 287.82 48l.18-.003V76a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V47.997h.07A8 8 0 0 0 0 32.001V4a4 4 0 0 1 4-4h280ZM97 66h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1V6Z"
          />
        </defs>
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
          <g transform="translate(4 6)">
            <g>
              <use xlink:href="#coupon-stub-available-svg-path" filter="url(#coupons-stub-available-svg-filter)" />
              <use xlink:href="#coupon-stub-available-svg-path" [attr.fill]="backgroundColor" />
            </g>
          </g>
        </g>
      </svg>

      <svg width="195" height="92" viewBox="0 0 195 92">
        <defs>
          <path
            id="coupon-counterfoil-available-svg-path"
            d="M187 6a4 4 0 0 1 4 4v28.002A8 8 0 1 0 190.82 54l.18-.003V82a4 4 0 0 1-4 4H-93a4 4 0 0 1-4-4V53.997h.07A8 8 0 0 0-97 38.001V10a4 4 0 0 1 4-4h280ZM0 72h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1v-8Zm0-12h-1v8h1v-8Z"
          />
        </defs>
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
          <g>
            <use
              xlink:href="#coupon-counterfoil-available-svg-path"
              filter="url(#coupons-counterfoil-available-svg-filter)"
            />
            <use xlink:href="#coupon-counterfoil-available-svg-path" />
          </g>
        </g>
      </svg>
    </ng-container>
    <ng-template #unavailableBackground>
      <svg width="104" height="92" viewBox="0 0 104 92">
        <defs>
          <path
            id="coupons-stub-unavailable-svg-path"
            d="M97 6a4 4 0 0 1 4 4v2h-1v8h1v4h-1v8h1v4h-1v8h1v4h-1v8h1v4h-1v8h1v4h-1v8h1v2a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4V53.997h.07A8 8 0 0 0 4 38.001V10a4 4 0 0 1 4-4h89Z"
          />
        </defs>
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
          <g>
            <use xlink:href="#coupons-stub-unavailable-svg-path" filter="url(#coupons-stub-unavailable-svg-filter)" />
            <use xlink:href="#coupons-stub-unavailable-svg-path" [attr.fill]="backgroundColor" />
          </g>
        </g>
      </svg>

      <svg width="200" height="92" viewBox="0 0 200 92">
        <defs>
          <path
            id="coupons-counterfoil-unavailable-svg-path"
            d="M4 10a4 4 0 0 1 4-4h184a4 4 0 0 1 4 4l.001 28.002A8 8 0 1 0 195.82 54l.18-.003V82a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4v-2h1v-8H4v-4h1v-8H4v-4h1v-8H4v-4h1v-8H4v-4h1v-8H4v-4h1v-8H4v-2Z"
          />
        </defs>
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
          <g>
            <use
              xlink:href="#coupons-counterfoil-unavailable-svg-path"
              filter="url(#coupons-counterfoil-unavailable-svg-filter)"
            />
            <use xlink:href="#coupons-counterfoil-unavailable-svg-path" />
          </g>
        </g>
      </svg>
    </ng-template>
  </div>
  <div class="coupon-stub">
    <div *ngIf="logo" class="coupon-logo" [style.background-image]="logo"></div>
    <span
      [style.color]="primaryTextColor"
      [style.font-size]="primaryTextSize"
      [class.coupon-primary-text-disabled]="showDisabledBackground"
      [class.coupon-primary-text-unavailable]="!available"
      class="coupon-primary-text"
      >{{ primaryText }}</span
    >
    <span
      [style.color]="secondaryTextColor"
      class="coupon-secondary-text"
      [class.coupon-secondary-text-unavailable]="!available || showDisabledBackground"
      >{{ secondaryText | translate }}</span
    >
  </div>
  <div class="coupon-counterfoil">
    <span *ngIf="code" class="coupon-code" [class.coupon-code-disabled]="showDisabledBackground"
      >{{ 'code_' | translate }} {{ code }}</span
    >
    <span
      class="coupon-description ods-typography-body-s-regular"
      [class.coupon-description-disabled]="showDisabledBackground"
      >{{ shortDescription }}</span
    >
    <span class="coupon-counterfoil-text" [class.coupon-counterfoil-text-disabled]="showDisabledBackground">{{
      counterfoilText
    }}</span>
    <span *ngIf="showTerms" class="coupon-terms-text" [class.coupon-terms-text-disabled]="showDisabledBackground">{{
      'terms_apply' | translate
    }}</span>
  </div>
</div>
