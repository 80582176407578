<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="coupon_info" alignment="center" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <div class="coupon-details-dialog-content">
      <div
        *ngIf="detailsDescription"
        [innerHTML]="detailsDescription"
        class="coupon-details-dialog-content-description"
      ></div>
      <div *ngIf="expirationDateText" class="coupon-details-dialog-content-row">
        <div class="coupon-details-dialog-content-row-title">{{ 'valid_' | translate }}</div>
        <div class="coupon-details-dialog-content-row-text">{{ expirationDateText }}</div>
      </div>
      <div *ngIf="redemptionDateText" class="coupon-details-dialog-content-row">
        <div class="coupon-details-dialog-content-row-title">{{ 'last_redemption' | translate }}</div>
        <div class="coupon-details-dialog-content-row-text">{{ redemptionDateText }}</div>
      </div>
      <div *ngIf="remainingValueText" class="coupon-details-dialog-content-row">
        <div class="coupon-details-dialog-content-row-title">{{ 'you_have_a_balance' | translate }}</div>
        <div class="coupon-details-dialog-content-row-text">{{ remainingValueText }}</div>
      </div>
      <div *ngIf="code" class="coupon-details-dialog-content-row">
        <div class="coupon-details-dialog-content-row-title">{{ 'the_code_is' | translate }}</div>
        <div class="coupon-details-dialog-content-row-text">{{ code }}</div>
      </div>
      <div *ngIf="filteredShops.length" class="coupon-details-dialog-content-shops">
        <shop-tile-compact
          *ngFor="let shop of filteredShops"
          [shop]="shop"
          class="hoverable"
          (click)="onShopCardClick()"
        ></shop-tile-compact>
      </div>
    </div>
  </main>
  <footer *ngIf="showCta" #dialogFooter class="box-dialog-footer coupon-details-dialog-button-container">
    <button class="coupon-details-dialog-button" box-button theme="primary" (click)="onCouponAction()">
      {{ buttonText | translate }}
    </button>
  </footer>
</div>
