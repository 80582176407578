import { Injectable } from '@angular/core';
import { UserService } from '@box-core/services';
import { getHistoryEarningsGB, getHistoryEarningsEuro, getGBColorSchemeFamily } from '@box/utils';
import { RewardsHistoryEarning } from '@box-types';
import { currencyCode } from '@box-core/services/currency.service';

@Injectable()
export class RewardsHistoryEarningsService {
  constructor(private userService: UserService) {}

  public getGBEarningsImageClass(): string {
    const user = this.userService.getUser();
    const gbColorScheme = getGBColorSchemeFamily(user);
    return `account-history-earnings-row-image--${gbColorScheme}`;
  }

  public getHistoryEarningsEuro(): RewardsHistoryEarning {
    const user = this.userService.getUser();
    return getHistoryEarningsEuro(user, currencyCode);
  }

  public getHistoryEarningsGB(): RewardsHistoryEarning {
    const user = this.userService.getUser();
    return getHistoryEarningsGB(user);
  }
}
