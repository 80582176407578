<mat-expansion-panel #timeslotsPanel class="checkout-expansion-panel" (click)="onEdit()">
  <mat-expansion-panel-header collapsedHeight="2.5rem" expandedHeight="2.5rem">
    <div class="checkout-timeslot-wrapper">
      <div class="checkout-timeslot-content">
        <mat-icon class="checkout-timeslot-icon">{{ icon }}</mat-icon>
        <span class="checkout-timeslot-content-text ods-typography-body-m-bold">{{ timeslotText | translate }}</span>
      </div>
    </div>
  </mat-expansion-panel-header>
</mat-expansion-panel>
