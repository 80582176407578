import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  BreadcrumbListItem,
  createBreadCrumbListItem,
  getLastPathSegment,
  getPathWithoutQueryParams,
  removeLastSegment
} from '@box/utils';
import { BehaviorSubject } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable({ providedIn: 'root' })
export class BreadCrumbService {
  private readonly breadcrumbsSource = new BehaviorSubject<Record<string, string>>(undefined);
  public readonly breadcrumbs$ = this.breadcrumbsSource.asObservable();

  public clearBreadcrumbs(): void {
    this.breadcrumbsSource.next(undefined);
  }

  public setBreadcrumbs(breadcrumbs: Record<string, string>): void {
    this.breadcrumbsSource.next(breadcrumbs);
  }

  public addBreadcrumbs(newBreadcrumbs: Record<string, string>): void {
    if (!newBreadcrumbs) return;
    const breadcrumbs = this.getBreadcrumbs();
    if (!breadcrumbs) return this.setBreadcrumbs(newBreadcrumbs);
    this.setBreadcrumbs({ ...breadcrumbs, ...newBreadcrumbs });
  }

  public getBreadcrumbs(): Record<string, string> {
    return this.breadcrumbsSource.getValue();
  }

  public getExtraDataFromRoute(snapshot: ActivatedRouteSnapshot, lastPath: string): string {
    const key = snapshot?.data?.breadcrumbKey as string;
    if (!key) return '';
    const serviceData = this.getBreadcrumbs();
    if (key === 'location' && lastPath === 'delivery') return 'Delivery';
    return serviceData?.[key] ?? '';
  }

  /**
   * Builds a breadcrumb trail (except parent breadcrumb) by recursively traversing up the route tree, processing each segment of the path.
   * It handles special cases for certain paths and uses route data to create meaningful breadcrumb labels.
   * The trail is built by adding breadcrumb items to the breadcrumbItems array,
   * which is passed and modified throughout the recursive calls.
   *
   * @param snapshot - This represents the current route's snapshot, which includes information about the route, such as the path and any associated data.
   * @param path - The current path being processed in the breadcrumb trail.
   * @param position - The position in the breadcrumb trail, which is likely used for ordering the breadcrumbs.
   * @param breadcrumbItems - This is an array where each breadcrumb item is stored. It's passed by reference, meaning the function modifies this array directly.
   *
   */
  public buildChildrenBreadcrumbTrail(
    snapshot: ActivatedRouteSnapshot,
    path: string,
    position: number,
    breadcrumbItems: BreadcrumbListItem[]
  ): BreadcrumbListItem[] {
    if (!snapshot?.parent || path === '') return breadcrumbItems;

    const updatedBreadcrumbItems: BreadcrumbListItem[] = cloneDeep(breadcrumbItems);
    const lastPathSegment = getLastPathSegment(path);
    const extraData = this.getExtraDataFromRoute(snapshot, lastPathSegment);

    if (this.isSpecialDeliverySegment(lastPathSegment, path)) {
      const shortenedPath = removeLastSegment(path);
      updatedBreadcrumbItems.push(createBreadCrumbListItem(position, this.getBreadcrumbs()?.location, shortenedPath));
      return this.buildChildrenBreadcrumbTrail(
        snapshot,
        removeLastSegment(shortenedPath),
        position + 1,
        updatedBreadcrumbItems
      );
    }

    const breadcrumbName = `${snapshot.data.breadcrumbName as string}${extraData}`;
    if (breadcrumbName !== 'undefined') {
      updatedBreadcrumbItems.push(createBreadCrumbListItem(position, breadcrumbName, path));
    }

    const lastPathWithoutQueryParam = getPathWithoutQueryParams(lastPathSegment);
    if (lastPathWithoutQueryParam === 'details' || lastPathWithoutQueryParam === 'reviews') {
      const shortenedPath = removeLastSegment(path);
      updatedBreadcrumbItems.push(
        createBreadCrumbListItem(position + 1, this.getBreadcrumbs()?.shopName, shortenedPath)
      );
    }

    return this.buildChildrenBreadcrumbTrail(
      snapshot.parent,
      removeLastSegment(path),
      position + 1,
      updatedBreadcrumbItems
    );
  }

  private isSpecialDeliverySegment(segment: string, path: string): boolean {
    if ((segment === 'kouzines' || segment == 'psonia') && path.split('/').length > 3) return true;
    return false;
  }
}
