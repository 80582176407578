import { OnInit, ViewChild, ElementRef, Inject, Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { couponCodeValidator } from '@box-shared/validators/coupon-code.validator';
import { Subscription } from 'rxjs';
import { CheckoutCouponCodeDialogData, CheckoutCouponCodeDialogResponse } from './coupon-code-dialog.types';

@Component({
  selector: 'coupon-code-dialog',
  templateUrl: './coupon-code-dialog.component.html',
  styleUrls: ['./coupon-code-dialog.component.scss']
})
export class CouponCodeDialogComponent implements OnInit, OnDestroy {
  @ViewChild('couponInput', { static: true }) public couponInput: ElementRef<HTMLInputElement>;

  public couponFormControl: FormControl;
  public canSubmit: boolean;
  private code: string;
  private canSubmitSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<CouponCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CheckoutCouponCodeDialogData
  ) {}

  ngOnInit(): void {
    this.code = this.data?.code ?? '';
    this.couponInput.nativeElement.focus();
    this.setCouponFormControl();
    this.setCanSubmitValueSubscription();
    this.initializecouponFormControlValue();
  }

  ngOnDestroy(): void {
    this.canSubmitSubscription?.unsubscribe();
  }

  private setCanSubmitValueSubscription(): void {
    this.canSubmitSubscription = this.couponFormControl.valueChanges.subscribe(
      () => (this.canSubmit = this.isCouponFormValid())
    );
  }

  public closeDialog(response?: CheckoutCouponCodeDialogResponse): void {
    this.dialogRef.close(response);
  }

  public onCouponInput(): void {
    const couponCode = this.couponFormControl.value as string;
    this.couponFormControl.setValue(couponCode.trim().toLocaleUpperCase());
  }

  public onSubmit(): void {
    if (!this.canSubmit) return;
    const couponCode = this.couponFormControl.value as string;
    this.closeDialog({ code: couponCode });
  }

  private initializecouponFormControlValue(): void {
    this.couponFormControl.setValue('');
    this.couponFormControl.markAsTouched({ onlySelf: true });
  }

  private setCouponFormControl(): void {
    this.couponFormControl = new FormControl(this.code, Validators.compose([couponCodeValidator()]));
  }

  private isCouponFormValid(): boolean {
    if (!this.couponFormControl.value) return false;
    if (!this.couponFormControl.valid) return false;
    return true;
  }
}
