import { Injectable } from '@angular/core';
import { PromoCampaignsService } from '@box-core/services';
import {
  MarketPlacePointsTransactionBanner,
  Order,
  PromoCampaign,
  Shop,
  OrderMarketPlacePointsTransaction
} from '@box-types';
import uniq from 'lodash-es/uniq';
import { getPromoCampaignImage, getPromoCampaignText, generateImageSrc } from '@box/utils';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class CheckoutOrderPointsService {
  constructor(private promoCampaignsService: PromoCampaignsService, private languageService: LanguageService) {}

  public getMarketPlacePointsTransactionBanners(order: Order): MarketPlacePointsTransactionBanner[] {
    const transactions = order.marketPlacePoints.transactions ?? [];
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const banners = transactions
      .filter((transaction) => transaction?.marketPlacePoints > 0)
      .map((transaction) => this.getMarketPlacePointsTransactionBanner(order.shop, transaction, promoCampaigns))
      .sort((a, b) => b.points - a.points)
      .filter(Boolean);
    return this.groupTransactionBanners(banners);
  }

  private getMarketPlacePointsTransactionBanner(
    shop: Shop,
    transaction: OrderMarketPlacePointsTransaction,
    promoCampaigns: PromoCampaign[]
  ): MarketPlacePointsTransactionBanner {
    const event = transaction.event;
    const promoCampaign = promoCampaigns.find((pc) => pc.loyaltyEvent === event);
    if (!promoCampaign) return undefined;
    const type = promoCampaign.type;
    if (type === 'merchant_sponsored') return this.getMerchantSponsoredCampaignTransactionBanner(transaction, shop);
    return this.getRegularCampaignTransactionBanner(transaction, promoCampaign);
  }

  private getRegularCampaignTransactionBanner(
    transaction: OrderMarketPlacePointsTransaction,
    promoCampaign: PromoCampaign
  ): MarketPlacePointsTransactionBanner {
    const points = transaction.marketPlacePoints;
    const transactionsGroup = promoCampaign.transactionsGroup;
    const promoImage = getPromoCampaignImage(promoCampaign, 'checkoutBannerLogo');
    const image = generateImageSrc(promoImage);
    const orderAcceptanceDescription = getPromoCampaignText(promoCampaign, 'orderAcceptanceDescription');
    return { image, points, transactionsGroup, orderAcceptanceDescription };
  }

  private getMerchantSponsoredCampaignTransactionBanner(
    transaction: OrderMarketPlacePointsTransaction,
    shop: Shop
  ): MarketPlacePointsTransactionBanner {
    const points = transaction.marketPlacePoints;
    const image = generateImageSrc(shop.logo);
    const orderAcceptanceDescription = this.languageService
      .getTextByKey('points_from_the_shop')
      .replace('_SHOP_NAME', shop.name);
    return { image, points, orderAcceptanceDescription };
  }

  private groupTransactionBanners(banners: MarketPlacePointsTransactionBanner[]): MarketPlacePointsTransactionBanner[] {
    const bannersWithGroups = banners.filter((banner) => banner.transactionsGroup);
    const bannersWithoutGroups = banners.filter((banner) => !banner.transactionsGroup);
    const uniqGroupNames = uniq(
      bannersWithGroups.map((banner) => banner.transactionsGroup)
    ) as MarketPlacePointsTransactionBanner[];
    const uniqBannersWithGroups = uniqGroupNames.map((group) => {
      const groupedBanners = bannersWithGroups.filter((banner) => banner.transactionsGroup === group);
      return this.mergeTransactionBanners(groupedBanners);
    });
    return [...bannersWithoutGroups, ...uniqBannersWithGroups];
  }

  private mergeTransactionBanners(banners: MarketPlacePointsTransactionBanner[]): MarketPlacePointsTransactionBanner {
    const firstBanner = banners[0];
    const totalPoints = banners.reduce((acc, cur) => acc + cur.points, 0);
    return { ...firstBanner, points: totalPoints };
  }
}
