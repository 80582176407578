<div class="shop-suggestion-banner-wrapper" (click)="onOpenDetails(suggestionBanner)">
  <figure class="shop-suggestion-banner-image" [class.full-size-image]="promoHasItems">
    <img [src]="suggestionBanner.image" [alt]="suggestionBanner.campaignName" />
  </figure>
  <div class="shop-suggestion-banner-text-wrapper">
    <ng-container *ngIf="promoHasItems; else normalDescription">
      <span class="shop-suggestion-banner-text">
        {{ 'earn_' | translate }}
        <points-tag [sign]="tagSign">{{ tagContent }}</points-tag>
        {{ 'the_points' | translate }}!
      </span>
    </ng-container>
    <ng-template #normalDescription>
      <div class="shop-suggestion-banner-content">
        <mat-icon *ngIf="showDetails" class="shop-suggestion-banner-info-button" fontSet="material-icons-round"
          >info_outline</mat-icon
        >
        <span class="shop-suggestion-banner-text">{{ suggestionBanner.suggestionDescription }}</span>
      </div>
    </ng-template>
  </div>
  <button
    *ngIf="promoHasItems"
    box-button
    size="small"
    theme="primary"
    class="shop-suggestion-banner-button"
    (click)="onAction()"
  >
    {{ 'i_want_it' | translate }}!
  </button>
</div>
