<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header checkout-invoice-dialog-header">
    <box-dialog-header
      [class.transparent-header]="false"
      [alternativeTheme]="false"
      title="invoice_details"
      alignment="center"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody class="box-dialog-content">
    <div class="checkout-invoice-dialog-wrapper">
      <div class="checkout-invoice-dialog-main-wrapper">
        <div class="checkout-invoice-dialog-content">
          <form [formGroup]="invoiceForm" class="checkout-invoice-dialog-form">
            <div class="checkout-invoice-dialog-form-wrapper">
              <div class="checkout-invoice-dialog-form-section">
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'company_name' | translate }}</mat-label>
                  <input
                    matInput
                    id="companyName"
                    autocomplete="off"
                    name="companyName"
                    formControlName="companyName"
                    required
                  />
                  <mat-error><span [innerHTML]="'comapny_name_field_is_required' | translate | safeHtml"></span></mat-error>
                </mat-form-field>

                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'activity_' | translate }}</mat-label>
                  <input
                    matInput
                    id="profession"
                    autocomplete="off"
                    name="profession"
                    formControlName="profession"
                    required
                  />
                  <mat-error><span [innerHTML]="'activity_field_is_required' | translate | safeHtml"></span></mat-error>
                </mat-form-field>

                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'afm_' | translate }}</mat-label>
                  <input matInput id="taxId" autocomplete="off" name="taxId" formControlName="taxId" required />
                  <mat-error>{{ 'afm_field_is_invalid' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'doy_' | translate }}</mat-label>
                  <input
                    matInput
                    id="taxOffice"
                    autocomplete="off"
                    name="taxOffice"
                    formControlName="taxOffice"
                    required
                  />
                  <mat-error><span [innerHTML]="'doy_field_is_required' | translate | safeHtml"></span></mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'company_telephone' | translate }}</mat-label>
                  <input
                    matInput
                    id="companyPhone"
                    autocomplete="off"
                    name="companyPhone"
                    formControlName="companyPhone"
                    required
                  />
                  <mat-error *ngIf="invoiceForm.controls.companyPhone.errors?.required">
                    <span [innerHTML]="'company_telephone_field_is_required' | translate | safeHtml"></span>
                  </mat-error>
                  <mat-error *ngIf="invoiceForm.controls.companyPhone.errors?.invalidPhone">
                    {{ 'contact_telephone_is_invalid' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="checkout-invoice-dialog-form-section">
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'address_' | translate }}</mat-label>
                  <input
                    matInput
                    id="companyAddress"
                    autocomplete="off"
                    name="companyAddress"
                    formControlName="companyAddress"
                    required
                  />
                  <mat-error>{{ 'address_field_is_invalid' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'number_' | translate }}</mat-label>
                  <input
                    matInput
                    id="companyAddressNumber"
                    autocomplete="off"
                    name="companyAddressNumber"
                    formControlName="companyAddressNumber"
                    required
                  />
                  <mat-error><span [innerHTML]="'number_field_is_required' | translate | safeHtml"></span></mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'area_' | translate }}</mat-label>
                  <input
                    matInput
                    id="companyArea"
                    autocomplete="off"
                    name="companyArea"
                    formControlName="companyArea"
                    required
                  />
                  <mat-error>{{ 'area_field_is_invalid' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'town_' | translate }}</mat-label>
                  <input
                    matInput
                    id="companyCity"
                    autocomplete="off"
                    name="companyCity"
                    formControlName="companyCity"
                    required
                  />
                  <mat-error>{{ 'town_field_is_invalid' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>{{ 'postal_code' | translate }}</mat-label>
                  <input
                    matInput
                    id="companyPostalCode"
                    autocomplete="off"
                    name="companyPostalCode"
                    formControlName="companyPostalCode"
                    required
                  />
                  <mat-error>{{ 'postal_code_field_is_invalid' | translate }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer checkout-invoice-dialog-footer">
    <button
      box-button
      theme="primary"
      class="checkout-invoice-dialog-button"
      [disabled]="!invoiceForm.valid"
      (click)="onSubmit()"
    >
    {{ 'confirm_' | translate }}
    </button>
  </footer>
</div>
