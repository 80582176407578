import { Injectable } from '@angular/core';
import { Observable, retryWhen, switchMap, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { APIError, ConfirmDialogResponse, ConfirmDialogData } from '@box-types';
import { DialogService } from '@box-core/services/dialog.service';

const DEFAULT_MAX_RETRIES = 1;
const DEFAULT_DELAY_MS = 1000;

@Injectable({ providedIn: 'any' })
export class DelayedRetryService {
  constructor(private dialogService: DialogService) {}

  public delayedRetry<T>(
    showConfirmDialog = false,
    delayMs = DEFAULT_DELAY_MS,
    retries = DEFAULT_MAX_RETRIES
  ): (src: Observable<T>) => Observable<T> {
    return (src: Observable<T>) =>
      src.pipe(
        retryWhen((error: Observable<APIError>) =>
          error.pipe(
            delay(delayMs),
            switchMap((error) => {
              if (showConfirmDialog) return this.openRetryDialog(error);
              if (retries-- > 0) return of(error);
              throw error;
            })
          )
        )
      );
  }

  private openRetryDialog(error: APIError): Observable<APIError> {
    const dialogData = {
      title: error.userTitle ?? 'something_went_wrong',
      messages: [error.userMessage ?? 'try_again_in_a_bit'],
      confirmText: 'try_again',
      cancelText: 'confirm_'
    } as ConfirmDialogData;
    return this.dialogService
      .openConfirmDialog(dialogData)
      .afterClosed()
      .pipe(
        switchMap((response: ConfirmDialogResponse) => {
          if (response?.accepted) return of(error);
          throw error;
        })
      );
  }
}
