import { Injectable } from '@angular/core';
import { CoreService, LoaderService, translate } from '@box-core/services';
import { LocationsService } from '../../services';
import { Location, Shop, ShopDetails } from '@box-types';
import {
  generateLocationCuisinesDeliveryAnchors,
  generateLocationShopsDeliveryAnchors,
  generateSEODescriptionHTML
} from '@box/utils';
import { of, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { ShopExtraInfoLocationData } from './shop-extra-info.types';

@Injectable()
export class ShopExtraInfoService {
  constructor(
    private coreService: CoreService,
    private locationsService: LocationsService,
    private loaderService: LoaderService
  ) {}

  public getShopDescriptionHTML(shop: Shop, shopDetails: ShopDetails): string {
    const template = this.getShopDescriptionHTMLTemplate(shop);
    return generateSEODescriptionHTML(shopDetails, template, translate);
  }

  public getShopLocationData(shop: Shop, location: Location): Observable<ShopExtraInfoLocationData> {
    const locationKey = shop.locationKey;
    const cuisineKey = shop.mainCuisine?.key;
    if (!cuisineKey) return of(undefined);
    const cuisines = this.coreService.foodCuisines.getValue();

    this.loaderService.setState(true);
    return this.locationsService.fetchShopsByCuisineAndLocation(locationKey, cuisineKey).pipe(
      finalize(() => this.loaderService.setState(false)),
      map((shops) => ({
        cuisine: shop.mainCuisine,
        cuisineAnchors: generateLocationCuisinesDeliveryAnchors(location, cuisines),
        shopsAnchors: generateLocationShopsDeliveryAnchors(location, shops)
      }))
    );
  }

  private getCuisineHTMLTemplate(shop: Shop): string {
    const cuisines = this.coreService.cuisines.getValue();
    const cuisineKey = shop.mainCuisine?.key;
    if (!cuisines?.length || !cuisineKey) return '';
    return cuisines.find((cuisine) => cuisine.key === cuisineKey)?.shopTemplate;
  }

  private getChainHTMLTemplate(shop: Shop): string {
    const chains = this.coreService.chains.getValue();
    if (!chains?.length || !shop.chain) return '';
    return chains.find((chain) => chain.chainKey === shop.chain)?.shopTemplate;
  }

  private getShopDescriptionHTMLTemplate(shop: Shop): string {
    if (shop.chain) return this.getChainHTMLTemplate(shop);
    return this.getCuisineHTMLTemplate(shop);
  }
}
