<div class="suggestion-dialog-banner-wrapper">
  <figure class="suggestion-dialog-banner-image">
    <img [src]="imageSrc" [alt]="imageAlt" />
  </figure>
  <div class="suggestion-dialog-banner-content">
    <p class="suggestion-dialog-banner-text">{{ description }}</p>
  </div>
  <div class="suggestion-dialog-banner-container">
    <ng-container *ngIf="multiplier; else points">
      <span class="suggestion-dialog-banner-container-multiplier">
        {{ multiplier }}
      </span>
    </ng-container>
    <ng-template #points>
      <span class="suggestion-dialog-banner-container-points-amount">
        {{ pointsAmount }}
      </span>
    </ng-template>
    <span class="suggestion-dialog-banner-container-text">{{ 'the_points_capital' | translate }}</span>
  </div>
</div>
