import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  HostBinding,
  AfterViewInit
} from '@angular/core';
import { ProductMYOState } from '@box-delivery/delivery.types';
import { Product, ProductDetails, ProductInstance, Review, APIError } from '@box-types';
import {
  isElementScrollable,
  getProductInstanceDescription,
  remainingRequiredChoices,
  getFreeChoicesType,
  getMaxLimitType,
  getProductInstancePrice,
  requiresMakeYourOwn
} from '@box/utils';
import { finalize } from 'rxjs';
import { ProductMYOService } from './product-myo.service';
import { ProductMYOOptions } from './product-myo.types';
import { BodyScrollEvent } from '@box-shared/directives/body-scroll-event.types';
import { DialogService } from '@box-core/services';

@Component({
  selector: 'product-myo',
  templateUrl: './product-myo.component.html',
  styleUrls: ['./product-myo.component.scss'],
  providers: [ProductMYOService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYOComponent implements OnChanges, AfterViewInit {
  @ViewChild('wrapper') private wrapperElement: ElementRef<HTMLDivElement>;
  @Input() private options: ProductMYOOptions;
  @Output() private myoBack = new EventEmitter();
  @Output() private myoClose = new EventEmitter();
  @Output() private myoSubmit = new EventEmitter<ProductMYOOptions>();

  public canBack: boolean;
  public canClose: boolean;
  public loading: boolean;
  public subHeaderStuck: boolean;
  public bodyScrollable: boolean;
  public bodyScrolled: boolean;
  public bodyScrolledToBottom: boolean;
  public state: ProductMYOState = 'MYO';
  public reviews: Review[];
  public editMode: boolean;
  public buttonText: string;
  public isSuperMarket: boolean;
  public product: Product;
  public productInstance: ProductInstance;
  public productInstanceToEdit: ProductInstance;
  public discountSticker: string;
  public requiresMakeYourOwn: boolean;
  public maxLimitType: string;
  public freeChoicesType: string;
  public heroImageSrc: string;
  public images: string[];
  public productDetails: ProductDetails[];
  public buttonPrice: number;
  public showOnlyAdditionalPrice: boolean;
  public showQuantity: boolean;
  public commentsAllowed: boolean;
  public discount: number;
  public extraIngredientDiscount: number;
  public myoTabText: string;
  public reviewsLoading: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private productMYOService: ProductMYOService,
    private dialogService: DialogService
  ) {}

  @HostBinding('class') public hostClass = 'product-myo';

  ngOnChanges(changes: SimpleChanges): void {
    this.options = changes.options.currentValue as ProductMYOOptions;
    this.isSuperMarket = this.options.isSuperMarket;
    this.product = this.options.product;
    this.productInstance = this.options.productInstance;
    this.editMode = this.options.editMode;
    this.buttonText = this.editMode ? 'save_' : 'addition_';
    this.productInstanceToEdit = this.options.productInstanceToEdit;
    this.canBack = this.options.canBack;
    this.canClose = this.options.canClose;
    this.showOnlyAdditionalPrice = this.options.showOnlyAdditionalPrice ?? false;
    this.showQuantity = this.options.showQuantity ?? true;
    this.commentsAllowed = this.options.commentsAllowed ?? false;
    this.discount = this.options.offerInstance?.percentageDiscount ?? 0;
    this.extraIngredientDiscount = this.options.offerInstance?.extraIngredientsHavePercentageDiscount
      ? this.options.offerInstance?.percentageDiscount
      : 0;
    this.discountSticker = this.product.discountSticker;
    this.maxLimitType = getMaxLimitType(this.product);
    this.freeChoicesType = getFreeChoicesType(this.product);
    this.buttonPrice = this.productMYOService.getButtonPrice(this.options, this.productInstance);
    this.heroImageSrc = this.productMYOService.getProductHeroImageSrc(this.product);
    this.images = this.productMYOService.getProductImages(this.product, this.isSuperMarket);
    this.productDetails = this.productMYOService.getProductDetails(this.product, this.isSuperMarket);
    this.fetchReviews();
    this.myoTabText = this.productMYOService.getMYOTabText();
    if (this.productInstance.hasCustomPrice && this.options.customPriceCheck) this.getDynamicPrice();
  }

  ngAfterViewInit(): void {
    this.bodyScrollable = isElementScrollable(this.wrapperElement.nativeElement);
  }

  public onSubmit(): void {
    const remainingChoices = remainingRequiredChoices(this.productInstance);
    if (remainingChoices > 0) return this.productMYOService.showProductChoicesThresholdDialog(remainingChoices);
    this.myoSubmit.emit({
      product: this.product,
      productInstance: this.productInstance,
      editMode: this.editMode,
      productInstanceToEdit: this.productInstanceToEdit
    });
  }

  public onBack(): void {
    this.myoBack.emit();
  }

  public onClose(): void {
    this.myoClose.emit();
  }

  public onAfterGroupCollapse(): void {
    this.bodyScrollable = isElementScrollable(this.wrapperElement.nativeElement);
  }

  public onAfterGroupExtend(): void {
    this.bodyScrollable = isElementScrollable(this.wrapperElement.nativeElement);
  }

  public onBodyScroll(event: BodyScrollEvent): void {
    this.bodyScrolled = event.scrolled;
    this.bodyScrolledToBottom = event.scrolledToBottom;
  }

  public onSubHeaderStickyChanges(isStuck: boolean): void {
    this.subHeaderStuck = isStuck;
  }

  public onStateChange(state: ProductMYOState): void {
    this.state = state;
    this.wrapperElement.nativeElement.scrollTop = 0;
  }

  public onProductAdd(): void {
    this.productInstance = { ...this.productInstance, quantity: this.productInstance.quantity + 1 };
  }

  public onProductRemove(): void {
    if (this.productInstance.quantity <= 1) return;
    this.productInstance = { ...this.productInstance, quantity: this.productInstance.quantity - 1 };
  }

  public setInstanceDetails(): void {
    const { basePrice, selections } = this.productInstance;
    // todo refactor
    // we are triggering the change detection with this
    // needs to change after Cart Refactor
    const price = getProductInstancePrice(this.productInstance);
    const description = getProductInstanceDescription(selections);
    this.productInstance = { ...this.productInstance, description, price, ingredientsPrice: price - basePrice };
    // todo refactor duplicate calculations
    this.buttonPrice = this.productMYOService.getButtonPrice(this.options, this.productInstance);
  }

  public onOptionChange(): void {
    this.requiresMakeYourOwn = requiresMakeYourOwn(this.productInstance.selections);
    if (this.productInstance.hasCustomPrice && this.options.customPriceCheck) return this.getDynamicPrice();
    this.setInstanceDetails();
  }

  private fetchReviews(): void {
    this.reviewsLoading = true;
    this.changeDetectorRef.detectChanges();
    const productId = this.options.offerInstance ? this.product.productId : this.product._id;
    this.productMYOService
      .fetchProductReviews(productId)
      .pipe(
        finalize(() => {
          this.reviewsLoading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((reviews) => (this.reviews = reviews));
  }

  private getDynamicPrice(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    const productId = this.options.offerInstance ? this.product.productId : this.product._id;
    this.productMYOService
      .getDynamicPrice(productId, this.productInstance)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (price) => {
          const { selections } = this.productInstance;
          // we are triggering the change detection with this
          // needs to change after Cart Refactor
          const description = getProductInstanceDescription(selections);
          this.productInstance = {
            ...this.productInstance,
            description,
            price,
            basePrice: price
          };
          this.buttonPrice = price;
        },
        error: (error: APIError) => {
          this.dialogService
            .openErrorDialog(error)
            .afterClosed()
            .subscribe(() => this.onClose());
        }
      });
  }
}
