import { User, CurrencyCode } from '@box-types';
import { formatPoints, currencyFormat, CurrencyFormatOptions } from '../core';

function getUserPointsBalanceText(user: User): string {
  const remainingPoints = user.marketPlacePoints.remainingPoints;
  return formatPoints(remainingPoints);
}

function getUserCurrencyBalanceText(user: User, redeemCurrencyRate: number, currencyCode: CurrencyCode): string {
  const remainingPoints = user.marketPlacePoints.remainingPoints;
  const currencyAmount = remainingPoints / redeemCurrencyRate;
  const formatOptions: CurrencyFormatOptions = { currencyCode, symbolSpace: true, minimumFractionDigits: 2 };
  return currencyFormat(currencyAmount * 100, formatOptions);
}

export { getUserPointsBalanceText, getUserCurrencyBalanceText };
