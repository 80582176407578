import { Injectable } from '@angular/core';
import { ConfigurationService } from '@box-core/services';
import { LanguageService } from '@box-core/services/language.service';
import { MarketCardBanner, Order, MarketCardPointsBanner, SMLoyaltyConfiguration } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Injectable()
export class CheckoutOrderMarketCardPointsService {
  constructor(private configService: ConfigurationService, private languageService: LanguageService) {}

  public getMarketLoyaltyInfo(order: Order): SMLoyaltyConfiguration {
    const integrator = order.shop.integrator?.company;
    const config = this.configService.getConfiguration();
    if (!config || !config.smLoyaltyInfo) return undefined;
    return config.smLoyaltyInfo.find((i) => i.integrator === integrator);
  }

  public marketCardCreated(): boolean {
    return Boolean(window.sessionStorage.getItem('Box:MarketCardCreated'));
  }

  public clearMarketCardCreated(): void {
    window.sessionStorage.removeItem('Box:MarketCardCreated');
  }

  public getMarketCardBanner(config: SMLoyaltyConfiguration): MarketCardBanner {
    const image = generateImageSrc(config.smallCardPlaceholder);
    const cardName = config.shortCardName;
    const superMarket = config.smName;
    const description = this.languageService.getTextByKey('your_new_card_has_been_created', {
      _CARD_NAME: config.shortCardName
    });
    return { image, cardName, superMarket, description };
  }

  public getMarketCardPointsBanner(order: Order, config: SMLoyaltyConfiguration): MarketCardPointsBanner {
    const image = generateImageSrc(config.orderAcceptanceLogo);
    const cardName = config.shortCardName;
    const superMarket = config.smName;
    const points = order.integratorLoyalty?.pointsFromOrder;
    return { image, cardName, superMarket, points };
  }
}
