import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@box-core/services/language.service';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(key: string, replacementDictionary?: Record<string, string>): string {
    return this.languageService.getTextByKey(key, replacementDictionary);
  }
}
