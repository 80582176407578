<header class="coupon-redemption-dialog-header">
  <div
    *ngIf="imageSrc"
    class="coupon-redemption-dialog-header-image"
    [style.background-image]="'url(' + imageSrc + ')'"
  ></div>
  <button mat-icon-button (click)="closeDialog()" class="coupon-redemption-dialog-header-button">
    <mat-icon>close</mat-icon>
  </button>
  <h4 class="coupon-redemption-dialog-header-title ods-typography-body-m-bold">{{ 'congrats_' | translate }}</h4>
</header>
<main class="coupon-redemption-dialog-main">
  <p *ngIf="points" class="coupon-redemption-dialog-text ods-typography-body-m-bold">
    {{ 'you_just_won' | translate }}
    <points-tag>{{ points | number: '1.0-0' }}</points-tag>
    {{ 'the_points' | translate }}!
  </p>
  <p class="coupon-redemption-dialog-text ods-typography-body-m-bold">{{ benefitReason }}</p>
</main>
