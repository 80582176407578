<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="card_confirmation" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="market-card-validation-dialog-content">
      <div class="market-card-validation-dialog-image-container">
        <figure class="market-card-validation-dialog-image">
          <img [src]="logo" [alt]="logoAlt" />
        </figure>
      </div>

      <p class="market-card-validation-dialog-text" [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'EMAIL'">
          <div *ngIf="maskedEmail">
            <span [innerHTML]="t('we_have_sent_you_a_code_to_email', { _EMAIL: maskedEmail }) | safeHtml"></span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'OTP'">
          <div *ngIf="maskedPhoneNumber">
            <span
              [innerHTML]="t('we_have_sent_you_a_code_with_sms', { _PHONE_NUMBER: maskedPhoneNumber }) | safeHtml"
            ></span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PIN'">
          {{ 'type_the_pin_code' | translate }}
        </ng-container>
      </p>

      <mat-form-field class="market-card-validation-dialog-field">
        <mat-label>{{ 'verification_code' | translate }}</mat-label>
        <input
          matInput
          autocomplete="off"
          name="validationCode"
          (keydown.enter)="onValidate()"
          [formControl]="validationFormControl"
        />
      </mat-form-field>

      <button
        box-button
        theme="primary"
        class="market-card-validation-dialog-button"
        [disabled]="validationButtonDisabled"
        (click)="onValidate()"
      >
        {{ 'confirm_' | translate }}
      </button>

      <p class="market-card-validation-dialog-text">
        {{
          'for_any_issue_regarding_the_confirmation_of_your_card'
            | translate: { _SHOP_NAME: shopName, _SHOP_NUMBER: shopNumber }
        }}
      </p>

      <div class="market-card-validation-dialog-resend">
        <span class="market-card-validation-dialog-resend-text">{{ resendText | translate }}</span>
        <button
          box-button
          theme="secondary"
          size="small"
          borders="none"
          class="market-card-validation-dialog-resend-button"
          (click)="onResend()"
        >
          {{ resendButtonText | translate }}
        </button>
      </div>
    </div>
  </main>
</div>
