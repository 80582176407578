import { Component, Renderer2, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product, ProductInstance, ConfirmDialogResponse } from '@box-types';
import { CartService, DialogService, ShopService } from '@box-core/services';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { SameMYODialogData, SameMYODialogResponse } from './same-myo-dialog.types';
import { Offer, OfferInstance } from '@box-types';
import { CartActionResponse } from '@box/utils';

@Component({
  selector: 'same-myo-dialog',
  templateUrl: './same-myo-dialog.component.html',
  styleUrls: ['./same-myo-dialog.component.scss']
})
export class SameMYODialogComponent extends BoxDialogWrapperComponent {
  public type: string;
  public cartItem: Product | Offer;
  public isSuperMarket: boolean;
  private item: Product | Offer;

  constructor(
    public renderer: Renderer2,
    private cartService: CartService,
    private shopService: ShopService,
    private dialogRef: MatDialogRef<SameMYODialogComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: SameMYODialogData
  ) {
    super(renderer);
    this.item = this.data.item;
    this.type = this.data.type;
    this.cartItem = this.getCartItem(this.item._id);
    this.isSuperMarket = this.shopService.shop.value.isSuperMarket;
  }

  public closeDialog(data?: SameMYODialogResponse) {
    this.dialogRef.close(data);
  }

  public openMYO(): void {
    this.closeDialog({ openMYO: true });
  }

  public onInstanceAdd(instance: ProductInstance | OfferInstance): void {
    const response: CartActionResponse = this.addInstanceToCart(instance);
    if (response === 'CART_LIMIT_REACHED') return;
    if (response === 'ITEM_LIMIT_REACHED') return;
    if (response === 'CHOICES_THRESHOLD_NOT_REACHED') return;
    this.cartItem = this.getCartItem(this.item._id);
  }

  public onInstanceRemove(instance: ProductInstance | OfferInstance): void {
    const removeCallBack = () => {
      this.removeInstanceFromCart(instance);
      this.cartItem = this.getCartItem(this.item._id);
    };

    if (instance.quantity === 1) return this.showRemovalConfirmationDialog(removeCallBack);
    return removeCallBack();
  }

  private showRemovalConfirmationDialog(callBack: () => void): void {
    const confirmConfig = {
      title: 'remove_from_cart',
      messages: ['this_action_will_remove_the_product_from_the_cart']
    };

    this.dialogService
      .openConfirmDialog(confirmConfig)
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (!data?.accepted) return;
        callBack();
        const shouldClose = !this.cartItem?.cartInstances?.length;
        if (shouldClose) return this.closeDialog();
      });
  }

  private addInstanceToCart(instance: ProductInstance | OfferInstance): CartActionResponse {
    if (this.type === 'offer') {
      const offer = this.cartItem as Offer;
      const offerInstance = { ...instance, quantity: 1 } as OfferInstance;
      const cartResponse = this.cartService.addOffer(offer, offerInstance);
      if (cartResponse === 'OFFER_ADDED') this.shopService.syncCartOfferToMenu(offer);
      return cartResponse;
    }
    if (this.type === 'product') {
      const product = this.cartItem as Product;
      const productInstance = { ...instance, quantity: 1 } as ProductInstance;
      const cartResponse = this.cartService.addProduct(product, productInstance);
      if (cartResponse === 'PRODUCT_ADDED') this.shopService.syncCartProductToMenu(product);
      return cartResponse;
    }
  }

  private removeInstanceFromCart(instance: ProductInstance | OfferInstance): void {
    if (this.type === 'offer') {
      const offer = this.cartItem as Offer;
      const offerInstance = instance as OfferInstance;
      const cartResponse = this.cartService.removeOffer(offer, offerInstance);
      if (cartResponse === 'OFFER_REMOVED') this.shopService.syncCartOfferToMenu(offer);
    }
    if (this.type === 'product') {
      const product = this.cartItem as Product;
      const productInstance = instance as ProductInstance;
      const cartResponse = this.cartService.removeProduct(product, productInstance);
      if (cartResponse === 'PRODUCT_REMOVED') this.shopService.syncCartProductToMenu(product);
    }
  }

  private getCartItem(id: string): Product | Offer {
    if (this.type === 'offer') return this.getCartOffer(id);
    if (this.type === 'product') return this.getCartProduct(id);
  }

  private getCartOffer(id: string): Offer {
    const cartOffers = this.cartService.getCart().offers;
    return cartOffers.find((offer) => offer._id === id);
  }

  private getCartProduct(id: string): Product {
    const cartProducts = this.cartService.getCart().products;
    return cartProducts.find((product) => product._id === id);
  }
}
