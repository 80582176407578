<div class="rewards-balance-badge-wrapper hover-opacity">
  <div class="rewards-balance-badge-container">
    <div class="rewards-balance-badge-coupons" (click)="onClickCoupon()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.4231 10.2307C21.7417 10.2307 22 9.97242 22 9.65383V6.57691C22 6.25828 21.7417 6 21.4231 6H8.73078V8.88461C8.73078 9.20324 8.47246 9.46152 8.15387 9.46152C7.83527 9.46152 7.57695 9.2032 7.57695 8.88461V6H2.57695C2.25832 6 2 6.25828 2 6.57691V9.65383C2 9.97246 2.25832 10.2307 2.57691 10.2307C3.53125 10.2307 4.3077 11.0071 4.3077 11.9615C4.3077 12.9159 3.53129 13.6923 2.57691 13.6923C2.25832 13.6923 2 13.9506 2 14.2692V17.3461C2 17.6648 2.25832 17.923 2.57691 17.923H7.57691V15.0384C7.57691 14.7198 7.83523 14.4615 8.15383 14.4615C8.47242 14.4615 8.73074 14.7198 8.73074 15.0384V17.923H21.423C21.7417 17.923 22 17.6647 22 17.3461V14.2692C22 13.9506 21.7416 13.6923 21.423 13.6923C20.4687 13.6923 19.6923 12.9159 19.6923 11.9615C19.6923 11.0071 20.4687 10.2307 21.4231 10.2307ZM8.73078 12.7307C8.73078 13.0494 8.47246 13.3077 8.15387 13.3077C7.83527 13.3077 7.57695 13.0493 7.57695 12.7307V11.1923C7.57695 10.8736 7.83527 10.6154 8.15387 10.6154C8.47246 10.6154 8.73078 10.8737 8.73078 11.1923V12.7307Z"
        />
      </svg>
    </div>
    <div class="rewards-balance-badge-points" (click)="onClickPoints()">
      <div class="rewards-balance-badge-points-content ods-typography-body-s-bold" [ngSwitch]="state">
        <div class="rewards-balance-badge-points-text-container" *ngSwitchCase="'POINTS'">
          <div>{{ pointsText }}</div>
          <div>{{ 'points_lowercase' | translate }}</div>
        </div>
        <div class="rewards-balance-badge-points-text-container" *ngSwitchCase="'CURRENCY'">
          <div>{{ 'you_have' | translate }}</div>
          <div>{{ currencyText }}</div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isTutorialOpen">
    <rewards-preview-overlay (closeClick)="onClickClose()" (ctaClick)="onClickCta()"></rewards-preview-overlay>
  </ng-container>
</div>
