import { Injectable } from '@angular/core';
import { appConfig } from '@box-configs/app.config';
import { Observable } from 'rxjs';
import { DialogService } from '@box-core/services';
import { GenerateOTPOptions, GenerateOTPResponse, VerifyOTPOptions, VerifyOTPResponse, APIError } from '@box-types';
import { BoxInfoDialogComponent } from '..';
import { MatDialogRef } from '@angular/material/dialog';
import { formatPhoneToITU } from '@box/utils';
import { OtpService } from '@box-core/services/otp.service';
import { PhoneVerificationDialogState } from '@box-shared/components/phone-verification-dialog/phone-verification-dialog.types';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class PhoneVerificationDialogService {
  constructor(
    private otpService: OtpService,
    private dialogService: DialogService,
    private languageService: LanguageService
  ) {}

  public generateOTP(options: GenerateOTPOptions): Observable<GenerateOTPResponse> {
    return this.otpService.generateOTP(options);
  }

  public verifyPhone(options: VerifyOTPOptions): Observable<VerifyOTPResponse> {
    return this.otpService.verifyOTP(options);
  }

  public getDescription(state: PhoneVerificationDialogState, phone: string): string {
    if (state === 'PASSWORD') {
      const formattedPhone = formatPhoneToITU(phone);
      const desciption = this.languageService
        .getTextByKey('phone_verification_dialog_description_1')
        .replace('_FORMATTED_PHONE', formattedPhone);
      return desciption;
    }
    return 'phone_verification_dialog_description_2';
  }

  public getSupportPhoneNumber(): string {
    return appConfig.support.PHONE_NUMBER;
  }

  public openErrorDialog(error: APIError): MatDialogRef<BoxInfoDialogComponent> {
    return this.dialogService.openErrorDialog(error);
  }
}
