<ng-container *ngIf="showReviews; else emptyState">
  <ng-container *ngFor="let review of reviews; let last = last">
    <review [review]="review"></review>
    <div *ngIf="!last" class="box-divider shop-reviews-divider"></div>
  </ng-container>
</ng-container>

<ng-template #emptyState>
  <div class="shop-page-reviews-empty-state">
    <p class="shop-page-reviews-text">{{ 'no_reviews_for_this_shop' | translate }}</p>
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="40">
      <path
        fill="#eee"
        d="m102.253 18.783-8.71-.866c-.55-.053-1.024-.42-1.248-.972l-3.12-7.96a1.467 1.467 0 0 0-2.77 0l-3.095 7.96c-.2.551-.699.92-1.248.972l-8.71.866c-1.298.132-1.823 1.84-.85 2.759l6.565 6.068c.424.394.599.972.474 1.55l-1.971 8.51c-.3 1.34 1.074 2.444 2.222 1.735l7.263-4.493a1.445 1.445 0 0 1 1.522 0l7.263 4.493c1.148.709 2.52-.368 2.221-1.734l-1.947-8.511a1.621 1.621 0 0 1 .475-1.55l6.564-6.068c.948-.92.399-2.627-.899-2.759ZM66.253 10.783l-8.71-.866c-.55-.053-1.024-.42-1.248-.972l-3.12-7.96a1.467 1.467 0 0 0-2.77 0l-3.095 7.96c-.2.551-.699.92-1.248.972l-8.71.866c-1.298.132-1.823 1.84-.85 2.759l6.565 6.068c.424.394.599.972.474 1.55l-1.971 8.51c-.3 1.34 1.074 2.444 2.222 1.735l7.263-4.493a1.445 1.445 0 0 1 1.522 0l7.263 4.493c1.148.709 2.52-.368 2.221-1.734l-1.947-8.511a1.621 1.621 0 0 1 .475-1.55l6.564-6.068c.948-.92.399-2.627-.899-2.759ZM30.253 18.783l-8.71-.866c-.55-.053-1.024-.42-1.248-.972l-3.12-7.96a1.467 1.467 0 0 0-2.77 0l-3.095 7.96c-.2.551-.699.92-1.248.972l-8.71.866c-1.298.132-1.823 1.84-.85 2.759l6.565 6.068c.424.394.599.972.474 1.55L5.57 37.67c-.3 1.34 1.074 2.444 2.222 1.735l7.263-4.493a1.445 1.445 0 0 1 1.522 0l7.263 4.493c1.148.709 2.52-.368 2.221-1.734l-1.947-8.511a1.621 1.621 0 0 1 .475-1.55l6.564-6.068c.948-.92.399-2.627-.899-2.759Z"
      />
    </svg>
  </div>
</ng-template>
