import { Injectable } from '@angular/core';
import { BOXEmptyStateOptions, SearchStatusType } from '@box-types';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class HomeSearchResultsService {
  constructor(private languageService: LanguageService) {}

  public generateEmptyStateOptions(searchTerm: string): BOXEmptyStateOptions {
    return {
      iconURL: '/assets/images/empty-state/empty-state--search.svg',
      title: this.languageService.getTextByKey('no_results_found_for', { _SEARCH_TERM: searchTerm })
    };
  }

  public generateCTAText(status: SearchStatusType, hasResults: boolean): string {
    if (status === 'COMPLETED' && !hasResults) return this.languageService.getTextByKey('discover_shops');
    return this.languageService.getTextByKey('see_shops_dishes_ingredients');
  }
}
