import { Product, ProductInstance, Selection, Ingredient } from '@box-types';
import { cloneDeep, isNumber } from 'lodash-es';
import {
  disableSelections,
  enableAllSelections,
  getIngredientsEnvFee,
  getProductBaseChoice,
  getProductBaseEnvFee,
  getProductBasePrice,
  getProductIngredientsPrice,
  productHasCustomPrice,
  resetSelections
} from './product.utils';
import { applyPercentageDiscount } from '../core';

export {
  getProductInstancePrice,
  getProductInstanceAdditionalPrice,
  getProductFromInstance,
  generateProductInstanceSelections,
  getProductInstanceDescription,
  createProductInstance,
  deleteProductInstancesComments
};

function getProductInstancePrice(
  product: ProductInstance,
  percentageDiscount?: number,
  discountOnExtraIngredients?: boolean
): number {
  if (!product) return 0;

  /* the call in check-availability-products-offers
  returns the updated price of products
  with their selected options taken into consideration,
  if they have an integrator.customPrice (PizzaFan, MyCook)
  In that case we don't need to recalculate the price of the product,
  since BE has already done that job for us.
  The result of this calculation is in product.finalPrice
  The products within offers with a customPrice (fixed price offers),
  have products that do not contain the final price */
  const isPricePrecalculated = product.integrator?.customPrice && isNumber(product.finalPrice);
  if (isPricePrecalculated) return product.finalPrice;

  // contains original price without added ingredients or necessary selection difference
  const productOriginalPrice = product.basePrice ?? 0;
  const productBasePrice = getProductBaseChoice(product?.selections)?.changeInPrice || productOriginalPrice;
  const productBaseEnvFee = getProductBaseEnvFee(product);
  const productBasePriceWithDiscount =
    applyPercentageDiscount(productBasePrice - productBaseEnvFee, percentageDiscount) + productBaseEnvFee;
  const ingredientsPrice = getProductIngredientsPrice(product);
  const ingredientsEnvFee = getIngredientsEnvFee(product);
  const ingredientsPriceWithDiscount = discountOnExtraIngredients
    ? applyPercentageDiscount(ingredientsPrice - ingredientsEnvFee, percentageDiscount) + ingredientsEnvFee
    : ingredientsPrice;
  return productBasePriceWithDiscount + ingredientsPriceWithDiscount;
}

// only for fixedPrice offers
function getProductInstanceAdditionalPrice(
  product: ProductInstance,
  percentageDiscount?: number,
  discountOnExtraIngredients?: boolean
): number {
  if (!product) return 0;
  // product.basePrice is decorated in createProductInstance
  const productBasePrice = product.basePrice ?? 0;
  const productBaseEnvFee = getProductBaseEnvFee(product);
  const productBasePriceWithDiscount =
    applyPercentageDiscount(productBasePrice - productBaseEnvFee, percentageDiscount) + productBaseEnvFee;

  const productInstancePrice =
    getProductInstancePrice(product, percentageDiscount, discountOnExtraIngredients) - productBasePriceWithDiscount;
  return Math.max(0, productInstancePrice);
}

function getProductFromInstance(product: Product, instance: ProductInstance): Product {
  const newProduct = cloneDeep(product);
  delete newProduct.cartInstances;
  delete newProduct.cartQuantity;
  newProduct.quantity = instance.quantity;
  newProduct.comments = instance.comments;
  newProduct.selections = cloneDeep(instance.selections);
  newProduct.selections?.forEach((selection) => {
    if (selection.disabledChoices === true) return (selection.optionsSelected = []);
    selection.optionsSelected = selection.choices.filter((choice) => choice.checked);
  });
  return newProduct;
}

function generateProductInstanceSelections(selections: Selection[]): Selection[] {
  const tempSelections: Selection[] = cloneDeep(selections);
  resetSelections(tempSelections);
  enableAllSelections(tempSelections);
  if (!tempSelections?.length) return;
  tempSelections
    .filter((s) => !s.multipleSelection && Boolean(s.choices?.length))
    .forEach(
      (s) =>
        !s.disabledChoices &&
        s.choices.forEach((c) => c.checked && disableSelections(tempSelections, c.disableSelections))
    );

  return tempSelections;
}

function getProductInstanceDescription(selections: Selection[]): string {
  if (!selections?.length) return;
  const enabledSelectionsChoices: Ingredient[] = selections
    .filter((s) => !s.disabledChoices && Boolean(s.choices?.length))
    .flatMap((selection) => selection.choices);
  if (!enabledSelectionsChoices?.length) return;

  const choices = enabledSelectionsChoices.reduce((choices, choice) => {
    if (!choice.checked) return choices;
    choices.push(choice.title);
    return choices;
  }, [] as string[]);

  return choices.join(', ');
}

function createProductInstance(product: Product): ProductInstance {
  const instanceSelections = generateProductInstanceSelections(product.selections); // todo refactor
  const basePrice = getProductBasePrice(product);

  const productInstance = {
    instanceId: (Math.random() + 1).toString(36).substring(2),
    quantity: product.quantity || 1,
    comments: product.comments || '',
    selections: instanceSelections,
    description: getProductInstanceDescription(instanceSelections),
    basePrice,
    hasCustomPrice: productHasCustomPrice(product),
    drinkId: product.drinkId,
    integrator: product.integrator,
    maxItems: product.maxItems,
    freeChoices: product.freeChoices,
    promos: product.promos,
    envFee: product.envFee,
    // TODO remove on refactor
    beginPrice: product.beginPrice,
    finalPrice: product.finalPrice,
    minimumRequiredChoices: product.minimumRequiredChoices,
    chargingPolicy: product.chargingPolicy
  } as ProductInstance;

  const price = getProductInstancePrice(productInstance);
  const ingredientsPrice = price - basePrice;

  return { ...productInstance, price, ingredientsPrice };
}

/**
 * This function mutates the Product objects
 * @param {Product} product the product that we need to purge the instnace comments from
 * @returns {void}
 * */
function deleteProductInstancesComments(product: Product): void {
  if (!product.cartInstances?.length) return;
  product.cartInstances.forEach((instance) => delete instance.comments);
}
