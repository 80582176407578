import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { CheckoutTipService } from './checkout-tip.service';
import { CheckoutAmountChange } from '../checkout-amounts/checkout-amounts.types';
import { CheckoutStateService } from '@box-checkout/services';

@Component({
  selector: 'checkout-tip',
  templateUrl: './checkout-tip.component.html',
  styleUrls: ['./checkout-tip.component.scss'],
  providers: [CheckoutTipService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutTipComponent implements OnInit {
  public tip: number;
  public amounts: number[];
  public minimumAmount: number;
  public maximumAmount: number;
  public description: string;

  constructor(private checkoutTipService: CheckoutTipService, private checkoutStateService: CheckoutStateService) {}

  @HostBinding('class') public hostClass = 'checkout-tip';

  ngOnInit(): void {
    this.amounts = this.checkoutTipService.getAmounts();
    this.minimumAmount = this.checkoutTipService.getMinimumAmount();
    this.maximumAmount = this.checkoutTipService.getMaximumAmount();
    this.description = this.checkoutTipService.getDescription();
    this.tip = this.amounts[0];
  }

  public onAmountChange(event: CheckoutAmountChange): void {
    this.tip = event.value;
    this.checkoutStateService.setTip(event.value);
    this.checkoutTipService.triggerAnalyticsEvent(event.value, event.label);
  }
}
