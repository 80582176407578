import { Component, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { CartTwist } from '@box-core/animations';
import { LanguageService } from '@box-core/services/language.service';
import { formatPoints } from '@box/utils';

@Component({
  selector: 'cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss'],
  animations: [CartTwist],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartButtonComponent implements OnChanges {
  @Input() public quantity = 0;
  @Input() public price = 0;
  @Input() public offerAddition = false;
  @Input() public multiplierSum = 0;
  @Input() public pointsSum = 0;
  @Input() public disabled: boolean;
  @Input() public showBenefits = true;

  public cartTwistActive = false;
  public benefitsText: string;

  @HostBinding('class') public hostClass = 'cart-button';

  constructor(private languageService: LanguageService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quantity) {
      this.quantity = changes.quantity.currentValue as number;
    }

    if (changes.multiplierSum) {
      this.multiplierSum = changes.multiplierSum.currentValue as number;
      this.setBenefitsText();
    }

    if (changes.pointsSum) {
      this.pointsSum = changes.pointsSum.currentValue as number;
      this.setBenefitsText();
    }
  }

  public twist(): void {
    this.cartTwistActive = !this.cartTwistActive;
  }

  private setBenefitsText(): void {
    if (this.multiplierSum > 0) {
      this.benefitsText = this.languageService
        .getTextByKey('x_points')
        .replace('_POINTS', formatPoints(this.multiplierSum));
    } else if (this.pointsSum > 0) {
      this.benefitsText = this.languageService
        .getTextByKey('plus_points')
        .replace('_POINTS', formatPoints(this.pointsSum));
    } else {
      this.benefitsText = this.languageService.getTextByKey('x_points').replace('_POINTS', '1');
    }
  }
}
