import { Injectable } from '@angular/core';
import { ConfigurationService, translate } from '@box-core/services';
import {
  getFirstPersonalDataTermsHTML,
  getMarketFindCardTermsHTML,
  getSaveCardTermsText,
  getSecondPersonalDataTermsHTML
} from '@box/utils';
import { BoxDialogValidationMode, SMLoyaltyConfiguration } from '@box-types';
import { ValidatorFn } from '@angular/forms';
import { emailValidator } from '@box-shared/validators';
import { numbersOnlyValidator } from '@box-shared/validators/numbers-only.validator';

@Injectable()
export class MarketCardConnectDialogService {
  constructor(private configService: ConfigurationService) {}

  public getSMLoyaltyConfig(integrator: string): SMLoyaltyConfiguration {
    const smLoyaltyInfo = this.configService.getConfiguration()?.smLoyaltyInfo ?? [];
    return smLoyaltyInfo.find((info) => info.integrator === integrator);
  }

  public getCreateButtonText(mode: string): string {
    if (mode === 'normal') return 'create_new_card';
    if (mode === 'guest') return 'continue_without_card';
  }

  public getFindCardTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getMarketFindCardTermsHTML(loyaltyConfig, translate);
  }

  public getSaveCardTermsText(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getSaveCardTermsText(loyaltyConfig, translate);
  }

  public getFirstPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getFirstPersonalDataTermsHTML(loyaltyConfig, translate);
  }

  public getSecondPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getSecondPersonalDataTermsHTML(loyaltyConfig, translate);
  }

  public getInputValidator(mode: BoxDialogValidationMode): ValidatorFn {
    if (mode === 'EMAIL') return emailValidator();
    return numbersOnlyValidator();
  }
}
