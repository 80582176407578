import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { currencyFormat } from '@box/utils';
import { FormControl } from '@angular/forms';
import { numbersOnlyValidator } from '@box-shared/validators';
import { SwiperOptions } from 'swiper/types';
import { v4 as uuidv4 } from 'uuid';
import { CheckoutAmountChange, CheckoutAmountRadioButton } from './checkout-amounts.types';
import { currencyCode } from '@box-core/services/currency.service';

@Component({
  selector: 'checkout-amounts',
  templateUrl: './checkout-amounts.component.html',
  styleUrls: ['./checkout-amounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutAmountsComponent implements OnInit, OnChanges {
  @ViewChild('customAmountInput') private customAmountInput: ElementRef<HTMLInputElement>;
  @Input() public amounts: number[];
  @Input() private minimumAmount: number;
  @Input() private maximumAmount: number;
  @Output() private amountChange = new EventEmitter<CheckoutAmountChange>();

  public swiperOptions: SwiperOptions = { slidesPerView: 'auto', spaceBetween: 12, freeMode: true };
  public edittingCustomAmount: boolean;
  public selectedRadioButton: CheckoutAmountRadioButton;
  public radioButtons: CheckoutAmountRadioButton[];
  public customRadioButton: CheckoutAmountRadioButton;
  public customAmountFormControl: FormControl;
  /** `key` is used to generate the radio button ids. Without that, multiple instances of this
   * component will not work propery due to the way label works with the for attribute. */
  private key: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.key = uuidv4();
  }

  @HostBinding('class') public hostClass = 'checkout-amounts';

  ngOnInit(): void {
    this.customRadioButton = { id: `checkout-amount-${this.key}-input_custom`, value: null, label: 'other_' };
    this.customAmountFormControl = new FormControl(null, numbersOnlyValidator());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amounts) {
      this.amounts = changes.amounts.currentValue as number[];
      this.radioButtons = this.generateAmountRadioButtons(this.amounts);
      this.selectedRadioButton = this.radioButtons[0];
    }

    if (changes.minimumAmount) this.minimumAmount = changes.minimumAmount.currentValue as number;
    if (changes.maximumAmount) this.maximumAmount = changes.maximumAmount.currentValue as number;
  }

  public onRadioButtonChange(radioButton: CheckoutAmountRadioButton): void {
    this.selectedRadioButton = radioButton;
    if (radioButton.id === this.customRadioButton.id) {
      /* We do not emit the value change since we want the user to insert a custom value.
      After that the blur event callback `onCustomAmountSubmit` will handle the emition */
      this.activateEditCustomAmountMode();
    } else {
      this.resetCustomRadioButton();
      this.amountChange.emit({ value: this.selectedRadioButton.value, label: this.selectedRadioButton.label });
    }
  }

  public onCustomAmountClick(): void {
    /* We wabt to ignore the click event callback while the element is not selected already.
    The `onRadioButtonChange` will handle the first user input. This guarantees that the callback
    will be invoked only when the user has already submitted and selected a custom price. */
    const customAmountSelected = this.selectedRadioButton.id === this.customRadioButton.id;
    if (customAmountSelected && !this.edittingCustomAmount) this.activateEditCustomAmountMode();
  }

  public onCustomAmountSubmit(): void {
    const normalizedValue = this.normalizeCustomAmountValue(this.customAmountFormControl.value as number);
    const exists = this.amounts.includes(normalizedValue);

    if (exists) {
      this.resetCustomRadioButton();
      this.selectedRadioButton = this.radioButtons.find((amountInput) => amountInput.value === normalizedValue);
    } else {
      this.updateCustomRadioButton(normalizedValue);
      this.selectedRadioButton = this.customRadioButton;
      this.customAmountFormControl.setValue(normalizedValue / 100);
    }

    this.edittingCustomAmount = false;
    this.changeDetectorRef.detectChanges();
    this.amountChange.emit({ value: this.selectedRadioButton.value, label: this.selectedRadioButton.label });
  }

  public onCustomAmountCancel(): void {
    this.resetCustomRadioButton();
    this.customAmountInput.nativeElement.blur();
  }

  private generateAmountRadioButtons(amounts: number[]): CheckoutAmountRadioButton[] {
    return amounts.map((amount, index) => ({
      id: `checkout-amount-${this.key}-input_${index}`,
      value: amount,
      label: currencyFormat(amount, { symbolSpace: false, currencyCode: currencyCode })
    }));
  }

  private activateEditCustomAmountMode(): void {
    this.edittingCustomAmount = true;
    this.changeDetectorRef.detectChanges();
    this.customAmountInput.nativeElement.focus();
  }

  private updateCustomRadioButton(amount: number): void {
    this.customRadioButton.value = amount;
    this.customRadioButton.label = currencyFormat(amount, {
      symbolSpace: false,
      currencyCode: currencyCode
    });
  }

  private resetCustomRadioButton(): void {
    this.customRadioButton.value = null;
    this.customRadioButton.label = 'other_';
    this.customAmountFormControl.setValue(null);
  }

  private normalizeCustomAmountValue(value: number): number {
    if (!value) return 0;
    /* The Math.round is required here to avoid cases were we have issues with the
    double precision floating point numbers. ie 1.1 * 100 = 110.00000000000001 and
    19.99 * 100 = 1998.9999999999998 */
    const valueInCents = Math.round(value * 100);
    if (this.minimumAmount && valueInCents < this.minimumAmount) return this.minimumAmount;
    if (this.maximumAmount && valueInCents > this.maximumAmount) return this.maximumAmount;
    return valueInCents;
  }
}
