import { Product, Selection } from '@box-types';
import { cloneDeep } from 'lodash-es';

export { transferOptionsSelectedToChoices, getProductSelectedChoicesTitles };

function transferOptionsSelectedToChoices(product: Product): Selection[] {
  const { selections } = product;
  if (!selections?.length) return [];

  const clonedSelections = cloneDeep(product.selections);

  return clonedSelections.map((selection) => {
    if (!selection.choices?.length) return selection;

    const choices = selection.choices.map((choice) => {
      const selectedOption = selection.optionsSelected.find((option) => option._id === choice._id);
      return { ...choice, checked: Boolean(selectedOption) };
    });
    return { ...selection, choices, optionsSelected: [] };
  });
}

function getProductSelectedChoicesTitles(product: Product): string[] {
  const { selections } = product;
  if (!selections?.length) return [];
  return selections.flatMap((selection) => selection.optionsSelected.map((choice) => choice.title));
}
