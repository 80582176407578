import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  HostBinding,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ProductMYOState } from '@box-delivery/delivery.types';
import { BOXNavOptions } from '@box-types';

@Component({
  selector: 'product-myo-nav',
  templateUrl: './product-myo-nav.component.html',
  styleUrls: ['./product-myo-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYONavComponent implements OnChanges {
  @Input() public reviewsCounter: number;
  @Input() public loading: boolean;
  @Input() public description = 'informations_';
  @Output() private stateChange = new EventEmitter<ProductMYOState>();
  public navOptions: BOXNavOptions = { elevated: false };

  public state: ProductMYOState = 'MYO';

  @HostBinding('class') public hostClass = 'product-myo-nav';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reviewsCounter) this.reviewsCounter = changes.reviewsCounter.currentValue as number;
    if (changes.description) this.description = changes.description.currentValue as string;
  }

  public onStateChange(state: ProductMYOState): void {
    this.state = state;
    this.stateChange.emit(state);
  }
}
