import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '@box-core/services';
import { currencyFormat, CurrencyFormatOptions } from '@box/utils';

@Pipe({ name: 'currencyPipe' })
export class CurrencyPipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {}

  transform(cents: number, options?: CurrencyFormatOptions): string {
    const currencyCode = this.currencyService.getCurrencyCode();
    const decoratedOptions: CurrencyFormatOptions = {
      ...options,
      currencyCode,
      minimumFractionDigits: 2
    };
    return currencyFormat(cents, decoratedOptions);
  }
}
