import {
  Category,
  Product,
  ProductReplacementOption,
  Shop,
  MarketCard,
  MarketCardDetails,
  Offer,
  APIError,
  SMLoyaltyConfiguration,
  GetTextByKeyType
} from '@box-types';
import uniq from 'lodash-es/uniq';
import { productReplacementOptions } from '@box-configs/product-replacement.options';
import { sortCategories } from './shops/';

export {
  generateMarketCard,
  generateOffersSubCategories,
  getProductReplacementOptions,
  getMarketFindCardTermsHTML,
  getSaveCardTermsText,
  getFirstPersonalDataTermsHTML,
  getSecondPersonalDataTermsHTML,
  getLoyaltyCardForIntegrator,
  isCardExpirationErrorCode,
  hasLocalKritikosCard,
  isEfreshTokenInvalid,
  supermarketHasOffers
};

function isCardExpirationErrorCode(error: APIError): boolean {
  if (!error?.code) return false;
  return ['SMVD050', 'SMVD009'].includes(error.code);
}

function isEfreshTokenInvalid(error: APIError): boolean {
  if (!error?.code) return false;
  return error.code === 'E_FRESH_INVALID_TOKEN';
}

function generateMarketCard(details: MarketCardDetails, loyaltyInfo: SMLoyaltyConfiguration): MarketCard {
  return {
    cardId: details.cardId,
    name: loyaltyInfo.shortCardName,
    supermarket: details.name,
    shopName: loyaltyInfo.smName,
    frontImage: loyaltyInfo.bigCardPlaceholder,
    balance: details.points,
    barcodeFormat: loyaltyInfo.barcodeGenerator
  };
}

function supermarketHasOffers(products: Product[], offers: Offer[]): boolean {
  const hasOffers = Boolean(offers?.length);
  const hasProductOffers = Boolean(products?.filter((p) => p.isOffer)?.length);
  return hasOffers || hasProductOffers;
}

function generateOffersSubCategories(
  products: Product[],
  offers: Offer[],
  categories: Category[],
  shop: Shop
): Category[] {
  const hasOffers = offers?.length > 0;
  const offerProducts = products?.filter((p) => p.isOffer);
  const hasOfferProducts = offerProducts?.length > 0;
  const subCategories: Category[] = [];
  if (hasOffers) subCategories.push({ name: 'its_worth_it_for_you', _id: 'offers_category' });
  if (!hasOfferProducts) return subCategories;
  const productOfferCategories: Category[] = [];
  if (shop?.integrator?.company === 'masoutis' || !shop?.isSuperMarket) {
    // we build the offer sub categories straight from the information that is attached on the products
    // for masoutis stores. We use the product.category property and not the product.category.parent which is the
    // product's supercategory. We don't depend on the getItems/preview categories properties
    const uniqCategoryIDs = uniq(offerProducts?.flatMap((product) => product?.category?._id));
    const productCategories = offerProducts.flatMap((product) => product.category);
    const uniqCategories = uniqCategoryIDs.map((id) => productCategories.find((category) => category._id === id));
    productOfferCategories.push(...uniqCategories);
  } else {
    const uniqCategoryIDs = uniq(offerProducts?.map((product) => product?.category?.parent));
    const uniqCategories = (categories ?? [])?.filter((category) => uniqCategoryIDs?.includes(category._id));
    productOfferCategories.push(...uniqCategories);
  }

  const sortedProductOfferCategories = sortCategories(productOfferCategories);
  if (sortedProductOfferCategories?.length > 0) subCategories.push(...sortedProductOfferCategories);
  return subCategories;
}

function getProductReplacementOptions(shop: Shop): ProductReplacementOption[] {
  const options: ProductReplacementOption[] = [];
  const { phoneProductReplacement, smartProductReplacement, noProductReplacement } = shop;
  if (phoneProductReplacement) options.push(productReplacementOptions.phone);
  if (smartProductReplacement) options.push(productReplacementOptions.smart);
  if (noProductReplacement) options.push(productReplacementOptions.none);
  return options;
}

/* Loyalty Cards Terms */

function getMarketFindCardTermsHTML(loyaltyConfig: SMLoyaltyConfiguration, translateFn: GetTextByKeyType): string {
  if (!loyaltyConfig?.findCardTermsUrl) return undefined;
  const termsAnchor = translateFn('terms_of_use_anchor', {
    _TERMS_URL: loyaltyConfig.findCardTermsUrl
  });
  return translateFn('i_agree_with_terms_of_use_html_template', {
    _TERMS_ANCHOR: termsAnchor,
    _SHORT_CARD_NAME: loyaltyConfig.shortCardName,
    _SM_NAME: loyaltyConfig.smName
  });
}

function getSaveCardTermsText(loyaltyConfig: SMLoyaltyConfiguration, translateFn: GetTextByKeyType): string {
  if (!loyaltyConfig?.canSave) return undefined;
  return translateFn('i_wish_to_keep_the_privilege_card_saved', {
    _SHORT_CARD_NAME: loyaltyConfig.shortCardName
  });
}

function getFirstPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration, translateFn: GetTextByKeyType): string {
  const termsAnchor = translateFn('terms_of_use_anchor', {
    _TERMS_URL: loyaltyConfig.loyaltyTerms
  });

  if (!loyaltyConfig?.personalDataTermsUrl) {
    return translateFn('i_agree_to_sent_my_details_to_supermarkets', {
      _TERMS_ANCHOR: termsAnchor,
      _SM_NAME: loyaltyConfig.smName
    });
  }
  const dataTermsAnchor = translateFn('processing_of_personal_data_anchor', {
    _TERMS_URL: loyaltyConfig.personalDataTermsUrl
  });
  return translateFn('i_have_been_informed_about_processing_of_my_personal_data', {
    _DATA_TERMS_ANCHOR: dataTermsAnchor,
    _SHORT_CARD_NAME: loyaltyConfig.shortCardName,
    _TERMS_ANCHOR: termsAnchor
  });
}

function getSecondPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration, translateFn: GetTextByKeyType): string {
  if (!loyaltyConfig?.personalDataTermsUrl) return undefined;
  return translateFn('i_wish_to_provide_and_transmit_my_data_to_supermarkets', {
    _SM_NAME: loyaltyConfig.smName
  });
}

function getLoyaltyCardForIntegrator(loyaltyCards: MarketCardDetails[], company: string): MarketCardDetails {
  if (!loyaltyCards?.length || !company) return;
  return loyaltyCards.find((card) => card.name === company);
}

function hasLocalKritikosCard(): boolean {
  const localKritikosGuestCard = window.localStorage.getItem('Box:kritikosGuestCard');
  return !!localKritikosGuestCard;
}
