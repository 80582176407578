import { appConfig } from '@box-configs/app.config';
import { environment } from '@box-env/environment';
import { GetTextByKeyType } from '@box-types';

export {
  generateTitleContent,
  generateDescriptionContent,
  generateKeywordsContent,
  generateImageContent,
  generateUrlContent
};

function generateTitleContent(title?: string): string {
  const appName = appConfig.name;
  const givenTitle = title ?? 'Online Delivery';
  return `${givenTitle} | ${appName}`;
}

function generateDescriptionContent(description: string, translateFn: GetTextByKeyType): string {
  return translateFn(description) ?? translateFn('seo_default_description');
}

function generateKeywordsContent(keywords: string, translateFn: GetTextByKeyType): string {
  return translateFn(keywords) ?? translateFn('seo_default_keywords');
}

function generateImageContent(url?: string): string {
  const ROOT_URL = environment.application.ROOT_URL;
  return url ?? `${ROOT_URL}/assets/images/box-share-image.png`;
}

function generateUrlContent(routerUrl?: string): string {
  const ROOT_URL = environment.application.ROOT_URL;
  return routerUrl ? `${ROOT_URL}${routerUrl}` : ROOT_URL;
}
