import { Component, OnInit } from '@angular/core';
import { NavItem } from '@box-types';
import { profileNavItems } from '@box-configs/nav-items.options';
import { CosmoteIDService, UserService } from '@box-core/services';
import { Router } from '@angular/router';
import { filterValidNavItemsForUser } from '@box/utils';

@Component({
  selector: 'box-navigation-menu',
  templateUrl: './box-navigation-menu.component.html',
  styleUrls: ['./box-navigation-menu.component.scss']
})
export class BoxNavigationMenuComponent implements OnInit {
  public menuItems: NavItem[];

  constructor(private userService: UserService, private cosmoteIDService: CosmoteIDService, private router: Router) {}

  ngOnInit(): void {
    const isGuest = this.userService.isGuest;
    this.menuItems = filterValidNavItemsForUser(profileNavItems, isGuest);
  }

  public onClick(menuItem: NavItem): void {
    if (menuItem?.clickAction) {
      this.cosmoteIDService.redirectToCosmoteId();
      return;
    }
    if (menuItem?.routerLink) void this.router.navigate([menuItem.routerLink]);
  }
}
