<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="delete_confirmation" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="verification-dialog-content">
      <div class="verification-dialog-image-container">
        <figure class="verification-dialog-image">
          <img src="/assets/images/logo/logo.svg" alt="BOX" />
        </figure>
      </div>

      <p class="verification-dialog-description">
        {{
          mobilePhone?.length
            ? t('phone_verification_dialog_description_1', { _FORMATTED_PHONE: mobilePhone })
            : t('we_have_sent_you_a_code_by_email_to', { _EMAIL: email })
        }}
      </p>

      <mat-form-field class="verification-dialog-field">
        <mat-label>{{ 'verification_code' | translate }}</mat-label>
        <input
          matInput
          autocomplete="off"
          name="verificationCode"
          (keydown.enter)="verifyOtp()"
          [formControl]="verificationControl"
        />
        <mat-error>{{ 'verification_code_error_text' | translate }}</mat-error>
      </mat-form-field>
      <button
        box-button
        theme="primary"
        class="verification-dialog-button"
        [disabled]="verificationControl.invalid"
        (click)="verifyOtp()"
      >
        {{ 'next_' | translate }}
      </button>

      <footer class="verification-dialog-footer">
        <verification-resend-code
          #verificationResendCodeComponent
          [otpMethod]="{ msisdn: mobilePhone, email: this.email }"
          (send)="generateOtp()"
        ></verification-resend-code>
      </footer>
    </div>
  </main>
</div>
