<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      class="myo-dialog-header"
      title="add_products_and_earn"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content checkout-suggestion-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-suggestion-dialog-groups">
      <div *ngFor="let group of checkoutSuggestionGroups" class="checkout-suggestion-dialog-group">
        <suggestion-dialog-banner
          [suggestionDialogBanner]="group"
          class="checkout-suggestion-dialog-group-header"
        ></suggestion-dialog-banner>
        <food-product
          *ngFor="let product of group.products"
          subtraction="true"
          [product]="product"
          (add)="onProductAdd(group, $event)"
          (remove)="onProductRemove(group, $event)"
        ></food-product>
      </div>
    </div>
  </main>
  <div class="checkout-suggestion-dialog-cta">
    <button box-button theme="primary" (click)="onAddToCart()">{{ 'add_to_cart' | translate }}</button>
  </div>
</div>
