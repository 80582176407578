import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrivacyConsentService, DialogService } from '@box-core/services';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PrivacyConsent } from '@box-types';
import dayjs from 'dayjs';

const ACCEPTED_DIALOG_MESSAGE = 'acceptance_of_cookies_was_saved';
const REJECTED_DIALOG_MESSAGE = 'the_cookies_rejection_was_saved';
const DEFAULT_DIALOG_MESSAGE = 'your_changes_have_been_saved';

@Component({
  selector: 'cookies-toggles',
  templateUrl: './cookies-toggles.component.html',
  styleUrls: ['./cookies-toggles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesTogglesComponent implements OnDestroy {
  public mandatoryAccepted = true;
  public performanceAccepted: boolean;
  public adAccepted: boolean;
  public uxAccepted: boolean;
  private privacySubscription: Subscription;

  constructor(private privacyConsentService: PrivacyConsentService, private dialogService: DialogService) {
    this.privacySubscription = this.privacyConsentService.privacyConsent$.subscribe((consent) =>
      this.onChangePrivacyConsent(consent)
    );
  }

  ngOnDestroy(): void {
    if (this.privacySubscription) this.privacySubscription.unsubscribe();
  }

  public onAcceptAll(): void {
    this.performanceAccepted = true;
    this.adAccepted = true;
    this.uxAccepted = true;
    this.saveConsent();
    this.showSaveDialog(ACCEPTED_DIALOG_MESSAGE);
  }

  public onRejectAll(): void {
    this.performanceAccepted = false;
    this.adAccepted = false;
    this.uxAccepted = false;
    this.saveConsent();
    this.showSaveDialog(REJECTED_DIALOG_MESSAGE);
  }

  public onSave(): void {
    this.saveConsent();
    this.showSaveDialog();
  }

  public onChangeConsent(type: string, { checked }: MatSlideToggleChange): void {
    switch (type) {
      case 'performance':
        this.performanceAccepted = checked;
        return;
      case 'ad':
        this.adAccepted = checked;
        return;
      case 'ux':
        this.uxAccepted = checked;
        return;
    }
  }

  private onChangePrivacyConsent(privacyConsent: PrivacyConsent) {
    this.performanceAccepted = privacyConsent.performance;
    this.adAccepted = privacyConsent.ad;
    this.uxAccepted = privacyConsent.ux;
  }

  private saveConsent(): void {
    this.privacyConsentService.setPrivacyConsent({
      performance: this.performanceAccepted,
      ux: this.uxAccepted,
      ad: this.adAccepted,
      ts: dayjs().valueOf()
    });
    if (!this.uxAccepted) this.privacyConsentService.removeUXData();
  }

  private showSaveDialog(message?: string): void {
    this.dialogService.openInfoDialog({
      title: 'cookies_settings',
      messages: [message || DEFAULT_DIALOG_MESSAGE]
    });
  }
}
