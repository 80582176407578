<mat-expansion-panel #productReplacementPanel class="checkout-expansion-panel" (click)="onEdit()">
  <mat-expansion-panel-header collapsedHeight="4rem" expandedHeight="4rem">
    <div class="checkout-product-replacement-wrapper">
      <div class="checkout-product-replacement-header">
        <h4 class="checkout-product-replacement-title">{{ 'change_unavailable_product_with' | translate }}</h4>
      </div>
      <div class="checkout-product-replacement-option">
        <span class="checkout-product-replacement-option-label">{{ selectedOption.label | translate }}</span>
      </div>
    </div>
  </mat-expansion-panel-header>
</mat-expansion-panel>
