import { Injectable } from '@angular/core';
import { ConfirmDialogResponse, Shop, Timeslot } from '@box-types';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TimeslotDialogComponent } from '@box-shared/components';
import dayjs from 'dayjs';
import { DialogService } from '@box-core/services/dialog.service';
import { Router } from '@angular/router';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import { TimeslotDialogResponse } from '@box-shared/components/timeslot-dialog/timeslot-dialog-response.interface';
import { LanguageService } from './language.service';

@Injectable({ providedIn: 'root' })
export class TimeslotsDialogsService {
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private timeslotsService: TimeslotsService,
    private languageService: LanguageService
  ) {}

  public openTimeslotsDialog(shop: Shop, nextAvailableSlot?: Timeslot): MatDialogRef<TimeslotDialogComponent> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog',
      data: {
        nextAvailableTimeslot: nextAvailableSlot,
        isSuperMarket: shop?.isSuperMarket
      }
    };

    const dialogRef = this.dialogService.openDialog(TimeslotDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data: TimeslotDialogResponse) => {
      if (!data) return;
      if (data.reset) this.timeslotsService.clearTimeslot();
      if (data.timeslot) this.timeslotsService.setTimeslot(data.timeslot);
    });

    return dialogRef;
  }

  public showCheckoutNoTimeslotSupportDialog(shop: Shop): void {
    this.dialogService
      .openConfirmDialog({
        title: 'no_delivery_times_available',
        messages: ['no_delivery_times_available_from_this_store_at_the_moment'],
        confirmText: 'back_to_products',
        cancelText: 'see_other_shops',
        buttonOrientation: 'vertical'
      })
      .afterClosed()
      .subscribe((data: { accepted: boolean }) => {
        if (!data) return undefined;
        if (data.accepted === false) return void this.router.navigate(['/discover']);
        const { locationKey, vanity_url } = shop;
        if (data.accepted === true) return void this.router.navigate(['/delivery', locationKey, vanity_url]);
      });
  }

  public showCheckoutTimeslotUnavailabilityDialog(shop: Shop): void {
    this.dialogService
      .openConfirmDialog({
        messages: ['selected_time_for_scheduling_an_order_is_unavailable', 'you_can_choose_another_delivery_time'],
        confirmText: 'confirm_',
        cancelText: 'time_selection'
      })
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (!data?.accepted) {
          return this.openTimeslotsDialog(shop);
        }
      });
  }

  public showTimeslotsUnavailableDialog(): void {
    this.dialogService
      .openInfoDialog({
        title: 'the_shop_cannot_serve_you',
        messages: ['the_shop_cannot_serve_you_due_to_unavailable_time'],
        btnText: 'see_other_shops'
      })
      .afterClosed()
      .subscribe(() => void this.router.navigate(['/discover']));
  }

  public showNoTimeslotSupportDialog(): void {
    this.dialogService
      .openInfoDialog({
        title: 'the_shop_cannot_serve_you',
        messages: ['the_shop_does_not_serve_scheduled_orders'],
        btnText: 'see_other_shops'
      })
      .afterClosed()
      .subscribe(() => void this.router.navigate(['/discover']));
  }

  public showTimeslotIncludedDialog(shop: Shop, availableTimeslot: Timeslot): void {
    const timeslotDateString = dayjs.unix(availableTimeslot.timeSlotStart).format(`dddd, `);
    const translatedText = this.languageService.getTextByKey('at_');
    const timeslotStartString = dayjs.unix(availableTimeslot.timeSlotStart).format(` HH:mm - `);
    const timeslotEndString = dayjs.unix(availableTimeslot.timeSlotEnd).format('HH:mm');
    const dateString = timeslotDateString + translatedText + timeslotStartString + timeslotEndString;
    const dateStringTranslated = this.languageService.getTextByKey('for_the_date').replace('_DATE', dateString);
    this.dialogService
      .openConfirmDialog({
        title: 'changing_the_time_of_a_scheduled_order',
        messages: ['the_shop_can_serve_scheduled_order', dateStringTranslated],
        confirmText: 'continue_to_shop',
        cancelText: 'see_other_shops',
        buttonOrientation: 'vertical'
      })
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (data?.accepted) {
          return this.timeslotsService.setTimeslot(availableTimeslot);
        }
        void this.router.navigate(['/discover']);
      });
  }

  public showFutureTimeslotAvailableDialog(shop: Shop, availableTimeslot: Timeslot): void {
    const timeslotStartString = dayjs
      .unix(availableTimeslot.timeSlotStart)
      .format(`dddd, ${this.languageService.getTextByKey('at_')} HH:mm - `);
    const timeslotEndString = dayjs.unix(availableTimeslot.timeSlotEnd).format('HH:mm');
    const dateString = timeslotStartString + timeslotEndString;
    const dateStringTranslated = this.languageService.getTextByKey('for_the_date').replace('_DATE', dateString);
    this.dialogService
      .openConfirmDialog({
        title: 'unavailable_delivery_time',
        messages: ['the_delivery_time_you_have_selected_is_unavailable', dateStringTranslated],
        confirmText: 'continue_to_shop',
        cancelText: 'see_other_shops',
        buttonOrientation: 'vertical'
      })
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (data?.accepted) {
          return this.timeslotsService.setTimeslot(availableTimeslot);
        }
        void this.router.navigate(['/discover']);
      });
  }
}
