import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@box-env/environment';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Configuration, APIResponse, ConfigurationToBeHydrated } from '@box-types';
import { get } from 'lodash-es';
import { map } from 'rxjs/operators';

dayjs.extend(isBetween);

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private readonly CONFIGURATION_URL = environment.application.CONFIGURATION_URL;
  private readonly configurationSource = new BehaviorSubject<Configuration | ConfigurationToBeHydrated>(undefined);

  constructor(private http: HttpClient) {}

  public fetchConfiguration(): Observable<ConfigurationToBeHydrated> {
    // Cache-Control: no-cache, no-store, must-revalidate, post-check=0, pre-check=0
    // Expires: 0
    return this.http
      .get(this.CONFIGURATION_URL)
      .pipe(map((response: APIResponse<ConfigurationToBeHydrated>) => response.payload));
  }

  public get<T>(path: string, defaultValue?: T): T {
    return get(this.getConfiguration(), path, defaultValue);
  }

  public setConfiguration(configuration: Configuration | ConfigurationToBeHydrated): void {
    this.configurationSource.next(configuration);
  }

  public getConfiguration(): Configuration {
    return this.configurationSource.getValue() as Configuration;
  }

  public isUnderMaintenance(): boolean {
    const configuration = this.configurationSource.getValue();
    if (!configuration?.downtime_Web) return false;
    const currentDate = dayjs();
    const fromDate = dayjs.unix(configuration.downtime_Web.fromDate);
    const toDate = dayjs.unix(configuration.downtime_Web.toDate);
    return currentDate.isBetween(fromDate, toDate);
  }
}
