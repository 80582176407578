<mat-expansion-panel class="checkout-donation-expansion-panel">
  <mat-expansion-panel-header collapsedHeight="2.5rem" expandedHeight="2rem">
    <mat-panel-title>
      <div class="checkout-donation-header">
        <span class="ods-typography-body-s-bold">{{ title }}</span>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <p class="checkout-donation-description ods-typography-body-s-regular">{{ description | translate }}</p>
  <checkout-amounts
    [amounts]="amounts"
    [minimumAmount]="minimumAmount"
    [maximumAmount]="maximumAmount"
    (amountChange)="onAmountChange($event)"
  ></checkout-amounts>
</mat-expansion-panel>
