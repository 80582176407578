import { User, RewardsHistoryEarning, CurrencyCode } from '@box-types';
import { CurrencyFormatOptions, currencyFormat } from '../core';

function showHistoryEarnings(user: User): boolean {
  const earnedRewardsInfo = user.earnedRewardsInfo;
  if (!earnedRewardsInfo) return false;
  const { earnedGB, earnedGiftcards, earnedEuros } = earnedRewardsInfo;
  return Boolean(earnedGB) || Boolean(earnedGiftcards) || Boolean(earnedEuros);
}

function getHistoryEarningsEuro(user: User, currencyCode: CurrencyCode): RewardsHistoryEarning {
  const earnedEuros = user.earnedRewardsInfo?.earnedEuros;
  if (!earnedEuros) return;
  const formatOptions: CurrencyFormatOptions = { currencyCode, symbolSpace: true, minimumFractionDigits: 2 };
  const title = currencyFormat(earnedEuros * 100, formatOptions);
  return { title, description: 'from_points_and_coupons' };
}

function getHistoryEarningsGB(user: User): RewardsHistoryEarning {
  const earnedGB = user.earnedRewardsInfo?.earnedGB;
  if (!earnedGB) return;
  return { title: `${earnedGB} GB`, description: 'for_mobile_usage' };
}

function getGBColorSchemeFamily(user: User): string {
  const userSegment = user?.mainCosmoteAssetSegment;
  if (userSegment === 'whatsup') return 'whats-up';
  if (userSegment === 'whatsupstudent') return 'whats-up';
  return 'cosmote';
}

export { showHistoryEarnings, getHistoryEarningsEuro, getHistoryEarningsGB, getGBColorSchemeFamily };
