<div
  class="shop-menu-nav-item-wrapper"
  [class.expanded]="expanded"
  [class.active]="item.active"
  [class.has-sub-items]="hasSubItems"
>
  <div class="shop-menu-nav-item-header">
    <span class="shop-menu-nav-item-header-text" (click)="onSelectItem($event)">{{ item.name | translate }}</span>
    <div *ngIf="!disableToggle" class="shop-menu-nav-item-header-chevron-wrapper">
      <button
        *ngIf="hasSubItems"
        mat-icon-button
        class="shop-menu-nav-item-header-chevron"
        (click)="onChevronClick($event)"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
  <ul *ngIf="hasSubItems && expanded" class="shop-menu-nav-item-list">
    <li
      *ngFor="let subItem of item.subItems"
      class="shop-menu-nav-item-list-item"
      [class.active]="subItem.active"
      (click)="onSelectItem($event, subItem)"
    >
      {{ subItem.name }}
    </li>
  </ul>
</div>
