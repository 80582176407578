<footer class="section">
  <div class="container">
    <div class="footer-blocks">
      <div class="footer-block contact">
        <h4 class="footer-block-title">{{ 'communication_' | translate }}</h4>
        <div class="footer-block-content">
          <a [attr.href]="'tel:' + supportPhoneNumber" class="footer-block-content-text ods-typography-body-s-regular">
            <mat-icon>phone</mat-icon>
            {{ supportPhoneNumber }}</a
          >
          <a [attr.href]="'mailto:' + supportEmail" class="footer-block-content-text ods-typography-body-s-regular">
            <mat-icon>email</mat-icon>
            {{ supportEmail }}</a
          >
          <span class="footer-block-content-text ods-typography-body-s-regular">
            {{ 'our_team_is_here_to_help' | translate }}
          </span>
        </div>
      </div>

      <div class="footer-block useful">
        <h4 class="footer-block-title">{{ 'useful_' | translate }}</h4>
        <div class="footer-block-content">
          <a
            *ngFor="let link of usefulLinks"
            [routerLink]="link.routerLink"
            class="footer-block-content-text ods-typography-body-s-regular"
            >{{ link.title | translate }}</a
          >
          <a
            [attr.href]="zohoUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="footer-block-content-text ods-typography-body-s-regular"
            >{{ 'have_shops' | translate }}</a
          >
        </div>
      </div>

      <div class="footer-block follow">
        <h4 class="footer-block-title">{{ 'follow_on_social' | translate }}</h4>

        <div class="box-social">
          <a [attr.href]="facebookUrl" class="box-social-link" target="_blank" rel="noopener noreferrer">
            <img loading="lazy" src="/assets/images/social/facebook.svg" alt="facebook-icon" />
          </a>
          <a [attr.href]="instagramUrl" class="box-social-link" target="_blank" rel="noopener noreferrer">
            <img loading="lazy" src="/assets/images/social/instagram.svg" alt="instagram-icon" />
          </a>
          <a [attr.href]="youtubeUrl" class="box-social-link" target="_blank" rel="noopener noreferrer">
            <img loading="lazy" src="/assets/images/social/youtube.svg" alt="youtube-icon" />
          </a>
        </div>
        <div class="footer-block-content">
          <span class="footer-block-content-text ods-typography-body-s-regular">{{
            'organism_title' | translate
          }}</span>
          <span class="footer-block-content-text ods-typography-body-s-regular">{{
            'address_khfisias' | translate
          }}</span>
          <span class="footer-block-content-text ods-typography-body-s-regular"
            >{{ 'afm_' | translate }}: 094019245</span
          >
          <span class="footer-block-content-text ods-typography-body-s-regular"
            >{{ 'gemh_' | translate }}: 001037501000</span
          >
        </div>
      </div>

      <div class="footer-block play-store">
        <h4 class="footer-block-title apps-footer-block">{{ 'take_box_with_you' | translate }}</h4>
        <div class="footer-block-content play-store">
          <box-app-links></box-app-links>
        </div>
      </div>
    </div>
  </div>
</footer>
