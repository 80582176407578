<div boxNav [options]="navOptions">
  <div
    boxNavItem
    [title]="description | translate"
    [class.box-nav-item-active]="state === 'MYO'"
    (click)="onStateChange('MYO')"
  >
    <span class="ods-typography-body-s-bold">{{ description | translate }}</span>
  </div>

  <div
    boxNavItem
    [title]="'reviews_' | translate"
    [class.box-nav-item-active]="state === 'REVIEWS'"
    (click)="onStateChange('REVIEWS')"
  >
    <div *ngIf="loading; else reviewsNavTextLoaded" class="product-myo-nav-text-skeleton"></div>
    <ng-template #reviewsNavTextLoaded>
      <span class="ods-typography-body-s-bold">
        {{ 'reviews_' | translate }} <span>({{ reviewsCounter > 0 ? reviewsCounter : '-' }})</span>
      </span>
    </ng-template>
  </div>
</div>
