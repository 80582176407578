<div class="checkout-market-points-wrapper">
  <div class="checkout-market-points-image-container" [ngSwitch]="state">
    <ng-container *ngSwitchCase="'disconnected'">
      <figure *ngIf="cardImageURL" class="checkout-market-points-image">
        <img [src]="cardImageURL" [alt]="cardName" />
      </figure>
    </ng-container>
    <ng-container *ngSwitchCase="'connected'">
      <svg class="checkout-market-points-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 64">
        <g fill-rule="evenodd">
          <path d="M0 0h76L64 64H0z" />
          <path opacity=".597" d="M0 64h76L64 0H0z" />
        </g>
      </svg>
      <span class="checkout-market-points-image-content">
        <span class="checkout-market-points-image-text">{{ 'you_have' | translate }}</span>
        <span class="checkout-market-points-image-points">{{ totalPoints | number: '1.0-0' }}</span>
        <span class="checkout-market-points-image-text">{{ 'the_points' | translate }}</span>
        <span class="checkout-market-points-image-text">{{ cardName }}</span>
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'active'">
      <svg
        data-mode="light"
        class="checkout-market-points-bg-active"
        width="76"
        height="64"
        viewBox="0 0 76 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          class="bottom-layer"
          d="M0 4C0 1.79086 1.79086 0 4 0H61L76 64H4C1.79086 64 0 62.2091 0 60V4Z"
        />
        <path class="top-layer" d="M0 4C0 1.79086 1.79086 0 4 0H76L61 64H4C1.79086 64 0 62.2091 0 60V4Z" />
      </svg>
      <figure class="checkout-market-points-image">
        <img [src]="shopImageURL" [alt]="shopName" />
      </figure>
    </ng-container>
  </div>
  <div class="checkout-market-points-text">{{ bannerText | translate }}</div>
  <div class="checkout-market-points-action" [ngSwitch]="state">
    <ng-container *ngSwitchCase="'disconnected'">
      <div class="checkout-add-card-action">
        <button mat-icon-button class="checkout-add-card-btn" (click)="onCardConnect()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="checkout-market-points-slide-container">
        <mat-slide-toggle
          [disabled]="disabled"
          color="primary"
          class="checkout-market-points-slide"
          [(ngModel)]="pointsChecked"
          (change)="onModeChange($event)"
        ></mat-slide-toggle>
      </div>
    </ng-container>
  </div>
</div>
