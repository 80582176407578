import { Component, Inject, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { APIError } from '@box-types';
import { BoxTermsDialogService } from '@box-shared/components/box-terms-dialog/box-terms-dialog.service';
import { SentryService } from '@box-core/services';
import { environment } from '@box-env/environment';
import { TermsDialogData } from '@box-shared/components/box-terms-dialog/box-terms-dialog.types';

@Component({
  selector: 'box-terms-dialog',
  templateUrl: './box-terms-dialog.component.html',
  styleUrls: ['./box-terms-dialog.component.scss'],
  providers: [BoxTermsDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxTermsDialogComponent implements OnInit {
  public termsStylesUrl = environment.application.legalDocuments.STYLES_URL;
  public title: string;
  public loading: boolean;
  public termsUrl: string;
  public termsHtml: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TermsDialogData,
    private dialogRef: MatDialogRef<BoxTermsDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private termsDialogService: BoxTermsDialogService,
    private sentryService: SentryService
  ) {}

  ngOnInit(): void {
    this.setDialogData(this.data);
    this.fetchTerms();
  }

  private fetchTerms(): void {
    if (!this.termsUrl) return;
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.termsDialogService
      .fetchTerms(this.termsUrl)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (terms) => {
          this.termsHtml = terms;
        },
        error: (error: APIError) =>
          this.sentryService.captureException(error, {
            domain: 'Contest Terms',
            domainDetails: 'Fetch Terms And Conditions Document',
            severity: 'error'
          })
      });
  }

  public closeDialog(data?: TermsDialogData): void {
    this.dialogRef.close(data);
  }

  private setDialogData(data: TermsDialogData): void {
    this.title = data.title ?? 'terms_of_use';
    this.termsUrl = data.termsUrl;
  }
}
