import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from '@box-core/services/language.service';

@Injectable({ providedIn: 'root' })
export class LanguageResolver implements Resolve<boolean> {
  constructor(private languagePreferenceService: LanguageService) {}
  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    this.languagePreferenceService.initializeLanguagePreference(activatedRouteSnapshot);
    this.languagePreferenceService.initializeLocalClientTranslations();
    return this.languagePreferenceService.initializeConfiguration$();
  }
}
