import { Injectable } from '@angular/core';
import { OrdersService } from '@box-core/services';
import { Order } from '@box-types';
import { getOrderDeliveryStatus } from '@box/utils';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable()
export class OrderCompletionRatingsService {
  private readonly ordersSource = new BehaviorSubject<Order[]>([]);
  public readonly orders$ = this.ordersSource.asObservable();

  constructor(private ordersService: OrdersService) {}

  public fetchOrders(): Observable<Order[]> {
    return this.ordersService
      .getOrderHistory$()
      .pipe(map((orders) => orders.filter((order) => !order.rating && getOrderDeliveryStatus(order) === 'COMPLETED')));
  }

  public getOrders(): Order[] {
    return this.ordersSource.getValue();
  }

  public setOrders(orders: Order[]): void {
    this.ordersSource.next(orders);
  }

  public removeOrder(order: Order): void {
    const currentOrders = this.ordersSource.getValue();
    this.ordersSource.next(currentOrders.filter((currentOrder) => currentOrder.friendlyId !== order.friendlyId));
  }

  public getExpandButtonText(expanded: boolean): string {
    if (expanded) return 'less_';
    return 'more_';
  }

  public updateOrderHistoryAfterRate(order: Order, rating: number): void {
    this.ordersService.updateOrderHistoryAfterRate(order, rating);
  }
}
