import { Injectable } from '@angular/core';
import { DeliveryMethodService, ShopService, TimeslotsService } from '@box-core/services';
import { DaasAvailabilityService } from '@box-core/services/daas-availability.service';
import { LanguageService } from '@box-core/services/language.service';
import { Shop, Timeslot, DaasAvailability, DeliveryMethod } from '@box-types';
import { getShopMinimumOrderPriceText, getShopDeliveryFeeText, getShopEstimationTimeText } from '@box/utils';
import { capitalize } from 'lodash-es';
import { combineLatest, map, Observable } from 'rxjs';
import { ShopPageDeliveryMethodBlock } from './shop-page-delivery-method.types';
import { currencyCode } from '@box-core/services/currency.service';

@Injectable()
export class ShopPageDeliveryMethodService {
  constructor(
    private shopService: ShopService,
    private timeslotsService: TimeslotsService,
    private deliveryMethodService: DeliveryMethodService,
    private daasAvailabilityService: DaasAvailabilityService,
    private languageService: LanguageService
  ) {}

  public deliveryMethod$(): Observable<DeliveryMethod> {
    return this.deliveryMethodService.deliveryMethod;
  }

  public setDeliveryMethod(deliveryMethod: DeliveryMethod): void {
    const shop = this.shopService.getShop();
    this.deliveryMethodService.saveDeliveryMethod(deliveryMethod, shop);
  }

  public blocks$(): Observable<ShopPageDeliveryMethodBlock[]> {
    return combineLatest({
      shop: this.shopService.shop,
      timeslot: this.timeslotsService.timeslot$,
      canDeliverToAddress: this.shopService.canDeliverToAddress,
      daasAvailability: this.daasAvailabilityService.daasAvailability
    }).pipe(
      map(({ shop, timeslot, canDeliverToAddress, daasAvailability }) => {
        const blocks: ShopPageDeliveryMethodBlock[] = [];
        if (shop.delivery) {
          const deliveryBlock = this.generateShopPageDeliveryMethodBlock(
            shop,
            timeslot,
            'delivery',
            canDeliverToAddress,
            daasAvailability
          );
          blocks.push(deliveryBlock);
        }
        if (shop.takeAway) {
          const takeAwayBlock = this.generateShopPageDeliveryMethodBlock(
            shop,
            timeslot,
            'takeAway',
            canDeliverToAddress,
            daasAvailability
          );
          blocks.push(takeAwayBlock);
        }
        return blocks;
      })
    );
  }

  private generateShopPageDeliveryMethodBlock(
    shop: Shop,
    timeslot: Timeslot,
    deliveryMethod: DeliveryMethod,
    canDeliverToAddress: boolean,
    daasAvailability: DaasAvailability
  ): ShopPageDeliveryMethodBlock {
    // TODO @faropoulos also reset the selected method, prolly on Singleton Service
    const estimationTime = getShopEstimationTimeText(shop, deliveryMethod, daasAvailability);
    const showEstimation = !timeslot && estimationTime !== undefined;
    const translatedText = this.languageService.getTextByKey('delivery_method_in_time', {
      _DELIVERY_METHOD: deliveryMethod,
      _TIME: estimationTime
    });
    const title = capitalize(showEstimation ? translatedText : deliveryMethod);
    const minimumOrderPriceText = getShopMinimumOrderPriceText(shop, deliveryMethod, canDeliverToAddress, currencyCode);
    const deliveryFeeText = getShopDeliveryFeeText(shop, deliveryMethod, currencyCode);
    return { title, minimumOrderPriceText, deliveryFeeText, deliveryMethod };
  }
}
