import { Category, Product, Shop } from '@box-types';
import uniq from 'lodash-es/uniq';
import orderBy from 'lodash-es/orderBy';
import { appConfig } from '@box-configs/app.config';

// todo test
export { normalizeCategories, generateDefaultExtraCategories, getDefaultExtraCategory };

function normalizeCategories(shop: Shop, categories: Category[], products: Product[]): Category[] {
  if (!categories.length) return [];
  if (shop.categoryView) {
    return categories.filter((cat) => cat.enabled);
  }

  if (!products.length) return [];
  const childrenCategoriesIDs = products.map((product) => product.category._id);
  const parentCategoriesIDs = products.map((product) => product.category.parent);
  const uniqCategoriesIDs = uniq([...childrenCategoriesIDs, ...parentCategoriesIDs]);
  return categories
    .filter((category) => category.enabled && uniqCategoriesIDs.includes(category._id))
    .map((category) => {
      const children = category.children || [];
      const enabledChildrenWithProducts = children.filter(
        (child) => child.enabled && uniqCategoriesIDs.includes(child._id)
      );
      if (category.children?.length && !enabledChildrenWithProducts?.length) return;
      const sortedChildren = orderBy(enabledChildrenWithProducts, 'categoryIndex', 'asc');
      return { ...category, children: sortedChildren };
    })
    .filter(Boolean);
}

function generateDefaultExtraCategories(suggestionsCategoryIndex?: number): Category[] {
  const { ORDERS_CATEGORY_INDEX, REMINDER_CATEGORY_INDEX, SUGGESTIONS_CATEGORY_INDEX, OFFERS_CATEGORY_INDEX } =
    appConfig.shops.menu;
  const finalSuggestionsCategoryIndex = suggestionsCategoryIndex ?? SUGGESTIONS_CATEGORY_INDEX;
  return [
    { _id: 'orders_category', name: 'recent_orders', children: [], categoryIndex: ORDERS_CATEGORY_INDEX },
    { _id: 'reminder_category', name: 'combine_and_win', children: [], categoryIndex: REMINDER_CATEGORY_INDEX },
    { _id: 'suggestions_category', name: 'for_you', children: [], categoryIndex: finalSuggestionsCategoryIndex },
    { _id: 'client_offers_category', name: 'offers_', children: [], categoryIndex: OFFERS_CATEGORY_INDEX }
  ];
}

function getDefaultExtraCategory(defaultCategories: Category[], key: string): Category {
  if (!defaultCategories.length || !key) return undefined;
  return defaultCategories.find((category) => category._id === key);
}
