import { Component, OnInit, Renderer2, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Shop,
  MarketCardCreateDialogData,
  MarketCardCreateDialogResponse,
  MarketCardValidationDialogResponse,
  MarketCardValidationDialogData,
  MarketCardCreateResponse,
  BoxDialogValidationMode,
  SMLoyaltyConfiguration,
  GetTextByKeyType
} from '@box-types';
import { LoaderService, UserService, DialogService } from '@box-core/services';
import { finalize } from 'rxjs/operators';
import { MarketCardCreateDialogService } from './market-card-create-dialog.service';
import { MarketCardValidationDialogComponent } from '@box-market-widget/components';
import { Observable } from 'rxjs';
import { LanguageService } from '@box-core/services/language.service';

@Component({
  selector: 'market-card-create-dialog',
  templateUrl: './market-card-create-dialog.component.html',
  styleUrls: ['./market-card-create-dialog.component.scss'],
  providers: [MarketCardCreateDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketCardCreateDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public logo: string;
  public logoAlt: string;
  public shopName: string;
  public cardName: string;
  public saveCardTermsText: string;
  public createFormGroup: FormGroup;
  public validationMode: BoxDialogValidationMode;
  public readonly t: GetTextByKeyType; // for the template to use

  private shop: Shop;
  private supermarket: string;
  private loyaltyConfig: SMLoyaltyConfiguration;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<MarketCardCreateDialogComponent>,
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private marketCardCreateDialogService: MarketCardCreateDialogService,
    private userService: UserService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) private data: MarketCardCreateDialogData
  ) {
    super(renderer);
    this.t = this.languageService.getTextByKey.bind(this.languageService);
    this.shop = this.data.shop;
  }

  ngOnInit(): void {
    this.validationMode = this.shop.loyaltyCardLoginMethod;
    this.supermarket = this.shop.integrator.company;
    this.loyaltyConfig = this.marketCardCreateDialogService.getSMLoyaltyConfig(this.supermarket);
    this.shopName = this.loyaltyConfig.smName;
    this.cardName = this.loyaltyConfig.shortCardName;
    this.logo = this.marketCardCreateDialogService.getShopLogoSrc(this.loyaltyConfig);
    this.logoAlt = this.shopName;
    this.saveCardTermsText = this.marketCardCreateDialogService.getSaveCardTermsText(this.loyaltyConfig);
    const inputControl = new FormControl(
      this.marketCardCreateDialogService.generateDefaultInputValue(this.validationMode),
      Validators.compose([
        this.marketCardCreateDialogService.getInputValidator(this.validationMode),
        Validators.required // eslint-disable-line @typescript-eslint/unbound-method
      ])
    );
    const saveCardTermsControl = new FormControl(false);
    this.createFormGroup = new FormGroup({ inputControl, saveCardTermsControl });
  }

  public onCreate(): void {
    if (this.createFormGroup.invalid) return;
    const input = this.createFormGroup.controls['inputControl'].value as string;
    // We want to have the save: true default value if we can't find the canSave property on smLoyaltyInfo on configuration file
    const saveCard = this.saveCardTermsText
      ? (this.createFormGroup.controls['saveCardTermsControl'].value as boolean)
      : true;

    if (this.validationMode === 'EMAIL') {
      this.createCardWithEmail(input, saveCard);
    } else {
      this.createCardWithMobile(input, saveCard);
    }
  }

  private createCardWithEmail(email: string, saveCard: boolean): void {
    // phone verification has already run in previous state
    const msisdn = this.userService.getUser().verifiedMSISDN;
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: {
        shop: this.shop,
        validationConfig: { email },
        saveCard,
        reason: 'create'
      } as MarketCardValidationDialogData
    };
    this.dialogService
      .openDialog(MarketCardValidationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: MarketCardValidationDialogResponse) => {
        if (!data?.success) return;
        this.createCard(msisdn, email, data.otp, data.otpId, saveCard).subscribe({
          next: (response) => {
            this.closeDialog({
              saveCard,
              phoneNumber: msisdn,
              cardId: response.cardId,
              otpId: response.otpId,
              email: response.email,
              customerToken: response.customerToken,
              points: response.points,
              cardName: this.supermarket
            });
          }
        });
      });
  }

  private createCardWithMobile(mobile: string, saveCard: boolean): void {
    // phone verification has already run in previous state
    this.createCard(mobile, undefined, undefined, undefined, false).subscribe({
      next: (response) => {
        const dialogConfig: MatDialogConfig = {
          panelClass: 'box-dialog-fit-content',
          data: {
            shop: this.shop,
            validationConfig: { phoneNumber: mobile, cardNumber: response.cardId },
            saveCard,
            reason: 'create'
          } as MarketCardValidationDialogData
        };

        this.dialogService
          .openDialog(MarketCardValidationDialogComponent, dialogConfig)
          .afterClosed()
          .subscribe((data: MarketCardValidationDialogResponse) => {
            if (!data?.success) return;
            this.closeDialog({
              saveCard,
              phoneNumber: mobile,
              cardId: response.cardId,
              otpId: response.otpId,
              email: response.email,
              customerToken: response.customerToken,
              points: response.points,
              cardName: this.supermarket
            });
          });
      }
    });
  }

  public closeDialog(data?: MarketCardCreateDialogResponse): void {
    this.dialogRef.close(data);
  }

  private createCard(
    mobile: string,
    email: string,
    otp: string,
    otpId: string,
    save: boolean
  ): Observable<MarketCardCreateResponse> {
    this.loaderService.setState(true);
    return this.marketCardCreateDialogService
      .createCard(mobile, email, this.supermarket, this.shop._id, otp, otpId, save)
      .pipe(
        finalize(() => {
          this.loaderService.setState(false);
          this.changeDetectorRef.detectChanges();
        })
      );
  }
}
