<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="unavailable_" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <ng-container *ngIf="loading; else couponsLoaded">
      <div class="rewards-unavailable-coupons-dialog-coupons-container">
        <div class="rewards-unavailable-coupons-dialog-coupon-skeleton"></div>
        <div class="rewards-unavailable-coupons-dialog-coupon-skeleton"></div>
      </div>
    </ng-container>
    <ng-template #couponsLoaded>
      <ng-container *ngIf="coupons.length; else couponsEmptyState">
        <div class="rewards-unavailable-coupons-dialog-coupons-container">
          <coupon *ngFor="let coupon of coupons" [coupon]="coupon"></coupon>
        </div>
      </ng-container>
      <ng-template #couponsEmptyState>
        <div class="rewards-unavailable-coupons-dialog-empty-state">
          <span class="rewards-unavailable-coupons-dialog-empty-state-text">{{
            'unavailable_coupons_not_found' | translate
          }}</span>
          <div class="rewards-unavailable-coupons-dialog-empty-state-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32">
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <g fill="#807F85" fill-rule="nonzero" transform="translate(-476 -372)">
                  <g transform="translate(476.545 372)">
                    <g transform="rotate(10 -15.062 21.318)">
                      <path
                        id="Combined-Shape"
                        d="M36.024.001c.54 0 .976.433.976.968v5.16a.972.972 0 0 1-.976.968c-1.614 0-2.927 1.303-2.927 2.903 0 1.601 1.313 2.903 2.927 2.903.539 0 .976.434.976.968v5.16a.972.972 0 0 1-.976.969h-22.57a.96.96 0 0 0 .045-.29v-2.58c0-.517-.41-.94-.925-.966l-.033-3.907.096-.005a.97.97 0 0 0 .862-.961V8.71a.972.972 0 0 0-.976-.969l-.018.001-.032-3.906A.972.972 0 0 0 13.5 2.87V.29a.96.96 0 0 0-.044-.29ZM8.376.29v2.58c0 .518.41.94.925.967l.032 3.906-.095.006a.97.97 0 0 0-.862.961v2.58c0 .535.437.968.976.968l.017-.001.033 3.907a.972.972 0 0 0-1.026.966v2.58c0 .102.015.199.044.29H.976A.972.972 0 0 1 0 19.032V13.87c0-.534.437-.968.976-.968 1.614 0 2.927-1.302 2.927-2.903 0-1.6-1.313-2.903-2.927-2.903A.972.972 0 0 1 0 6.13V.969C0 .434.437 0 .976 0H8.42a.96.96 0 0 0-.044.289Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </main>
</div>
