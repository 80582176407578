import { Component, OnInit, Input } from '@angular/core';
import { Order, Product, Offer, OrderDeliveryStatus } from '@box-types';
import { getOrderDeliveryStatus } from '@box/utils';
import dayjs from 'dayjs';

@Component({
  selector: 'shop-order',
  templateUrl: './shop-order.component.html',
  styleUrls: ['./shop-order.component.scss']
})
export class ShopOrderComponent implements OnInit {
  @Input() public order: Order;

  public hiddenItemsQuantity: number;
  public showHiddenItemsQuantity: boolean;
  public itemsToShow: (Offer | Product)[];
  public status: OrderDeliveryStatus;
  public orderPoints: number;
  public orderPrice: number;
  public orderCreatedAtText: string;

  ngOnInit(): void {
    this.setOrderInfo();
  }

  private setOrderInfo(): void {
    this.status = getOrderDeliveryStatus(this.order);
    this.orderPoints = this.order?.marketPlacePoints?.collected;
    this.orderCreatedAtText = dayjs(this.order.createdAt).format('dddd, DD/MM/YYYY');
    const offers = this.order.offers || [];
    const products = this.order.products || [];
    const items: (Offer | Product)[] = [...offers, ...products];
    this.showHiddenItemsQuantity = items.length > 4;
    const itemsQuantity = items.reduce((acc, cur) => acc + cur.quantity, 0);
    this.itemsToShow = items.slice(0, 4);
    const shownItemsQuantity = this.itemsToShow.reduce((acc, cur) => acc + cur.quantity, 0);
    this.hiddenItemsQuantity = itemsQuantity - shownItemsQuantity;
    const totalPrice = this.order.totalPrice;
    const donation = this.order.donation || 0;
    this.orderPrice = totalPrice + donation;
  }
}
