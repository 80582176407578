import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { catchError, Observable, of, tap } from 'rxjs';
import { ConfigurationService, SentryService } from '@box-core/services';
import { APIError, ConfigurationToBeHydrated } from '@box-types';
import { DelayedRetryService } from '@box-core/services/delayed-retry.service';
import { configurationV2 } from '@box-assets/configurationV2';

@Injectable({ providedIn: 'root' })
export class ConfigurationResolver implements Resolve<ConfigurationToBeHydrated> {
  constructor(
    private configService: ConfigurationService,
    private sentryService: SentryService,
    private delayedRetryService: DelayedRetryService
  ) {}

  resolve(): Observable<ConfigurationToBeHydrated> {
    return this.fetchConfiguration().pipe(tap((config) => this.configService.setConfiguration(config)));
  }

  private fetchConfiguration(): Observable<ConfigurationToBeHydrated> {
    return this.configService.fetchConfiguration().pipe(
      this.delayedRetryService.delayedRetry(),
      catchError((error: APIError) => {
        this.sentryService.captureException(error);
        return of(configurationV2 as ConfigurationToBeHydrated);
      })
    );
  }
}
