<checkout-market-banner *ngIf="showCardBanner" class="checkout-order-market-card-points-banner">
  <img [src]="marketCardBanner.image" [alt]="marketCardBanner.cardName" />
  <span>{{ marketCardBanner.description }}</span>
  <a routerLink="/account/supermarket-cards"> {{ 'here_' | translate }} </a>
</checkout-market-banner>
<checkout-market-banner *ngIf="showCardPointsBanner" class="checkout-order-market-card-points-banner">
  <img [src]="marketCardPointsBanner.image" [alt]="marketCardPointsBanner.superMarket" />
  <span>
    {{ 'with_your_order_you_won' | translate }}
    <strong>+{{ marketCardPointsBanner.points }} {{ 'the_points' | translate }}</strong> {{ 'in_female' | translate }}
    {{ marketCardPointsBanner.cardName }} {{ 'your_' | translate }}
  </span>
</checkout-market-banner>
