import { User, UserMarketPlacePoints, EarnedPointsInfoType } from '@box-types';

const DEFAULT_USER_POINTS: UserMarketPlacePoints = { remainingPoints: 0, collectedPoints: 0, consumedPoints: 0 };

function normalizeUser(user: User): User {
  return {
    ...user,
    loyaltyEventsAlreadyUsed: user.loyaltyEventsAlreadyUsed ?? [],
    marketPlacePoints: { ...DEFAULT_USER_POINTS, ...user.marketPlacePoints },
    loyaltyCards: user.loyaltyCards ?? [],
    segments: user.segments ?? []
  };
}

function getUserEarnedPointsInfo(user: User, key: EarnedPointsInfoType): { points: number; count: number } {
  const earnedPointsInfo = user?.earnedRewardsInfo?.earnedPointsInfo ?? {};
  const earnedPointsInfoValue = earnedPointsInfo[key] as { points: number; count: number };
  if (earnedPointsInfoValue?.count === undefined) return;
  return earnedPointsInfoValue;
}

function getUserEarningsInfo(user: User, key: EarnedPointsInfoType): { count: number; amount: number } {
  const earnedPointsInfo = user?.earnedRewardsInfo?.earnedPointsInfo ?? {};
  const earnedPointsInfoValue = earnedPointsInfo[key] as { count: number; amount: number };
  if (earnedPointsInfoValue?.count === undefined) return;
  return earnedPointsInfoValue;
}

function getUserFullName(user: User): string {
  const { firstName, lastName } = user;
  return `${firstName} ${lastName}`;
}

function appendMsisdnToUserAssets(user: User, assets: string[]): string[] {
  const requestAssets: string[] = [];
  if (assets?.length) requestAssets.push(...assets);
  const msisdn = user?.verifiedMSISDN ?? user?.mobile?.phone;
  if (msisdn) requestAssets.push(msisdn);
  return requestAssets;
}

export { normalizeUser, getUserEarnedPointsInfo, getUserEarningsInfo, getUserFullName, appendMsisdnToUserAssets };
