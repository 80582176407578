<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      [title]="'when_do_you_want_to_receive'"
      alignment="left"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <section class="timeslot-section">
      <mat-radio-group class="scheduleDialog-options-radio" (change)="onChangeRadioButton($event)">
        <mat-radio-button
          color="primary"
          class="box-radio-button timeslot-radio-button"
          *ngFor="let option of radioButtonOptions"
          [value]="option.name"
          [disabled]="option.disabled"
          [checked]="option.name === selectedRadioButton"
        >
          <span class="timeslot-radio-button-label">{{ option.label | translate }}</span>
        </mat-radio-button>
      </mat-radio-group>

      <div class="scheduleDialog-options-slots" *ngIf="selectedRadioButton === 'select_slot' && selectedDate">
        <mat-form-field class="scheduleDialog-options-slots-days">
          <mat-select #mySelectedDate [value]="selectedDate" (selectionChange)="onDateChange(mySelectedDate.value)">
            <mat-select-trigger>
              <span class="timeslot-select-trigger">{{ selectedDate.title | translate }}</span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let deliveryDate of deliveryDates"
              class="timeslot-select-option"
              [value]="deliveryDate"
            >
              {{ deliveryDate.title | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="scheduleDialog-options-slots-time">
          <mat-select [(ngModel)]="selectedDate.timeslot">
            <mat-select-trigger>
              <span class="timeslot-select-trigger">{{
                (selectedDate.timeslot.timeSlotStart * 1000 | date: 'HH:mm') +
                  ' - ' +
                  (selectedDate.timeslot.timeSlotEnd * 1000 | date: 'HH:mm')
              }}</span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let timeslot of selectedDate.timeslots"
              class="timeslot-select-option"
              [value]="timeslot"
            >
              {{
                (timeslot.timeSlotStart * 1000 | date: 'HH:mm') + ' - ' + (timeslot.timeSlotEnd * 1000 | date: 'HH:mm')
              }}
            </mat-option>
            <mat-option *ngIf="showNextDayOption" class="disabled-timeslot timeslot-select-option" disabled>
              {{ 'maybe_the_next_day' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </section>
  </main>

  <footer #dialogFooter class="box-dialog-footer">
    <button class="timeslot-submit" box-button theme="primary" (click)="onSubmit()">
      {{ 'confirm_' | translate }}
    </button>
  </footer>
</div>
