import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription, combineLatest, forkJoin, of, zip } from 'rxjs';
import { catchError, finalize, map, tap, startWith, skip, take } from 'rxjs/operators';
import {
  UserService,
  AddressesService,
  OrdersService,
  ShopsService,
  PromoBannersService,
  PromoCampaignsService,
  CampaignTimerService,
  ContestsService,
  DialogService,
  HomeSectionsService,
  TimeslotsService,
  AnalyticsService
} from '@box-core/services';
import { sortShops, saveHomeViewBAFBannerSeen, removeCollectionCampaignsSession, HomeSection } from '@box/utils';
import {
  Shop,
  PromoCampaign,
  FetchShopsOptions,
  Contest,
  APIError,
  GAPromotionConfig,
  SingleBannerConfiguration,
  PromoBanner
} from '@box-types';
import { DiscoverFiltersService } from '@box-core/services/discover-filters.service';
import { HomePageService } from './home-page.service';
import { Router } from '@angular/router';
import { HomePagePopupsService } from '@box-core/pages/home/home-page-popups.service';

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'home-page',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  providers: [HomePageService, HomePagePopupsService]
})
export class HomePage implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') public pageWrapper = 'page-wrapper';
  public isAreaSupported: boolean;
  public homeSections: HomeSection[];
  public promoCampaigns: PromoCampaign[];
  public shops: Shop[];
  public loading: boolean;
  public isMobileScreen: boolean;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1;

  private shopsSubscription: Subscription;
  private homeSubscription: Subscription;
  private breakPointObserverSubscription: Subscription;
  private collectionCampaignsDialogSubscription: Subscription;
  private homePagePopupsSubscription: Subscription;

  constructor(
    private promoCampaignsService: PromoCampaignsService,
    private dialogService: DialogService,
    private addressesService: AddressesService,
    private userService: UserService,
    private ordersService: OrdersService,
    private shopsService: ShopsService,
    private promoBannersService: PromoBannersService,
    private breakpointObserver: BreakpointObserver,
    private discoverFiltersService: DiscoverFiltersService,
    private campaignTimerService: CampaignTimerService,
    private homePageService: HomePageService,
    private contestsService: ContestsService,
    private router: Router,
    private homeSectionsService: HomeSectionsService,
    private timeslotsService: TimeslotsService,
    private analyticsService: AnalyticsService,
    private homePagePopupsService: HomePagePopupsService
  ) {}

  ngOnInit(): void {
    this.homePageService.setMetaTags();
    this.setBreakPointObserverSubscription();
    this.setCollectionCampaignsDialogSubscription();
    this.setHomePagePopupsSubscription();
    this.setHomeSubscription();
  }

  ngAfterViewInit(): void {
    this.triggerAnalyticsEvent();
  }

  ngOnDestroy(): void {
    this.shopsSubscription?.unsubscribe();
    this.homeSubscription?.unsubscribe();
    this.collectionCampaignsDialogSubscription?.unsubscribe();
    this.breakPointObserverSubscription?.unsubscribe();
    this.homePagePopupsSubscription?.unsubscribe();
  }

  public onNavigateToDiscover(): void {
    this.triggerButtonAnalyticsEvents('select_promotion');
    void this.router.navigate(['/discover']);
  }

  private setBreakPointObserverSubscription(): void {
    this.breakPointObserverSubscription = this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => {
      this.isMobileScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    });
  }

  private setHomeSubscription(): void {
    this.loading = true;
    forkJoin({
      promoBanners: this.promoBannersService.fetchPromoBanners().pipe(catchError(() => of([] as PromoBanner[]))),
      contests: this.contestsService.getContests().pipe(catchError(() => of([] as Contest[]))),
      orders: this.ordersService.getOrderHistory$(),
      sections: this.homeSectionsService.getHomeSections$(),
      discoverFilters: this.discoverFiltersService.getDiscoverFilters$()
    }).subscribe(({ promoBanners, contests }) => {
      this.shopsSubscription = combineLatest({
        address: this.addressesService.address$,
        timeslot: this.timeslotsService.timeslot$,
        happyHourState: this.campaignTimerService.whenCampaignIsEnabled$('happy_hour').pipe(startWith(() => null)),
        user: this.userService.user$
      }).subscribe(({ address, timeslot }) => {
        this.loading = true;
        const guid = this.userService.getUser().guid;
        const fetchOptions: FetchShopsOptions = { address, guid };
        this.shopsService
          .getShops(fetchOptions)
          .pipe(
            catchError((error: APIError) => {
              this.dialogService.openErrorDialog(error);
              return of([] as Shop[]);
            }),
            finalize(() => (this.loading = false)),
            tap((shops) => {
              const sortedShops = sortShops(shops, timeslot);
              this.shops = sortedShops;
              this.discoverFiltersService.init(sortedShops);
              this.isAreaSupported = sortedShops.length > 0;
            }),
            map((shops) => {
              const initializedPromoBanners = this.promoBannersService.initializePromoBanners(
                promoBanners,
                address,
                shops
              );
              this.promoBannersService.setPromoBanners(initializedPromoBanners);
              return { shops, banners: initializedPromoBanners };
            }),
            map(({ shops }) => {
              return this.homePageService.decorateFilterAndSortSections(shops, contests);
            })
          )
          .subscribe((homeSections) => {
            this.homeSections = homeSections;
          });
      });
    });
  }

  // is executed after the user changes address and the shops are updated
  private setCollectionCampaignsDialogSubscription(): void {
    this.collectionCampaignsDialogSubscription = zip([
      this.addressesService.address$.pipe(skip(1)),
      this.shopsService.shops.asObservable().pipe(skip(2))
    ]).subscribe(() => {
      // this allows the collections campaign dialog to reappear
      removeCollectionCampaignsSession();
      this.homePageService.showCollectionCampaignsDialog$().subscribe();
    });
  }

  private setHomePagePopupsSubscription(): void {
    this.homePagePopupsSubscription = zip([
      this.addressesService.address$,
      this.shopsService.shops.asObservable().pipe(skip(1)),
      this.userService.user$,
      this.promoBannersService.promoBanners$.pipe(skip(1)),
      this.ordersService.orderHistory$,
      this.promoCampaignsService.promoCampaigns$
    ])
      .pipe(take(1))
      .subscribe(() => {
        this.homePagePopupsService.initializePopups();
      });
  }

  public onRemoveSection(section: HomeSection): void {
    const inform$ = () => {
      if (section.type !== 'singleBanner') return of(null);
      return this.dialogService
        .openInfoDialog({
          title: 'BOX',
          messages: ['you_can_find_the_friends_invitation_in_your_profile_at_any_time']
        })
        .afterClosed()
        .pipe(tap(() => saveHomeViewBAFBannerSeen()));
    };

    inform$().subscribe(() => {
      this.homeSections = this.homeSections.filter((s) => s._id !== section._id);
    });
  }

  public onCtaButtonClicked(singleBannerConfig: SingleBannerConfiguration): void {
    this.triggerBannerButtonAnalyticsEvents(singleBannerConfig);
  }

  public onSingleBannerEnteredViewport(singleBannerConfig: SingleBannerConfiguration): void {
    this.triggerBannerAnalyticsEvents(singleBannerConfig);
  }

  public onSeeAllButtonEnteredViewport(): void {
    this.triggerButtonAnalyticsEvents('view_promotion');
  }

  private triggerAnalyticsEvent(): void {
    const gaConfig = {
      creative_name: '',
      creative_slot: 'discover_home',
      promotion_id: '',
      promotion_name: 'tabs'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerBannerAnalyticsEvents(singleBannerConfig: SingleBannerConfiguration): void {
    const gaConfig = {
      creative_name: singleBannerConfig.title,
      creative_slot: 'discover_home',
      promotion_id: '',
      promotion_name: singleBannerConfig.slug
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerBannerButtonAnalyticsEvents(singleBannerConfig: SingleBannerConfiguration): void {
    const gaConfig = {
      creative_name: singleBannerConfig.cta,
      creative_slot: 'discover_home',
      promotion_id: '',
      promotion_name: singleBannerConfig.slug
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
  }

  private triggerButtonAnalyticsEvents(eventName: string): void {
    const gaConfig = {
      creative_name: 'Δες όλα τα καταστήματα',
      creative_slot: 'discover_home',
      promotion_id: '',
      promotion_name: 'see_all_link'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }
}
