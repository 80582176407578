import { Card, PromoCampaign, PromoCampaignBanner, Payment, GetTextByKeyType } from '@box-types';
import { sortSuggestionElements } from '../core';
import { storageGet } from '../storage';
import { STORAGE_PAYMENT_KEY } from './payment.types';

export {
  getPaymentCardBrand,
  cardToPaymentType,
  getCardFromPaymentType,
  doesPaymentTypeMatchCampaign,
  getBannersWithMinimumRequiredAssets,
  getCampaignsCompatibleWithPaymentType,
  doPaymentBannersHaveRequiredAssetsForMultiView,
  sortPaymentCampaigns,
  isPaymentCard,
  isPaymentAddNewCardAndSave,
  getStoredPaymentHash,
  getCheckoutCartCtaText
};

function getCheckoutCartCtaText(payment: Payment): string {
  return payment?.addNew ? 'card_addition' : 'send_it';
}

function getPaymentCardBrand(card: Card): string {
  const brand = card?.cardDetailsAddedByBox?.brand;
  if (!brand) return;
  const brandLowerCase = brand.toLowerCase();
  if (brandLowerCase.includes('mastercard')) return 'mastercard';
  if (brandLowerCase.includes('visa')) return 'visa';
  if (brandLowerCase.includes('maestro')) return 'maestro';
  if (brandLowerCase.includes('diners')) return 'dinersclub';
}

function cardToPaymentType(card: Card, translateFn: GetTextByKeyType): Payment {
  const icon = getPaymentCardBrand(card);
  const cardPan = translateFn('the_card_pan', {
    _CARD_PAN: card.pan
  });
  return { icon, title: cardPan, type: 'card', card, addNew: false };
}

function getCardFromPaymentType(paymentType: Payment, cardList: Card[]): Card {
  if (!paymentType || !cardList?.length) return;
  const isCardType = paymentType.type === 'card';
  const isNewCard = paymentType.addNew;
  if (!isCardType || isNewCard) return;
  const cardDigits = paymentType.card.pan.slice(-4);
  return cardList.find((c) => c.pan.slice(-4) === cardDigits);
}

function doesPaymentTypeMatchCampaign(paymentType: Payment, campaign: PromoCampaign): boolean {
  if (!paymentType?.card || !campaign) return false;
  const paymentCardDetails = paymentType.card.cardDetailsAddedByBox;
  const campaignCardsDetails = campaign.cardRelatedDetails;
  const paymentCardBrand = getPaymentCardBrand(paymentType.card);
  if (paymentType.type !== 'card' || !paymentCardDetails || !paymentCardBrand) return false;
  if (!campaignCardsDetails?.length) return true;

  return campaignCardsDetails.some((campaignCardDetails) => {
    const matchesBrand =
      paymentCardBrand === campaignCardDetails?.brand?.toLowerCase() ||
      campaignCardDetails.brand === 'any' ||
      !campaignCardDetails.brand;
    const matchesBank =
      paymentCardDetails?.bank?.toLowerCase() === campaignCardDetails?.bank?.toLowerCase() ||
      campaignCardDetails.bank === 'any' ||
      !campaignCardDetails.bank;
    const matchesProductType =
      paymentCardDetails.productType?.toLowerCase() === campaignCardDetails?.productType?.toLowerCase() ||
      campaignCardDetails?.productType === 'any' ||
      !campaignCardDetails.productType;
    return matchesBrand && matchesBank && matchesProductType;
  });
}

function getCampaignsCompatibleWithPaymentType(paymentType: Payment, campaigns: PromoCampaign[]): PromoCampaign[] {
  if (!paymentType || !campaigns?.length) return [];
  return campaigns.filter((campaign) => doesPaymentTypeMatchCampaign(paymentType, campaign));
}

function getBannersWithMinimumRequiredAssets(paymentCampaignBanners: PromoCampaignBanner[]): PromoCampaignBanner[] {
  return paymentCampaignBanners.filter(
    (banner) => banner.checkoutBannerTitle && banner.checkoutBannerLogo && banner.logo
  );
}

function doPaymentBannersHaveRequiredAssetsForMultiView(banners: PromoCampaignBanner[]): boolean {
  // banners need to be sorted at this point
  const enoughBanners = banners?.length > 1;
  if (!enoughBanners) return false;
  return Boolean(
    banners[0].paymentDoubleBannerLeftSide &&
      banners[1].paymentDoubleBannerRightSideBorder &&
      banners[1].paymentDoubleBannerCampaignLogo &&
      (banners[1].multiplier || banners[1].points)
  );
}

function sortPaymentCampaigns(paymentCampaigns: PromoCampaign[], limit?: number): PromoCampaign[] {
  if (!paymentCampaigns?.length) return [];
  const paymentCampaignsByPriority = paymentCampaigns.sort((a, b) => b.priority - a.priority).slice(0, limit);
  return sortSuggestionElements(paymentCampaignsByPriority);
}

function isPaymentCard(payment: Payment): boolean {
  return ['card'].includes(payment.type);
}

function isPaymentAddNewCardAndSave(payment: Payment): boolean {
  const { type, addNew, saveCard } = payment;
  if (type === 'card' && addNew && saveCard) return true;
  return false;
}

function getStoredPaymentHash(): string {
  return storageGet<string>(STORAGE_PAYMENT_KEY, localStorage);
}
