import { Injectable } from '@angular/core';
import { iif, Observable, of, timer } from 'rxjs';
import {
  Contest,
  ContestPrize,
  ContestParticipation,
  ContestStatus,
  ContestStatusHistory,
  APIResponse,
  GetTextByKeyType
} from '@box-types';
import { catchError, concatMap, map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@box-env/environment';
import { UserService } from '@box-core/services/user.service';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { LanguageService } from '@box-core/services/language.service';

const COUNTDOWN_INTERVAL = 60 * 1000; // Every minute
const DATE_FORMAT = 'DD/MM/YY';

let t: GetTextByKeyType;

@Injectable({ providedIn: 'root' })
export class ContestsService {
  private BOX_API: string = environment.application.API_URL;
  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private platform: Platform,
    private languageService: LanguageService
  ) {
    this.initializeTranslateFn();
  }

  private initializeTranslateFn(): void {
    t = this.languageService.getTextByKey.bind(this.languageService);
  }

  public getContests(): Observable<Contest[]> {
    return this.fetchContests().pipe(
      concatMap((contests) =>
        iif(
          () => this.userService.isGuest || !contests?.length,
          of(contests),
          this.fetchParticipations().pipe(
            map((participations) => this.joinContestsWithParticipations(contests, participations)),
            catchError(() => of(contests))
          )
        )
      ),
      map((contests) => this.initializeContests(contests)),
      catchError(() => of([] as Contest[]))
    );
  }

  public getWonContests(): Observable<Contest[]> {
    return this.fetchWonParticipations().pipe(
      map((participations) => {
        const wonContests = this.getContestsFromParticipations(participations);
        return this.initializeContests(wonContests);
      }),
      catchError(() => of([] as Contest[]))
    );
  }

  public getContestBySlug(slug: string): Observable<Contest> {
    return iif(
      () => this.userService.isGuest,
      this.getContests(),
      this.getWonContests().pipe(
        concatMap((wonContests) =>
          iif(
            () => Boolean(wonContests.length),
            of(wonContests),
            this.getContests().pipe(catchError(() => of(undefined)))
          )
        )
      )
    ).pipe(
      map((contests) => {
        const contest = contests.find((contest) => contest.slug === slug);
        if (!contest) return void this.router.navigate(['/home']);
        return contest;
      })
    );
  }

  private joinContestsWithParticipations(contests: Contest[], participations: ContestParticipation[]): Contest[] {
    if (!contests?.length) return [];
    if (!participations?.length) return contests;
    return contests.map((contest) => {
      contest.participation = participations.find(
        (participation) => participation.externalContestId === contest.externalContestId
      );
      return contest;
    });
  }

  private getContestsFromParticipations(participations: ContestParticipation[]): Contest[] {
    return participations.map((participation) => {
      return {
        ...participation.contest,
        participation: participation
      };
    });
  }

  private fetchContests(): Observable<Contest[]> {
    return this.http
      .get(`${this.BOX_API}/contests`)
      .pipe(map((response: APIResponse<{ contests: Contest[] }>) => response.payload.contests));
  }

  private fetchParticipations(): Observable<ContestParticipation[]> {
    return this.http
      .get(`${this.BOX_API}/contest-participants`)
      .pipe(
        map(
          (response: APIResponse<{ participantsForActiveContests: ContestParticipation[] }>) =>
            response.payload.participantsForActiveContests
        )
      );
  }

  private fetchWonParticipations(): Observable<ContestParticipation[]> {
    return this.http.get(`${this.BOX_API}/contest-participants/winners`).pipe(
      map((response: APIResponse<{ wonContestParticipantsWithContest: ContestParticipation[] }>) => {
        const wonParticipations = response.payload.wonContestParticipantsWithContest;
        return wonParticipations.filter((participation) => {
          if (participation.status !== 'PENDING') return true;
          const acceptanceDeadline = dayjs(participation.needsConfirmationTill);
          return dayjs().isBefore(acceptanceDeadline);
        });
      })
    );
  }

  public registerContest(externalContestId: number, phone: string): Observable<ContestParticipation> {
    const data = { externalContestId: externalContestId, contactPhone: phone };
    return this.http
      .post(`${this.BOX_API}/contest-participants`, data)
      .pipe(map((response: APIResponse<{ participant: ContestParticipation }>) => response.payload.participant));
  }

  public receiveContestPrize(externalContestId: number, accept: boolean): Observable<ContestPrize> {
    const data = { externalContestId: externalContestId, confirm: accept };
    return this.http
      .put(`${this.BOX_API}/contest-participants/winners/confirmation`, data)
      .pipe(map((response: APIResponse<{ prize: ContestPrize }>) => response.payload.prize));
  }

  public initializeContests(contests: Contest[]): Contest[] {
    const enabledContests = contests.filter((contest) => contest.enabled);
    const decoratedContests = this.decorateContests(enabledContests);
    return this.orderContests(decoratedContests);
  }

  private decorateContests(contests: Contest[]): Contest[] {
    if (!contests?.length) return [];
    return contests.map((contest) => {
      const participationStatus = contest.participation?.status;
      const winningStates: ContestStatus[] = ['PENDING', 'ACCEPTED', 'REJECTED', 'REJECTED_AUTOMATICALLY'];
      contest.won = winningStates.includes(participationStatus);
      return contest;
    });
  }

  private orderContests(contests: Contest[]): Contest[] {
    const contestsSortedByExpirationDay = orderBy(contests, 'expirationDate', 'asc');
    const indexOfFirstActiveContest = contestsSortedByExpirationDay.findIndex((contest) =>
      dayjs().isBefore(contest.expirationDate)
    );
    const sortedExpiredContests = contestsSortedByExpirationDay.slice(0, indexOfFirstActiveContest);
    const sortedActiveContests = contestsSortedByExpirationDay.slice(
      indexOfFirstActiveContest,
      contestsSortedByExpirationDay.length
    );
    return [...sortedActiveContests, ...sortedExpiredContests];
  }

  public triggerCountDown(expirationDateString: string): Observable<number> {
    const expirationDate = dayjs(expirationDateString);
    if (!this.platform.isBrowser || !expirationDate) return undefined;
    if (expirationDate.isBefore(dayjs())) {
      return of(0);
    }

    return timer(this.getNextMinuteRemainingSeconds(), COUNTDOWN_INTERVAL).pipe(
      startWith(expirationDate.diff(dayjs(), 'milliseconds')),
      map(() => {
        return expirationDate.diff(dayjs(), 'milliseconds');
      })
    );
  }

  private getNextMinuteRemainingSeconds(): number {
    const currentDate = dayjs();
    const delaySeconds = 60 - currentDate.second();
    const delayMilliseconds = 1000 - currentDate.millisecond();
    return delaySeconds * 1000 + delayMilliseconds;
  }

  /**
   *
   * @param remainingMillis remaining time in milliseconds
   * @param expirationDateString is an ISODate for example 2021-11-23T15:00:00.000+0000
   */
  public convertMillisToReadableText(remainingMillis: number, expirationDateString: string): string {
    if (!remainingMillis || !expirationDateString || remainingMillis <= 0) return t('expired_');
    const expirationDate = dayjs(expirationDateString);

    const days = expirationDate.diff(dayjs(), 'days');
    if (days > 2) return expirationDate.format(DATE_FORMAT);
    if (days === 2) return `2 ${t('days_')}`;
    if (days === 1) return `1 ${t('day_')}`;

    const hours = expirationDate.diff(dayjs(), 'hours');
    if (hours > 1) return `${hours.toString()} ${t('hours_')}`;
    if (hours === 1) return `1 ${t('hour_')}`;

    const minutes = expirationDate.diff(dayjs(), 'minutes');
    if (minutes <= 1) return `1'`;
    return `${minutes.toString()}'`;
  }

  public convertParticipationsToReadableText(participations: number): string {
    if (participations === undefined || participations < 0) return;
    if (participations === 1) return `1 ${t('participation_')}`;
    return `${participations} ${t('participations_')}`;
  }

  public getRegistrationDate(statusHistory: ContestStatusHistory): string {
    if (!statusHistory?.length) return undefined;
    const registrationEntry = statusHistory.find((entry) => entry.status === 'REGISTERED');
    if (!registrationEntry?.date) return undefined;
    return this.getDateInTwoDigitForm(registrationEntry.date);
  }

  public getDateInTwoDigitForm(date?: string): string {
    if (!date) return dayjs().format(DATE_FORMAT);
    return dayjs(date).format(DATE_FORMAT);
  }

  public getFullDate(isoDate: string): string {
    if (!isoDate) return;
    const formattedDate = dayjs(isoDate).format(DATE_FORMAT);
    const formattedHours = dayjs(isoDate).format('HH:mm');
    return `${formattedDate}', ${t('hour_')} ${formattedHours}`;
  }
}
