<div class="swiper-section box-swiper">
  <div class="swiper-section-header">
    <div class="swiper-section-header-content">
      <h3 class="swiper-section-title">{{ title }}</h3>
    </div>
    <div #navButtonContainer class="swiper-section-navigation">
      <button #prevBtn mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div [swiper]="swiperOptions" *ngIf="mainViewTiles?.length > 0">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let tile of mainViewTiles">
        <collapsible-tile
          visibilityObserver
          [triggerOnce]="true"
          [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
          (enteredViewport)="onCuisineEnteredViewport(tile.item)"
          (click)="onCuisineClick(tile.item)"
          [tile]="tile"
          [size]="'large'"
        >
        </collapsible-tile>
      </div>
      <div class="swiper-slide">
        <button box-button theme="secondary" class="home-cuisine-action ods-typography-button-l" (click)="onAction()">
          {{ 'see_them_all' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
