<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header borderless">
    <box-dialog-header class="myo-dialog-header" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main class="box-dialog-content">
    <figure class="image contain-image dfu-image">
      <img src="/assets/images/general/cosmote-dfu-dialog-image.svg" alt="Cosmote Deals For You" />
    </figure>
    <p class="dfu-instructions">
      {{ 'dfy_offers_are_available_for_all_cosmote_customers' | translate }}
    </p>
    <p class="dfu-instructions">
      {{ 'you_can_take_your_code_from_my_cosmote_app' | translate }}
    </p>
  </main>
</div>
