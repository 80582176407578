import { Injectable } from '@angular/core';
import {
  DialogService,
  PromoCampaignsService,
  ShopService,
  UserService,
  PaymentTypesService,
  CartService,
  ExplorePromoCampaignService,
  AddressesService,
  DeliveryMethodService,
  CampaignEligibilityService,
  PurchaseEventService,
  AnalyticsService,
  PaymentGatewayService,
  RouterHistoryService,
  OrdersService,
  LoaderService,
  MarketLoyaltyService,
  SentryService,
  CampaignTimerService,
  translate,
  CurrencyService
} from '@box-core/services';
import {
  PromoCampaignBanner,
  Shop,
  InfoDialogData,
  MarketCardDetails,
  DeliveryMethod,
  Order,
  GAClickCommentsCheckOutConfig,
  ConfirmDialogResponse,
  PaymentTypesOptions,
  APIError,
  MarketOrderCheck,
  Address,
  MarketOrderCheckOptions,
  Product,
  Offer
} from '@box-types';
import {
  getLoyaltyCardForIntegrator,
  isLowOrderProbabilityUserWithCoupon,
  promoCampaignHasImageKey,
  getShopMinimumPrice,
  getShopDeliveryFee,
  getCouponDiscount,
  isPaymentCard,
  checkMerchantSponsoredCampaignEligibility,
  getMultiplierSum,
  getPointsSum,
  getPurchaseEventGAConfig,
  getPurchaseEventMPConfig,
  itemsPriceIsOverMinimum,
  getProductsForOrder,
  getOffersForOrder,
  getInitiatePaymentOptions,
  isCouponDummy,
  isOrderRejected,
  isOrderPending,
  isOrderAccepted,
  isLowOrderProbabilityUser,
  getHappyHourExpirationMessage,
  currencyFormat
} from '@box/utils';
import { MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { BoxConfirmDialogComponent } from '@box-shared/components';
import { CheckoutOrderPreviewOptions, CheckoutOrderPreview } from '@box-checkout/checkout.types';
import {
  CheckoutInfoService,
  CheckoutCouponsService,
  CartSuggestionService,
  CheckoutStateService
} from '@box-checkout/services';
import { LanguageService } from '@box-core/services/language.service';
import { Router } from '@angular/router';
import { CheckoutSynergyDialogComponent } from '@box-checkout/components';
import { interval, Observable, of, mapTo } from 'rxjs';
import { tap, finalize, catchError, map, switchMap } from 'rxjs/operators';

export const PROBLEMATIC_ADDRESS_DIALOG_DATA: InfoDialogData = {
  title: 'missing_information_from_your_address',
  messages: ['please_add_it']
};

@Injectable()
export class CheckoutService {
  constructor(
    private cartService: CartService,
    private shopService: ShopService,
    private paymentTypesService: PaymentTypesService,
    private promoCampaignsService: PromoCampaignsService,
    private userService: UserService,
    private dialogService: DialogService,
    private exploreService: ExplorePromoCampaignService,
    private addressesService: AddressesService,
    private checkoutInfoService: CheckoutInfoService,
    private deliveryMethodService: DeliveryMethodService,
    private checkoutCouponsService: CheckoutCouponsService,
    private cartSuggestionService: CartSuggestionService,
    private campaignEligibilityService: CampaignEligibilityService,
    private checkoutStateService: CheckoutStateService,
    private purchaseEventService: PurchaseEventService,
    private analyticsService: AnalyticsService,
    private routerHistoryService: RouterHistoryService,
    private router: Router,
    private ordersService: OrdersService,
    private loaderService: LoaderService,
    private marketLoyaltyService: MarketLoyaltyService,
    private paymentGatewayService: PaymentGatewayService,
    private sentryService: SentryService,
    private campaignTimerService: CampaignTimerService,
    private currencyService: CurrencyService,
    private languageService: LanguageService
  ) {}

  public showInsufficientMinimumPriceDialog(
    shop: Shop,
    method: DeliveryMethod
  ): MatDialogRef<BoxConfirmDialogComponent> {
    // todo handle sm case where there is no delivery
    const minimumDeliveryPrice = getShopMinimumPrice(shop, method);
    const currencyCode = this.currencyService.getCurrencyCode();
    const priceToText = currencyFormat(minimumDeliveryPrice, { currencyCode });
    const translatedMessages =
      method === 'delivery'
        ? [
            this.languageService.getTextByKey('min_order_price_for_delivery_is', { _PRICE_TEXT: priceToText }),
            this.languageService.getTextByKey('your_cart_is_not_sufficient_to_continue_with_a_delivery')
          ]
        : [
            this.languageService.getTextByKey('min_order_price_for_takeaway_is', { _PRICE_TEXT: priceToText }),
            this.languageService.getTextByKey('your_cart_is_not_sufficient_to_continue_with_a_takeaway')
          ];
    return this.dialogService.openConfirmDialog(
      {
        title: 'minimum_order_title',
        messages: translatedMessages,
        cancelText: 'back_to_shop',
        confirmText: method === 'delivery' ? 'i_will_take_from_the_shop' : 'i_will_take_with_delivery',
        buttonOrientation: 'vertical'
      },
      { panelClass: 'box-dialog-small-with-large-buttons' }
    );
  }

  public showTakeAwayAutoPaymentSelectionInfo(): void {
    const title = 'Take away';
    const message = 'payment_by_card_is_required_for_takeaway_orders';
    this.dialogService.openInfoDialog({ title, messages: [message] });
  }

  public addPromoCampaignsShowed(name: string): void {
    const currentNames = this.checkoutStateService.getPromoCampaignsShowed();
    if (currentNames?.includes(name)) return;
    this.checkoutStateService.setPromoCampaignsShowed([...currentNames, name]);
  }

  public isCheckoutTipEnabled(): boolean {
    const shop = this.shopService.getShop();
    if (!shop.supportsTip) return false;
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    if (deliveryMethod === 'takeAway') return false;
    const paymentType = this.paymentTypesService.getPaymentType();
    if (!paymentType) return false;
    const isCard = isPaymentCard(paymentType);
    if (!isCard) return false;
    return true;
  }

  public isCheckoutDonationEnabled(): boolean {
    const paymentType = this.paymentTypesService.getPaymentType();
    if (!paymentType) return false;
    const isCard = isPaymentCard(paymentType);
    if (!isCard) return false;
    return true;
  }

  public getCheckoutSynergyBanners(): PromoCampaignBanner[] {
    const consumedCampaigns = this.campaignEligibilityService.getConsumedPromoCampaigns().filter(
      // we filter out campaigns that are only eligible if you add products from checkout
      (campaign) => {
        const exploreCouponActive = isLowOrderProbabilityUserWithCoupon(this.userService.getUser());
        if (exploreCouponActive && campaign.name === 'new_users') return false;
        const { name, triggeredByClient, cartSuggestion } = campaign;
        const isBoxaki = ['new_users', 'happy_hour', 'returning_users'].includes(name);
        // if the campaign is boxaki, then we replace the image with the shop image, so we count as hasImage
        const hasImage = isBoxaki || (!isBoxaki && promoCampaignHasImageKey(campaign, 'checkoutBannerLogo'));
        if (!(triggeredByClient && cartSuggestion?.enabled) && hasImage) return true;
        return false;
      }
    );
    return consumedCampaigns.map((campaign) => this.promoCampaignsService.campaignToCampaignBanner(campaign));
  }

  private generateCheckoutOrderPreview(options: CheckoutOrderPreviewOptions): CheckoutOrderPreview {
    const shop = this.shopService.getShop();
    const cart = this.cartService.getCart();
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const { isSuperMarket } = shop;
    const {
      tip,
      donation,
      coupon,
      bagsPrice,
      checkOrderPrice,
      pointsDiscount,
      smLoyaltyDiscount,
      bankLoyaltyDiscount
    } = options;
    /* We are using the isSupermarket here due to a tech flaw we have with the Discount Calculation. We
    assume that the non SM Shops have no pure Discount. That is a subject to change.
    if the price is raised by checkOrder it needs to be added to the starting price.
    Bags are handled like any other cart item, they need to be added to both the starting price and final price*/
    const itemsStartingPrice = Math.max(checkOrderPrice ?? 0, cart.itemsStartingPrice);
    const cartStartingPrice = (isSuperMarket ? itemsStartingPrice : cart.itemsFinalPrice) + bagsPrice;
    const cartPriceWithPureDiscounts = (checkOrderPrice > 0 ? checkOrderPrice : cart.itemsFinalPrice) + bagsPrice;
    const cartPriceWithPureDiscountsBeforeCheckOrderUpdate = cart.itemsFinalPrice + bagsPrice;
    const pureDiscount = isSuperMarket ? Math.max(cartStartingPrice - cartPriceWithPureDiscounts, 0) : 0;
    const totalEnvFee = cart.envFee ?? 0;
    const deliveryFee = getShopDeliveryFee(shop, deliveryMethod, cartPriceWithPureDiscounts) ?? 0;
    const serviceFee = this.checkoutInfoService.getCheckoutInfo().serviceFee ?? 0;
    const additionsNotCoveredByDiscounts = tip + donation + deliveryFee + serviceFee;
    /** the order of discounts only matters when there is a percentage coupon being applied,
     * in that case the coupon percentage discount should be the last in order.
     * For that reason we need to subtract all the other discounts first. */
    const priceCoveredByCoupon = Math.max(
      cartPriceWithPureDiscounts - pointsDiscount - smLoyaltyDiscount - bankLoyaltyDiscount,
      0
    );
    const couponDiscount = getCouponDiscount(coupon, priceCoveredByCoupon);
    const totalDiscount = pureDiscount + pointsDiscount + smLoyaltyDiscount + bankLoyaltyDiscount + couponDiscount;
    const totalPrice = Math.max(cartStartingPrice - totalDiscount, 0) + additionsNotCoveredByDiscounts;
    return {
      cartStartingPrice: cartStartingPrice ?? 0, // includes envFee
      cartPriceWithPureDiscounts: cartPriceWithPureDiscounts ?? 0, // includes check order alterations and bags
      cartPriceWithPureDiscountsBeforeCheckOrderUpdate: cartPriceWithPureDiscountsBeforeCheckOrderUpdate ?? 0, // cartPriceWithPureDiscounts before orders/check does any updates
      priceCoveredByCoupon: priceCoveredByCoupon ?? 0,
      deliveryFee,
      serviceFee: deliveryMethod === 'delivery' ? serviceFee : 0,
      totalEnvFee,
      totalDiscount: Math.min(totalDiscount, cartStartingPrice) ?? 0,
      totalPrice: Math.ceil(totalPrice) ?? 0,
      tip,
      donation
    };
  }

  public getCheckoutPointsVisibility(shop: Shop, cartPrice: number): boolean {
    if (!shop.isEligibleForMyoBoxaki) return false;
    const userRemainingPoints = this.userService.getUser()?.marketPlacePoints?.remainingPoints;
    const boxakiEuroRate = shop.loyaltyRuleStepInEuroForRedemption;
    const boxakiPointsRate = shop.loyaltyPointsToRedeemRate;
    const isUserEligible = userRemainingPoints >= boxakiPointsRate;
    const isOrderEligible = cartPrice >= boxakiEuroRate * 100;
    return isUserEligible && isOrderEligible;
  }

  public getSuperMarketLoyaltyCard(): MarketCardDetails {
    const company = this.shopService.getShop().integrator?.company;
    if (!company) return;
    const loyaltyCards = this.userService.getLoayltyCards();
    if (!loyaltyCards?.length) return;
    return getLoyaltyCardForIntegrator(loyaltyCards, company);
  }

  public onLowProbabilitySuccessfulOrder(): void {
    this.exploreService.fetchExploreCouponData().subscribe((exploreData) => {
      const { userSegments } = exploreData;
      this.exploreService.clearCoupon();
      this.exploreService.clearTimer();
      this.userService.setUser({ ...this.userService.getUser(), segments: userSegments });
    });
  }

  public problematicAddressFlow(): void {
    this.dialogService
      .openInfoDialog(PROBLEMATIC_ADDRESS_DIALOG_DATA)
      .afterClosed()
      .subscribe(() => this.addressesService.initiateAddFullAddressDialogFlow$().subscribe());
  }

  public isHappyHourEligible(): boolean {
    if (this.isNewUsersEligible()) return false;

    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    if (!promoCampaigns?.length) return false;

    const happyHourPromo = promoCampaigns.find((promoCampaign) => promoCampaign.name === 'happy_hour');
    if (!happyHourPromo) return false;

    return checkMerchantSponsoredCampaignEligibility(this.shopService.getShop(), happyHourPromo);
  }

  public isNewUsersEligible(): boolean {
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    if (!promoCampaigns?.length) return false;

    const promoCampaign = promoCampaigns.find((promoCampaign) => promoCampaign.name === 'new_users');
    if (!promoCampaign) return false;

    const shopIsEligible = checkMerchantSponsoredCampaignEligibility(this.shopService.getShop(), promoCampaign);
    if (!shopIsEligible) return false;

    const exploreCouponExists = isLowOrderProbabilityUserWithCoupon(this.userService.getUser());
    if (!exploreCouponExists) return true;

    return this.checkoutCouponsService.isExploreCouponSelected();
  }

  public getOrderPromoCampaigns(): string[] {
    const promoCampaignNames: string[] = [];
    // todo we are missing logic for campaigns that are triggeredByClient: true and cartSuggestion.enabled === false
    const reminderCampaignsAddedInCheckout = this.cartSuggestionService.getEligibleReminderCampaignsTriggeredByClient();
    if (reminderCampaignsAddedInCheckout) promoCampaignNames.push(...reminderCampaignsAddedInCheckout);
    if (this.isNewUsersEligible()) promoCampaignNames.push('new_users');
    if (this.isHappyHourEligible()) promoCampaignNames.push('happy_hour');
    return promoCampaignNames;
  }

  public handleTakeAwayPaymentSelection(): void {
    const currentPaymentType = this.paymentTypesService.getPaymentType();
    if (currentPaymentType?.type !== 'card') {
      this.paymentTypesService.setFirstAvailableCardPayment();
      this.showTakeAwayAutoPaymentSelectionInfo();
    }
    this.checkoutStateService.setTip(0);
  }

  public setPointsAndMultiplierSum(): void {
    const campaigns = this.campaignEligibilityService.getConsumedPromoCampaigns();
    this.checkoutStateService.setMultiplierSum(getMultiplierSum(campaigns));

    const coupon = this.checkoutCouponsService.getCoupon();
    const payment = this.paymentTypesService.getPaymentType();
    const extraPoints = (coupon?.loyaltyPoints ?? 0) + (payment?.firstOrderCardPoints ?? 0);
    this.checkoutStateService.setPointsSum(getPointsSum(campaigns) + extraPoints);
  }

  public triggerPurchaseAnalyticsEvent(order: Order): void {
    const purchaseEvent = this.purchaseEventService.getPurchaseEvent(this.shopService.getShop()._id);
    if (!purchaseEvent) return;

    const options = {
      order: order,
      cart: this.cartService.getCart(),
      shop: this.shopService.getShop(),
      purchaseEvent: purchaseEvent,
      categories: this.shopService.getShopItemsFromMemory().categories,
      deliveryFee: this.checkoutStateService.getCheckoutOrderPreview().deliveryFee
    };

    if (!order.user.hasOrdered) {
      this.analyticsService.addGACustomEvent('first_ecommerce_purchase', {
        value: String(this.userService.getUser().guid)
      });
      this.analyticsService.addMPCustomEvent('FirstEcommercePurchase', {
        value: String(this.userService.getUser().guid)
      });
    }

    const gaConfig = getPurchaseEventGAConfig(options);
    this.analyticsService.addGAEcommerceEvent('purchase', gaConfig);

    const mpConfig = getPurchaseEventMPConfig(order, this.shopService.getShop().name);
    this.analyticsService.addMPEvent('Purchase', mpConfig);
  }

  public triggerCommentAnalyticsEvent(hasComment: boolean): void {
    const gaConfig = { comments_option: hasComment ? 'on' : 'off' } as GAClickCommentsCheckOutConfig;
    this.analyticsService.addGACustomEvent('click_comments_checkout', gaConfig);
  }

  public checkShopMinimumPriceAfterTakeAwaySelection(deliveryMethod: DeliveryMethod): void {
    const itemsPrice =
      this.checkoutStateService.getCartPriceWithPureDiscounts() +
      this.checkoutStateService.getCheckoutOrderPreview().totalEnvFee;
    if (itemsPriceIsOverMinimum(this.shopService.getShop(), deliveryMethod, itemsPrice)) return;
    this.showInsufficientMinimumPriceDialog(this.shopService.getShop(), deliveryMethod)
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (!data?.accepted) {
          return void this.router.navigate([
            '/delivery',
            this.shopService.getShop().locationKey,
            this.shopService.getShop().vanity_url
          ]);
        }
        // user has approved to switch delivery method
        // we do not check for the minimum price again for the new delivery method. Check this with Alex
        const updatedDeliveryMethod: DeliveryMethod = deliveryMethod === 'delivery' ? 'takeAway' : 'delivery';
        this.deliveryMethodService.saveDeliveryMethod(updatedDeliveryMethod, this.shopService.getShop());
      });
  }

  public initializeCheckoutPayments(): void {
    const cards = this.paymentGatewayService.getPaymentGatewayInfo()?.cardList ?? [];
    const paymentTypeOptions: PaymentTypesOptions = {
      cards,
      shop: this.shopService.getShop(),
      cartPrice: this.checkoutStateService.getCheckoutOrderPreview().totalPrice,
      deliveryMethod: this.deliveryMethodService.getDeliveryMethod()
    };

    this.paymentTypesService.initializePaymentTypes(paymentTypeOptions);
  }

  public openSynergyDialog(
    shop: Shop,
    synergyBanners: PromoCampaignBanner[]
  ): MatDialogRef<CheckoutSynergyDialogComponent> {
    const dialogConfig: MatDialogConfig = {
      closeOnNavigation: true,
      panelClass: ['box-dialog-fit-content', 'no-background', 'borderless'],
      data: { shop, synergyBanners }
    };
    return this.dialogService.openDialog(CheckoutSynergyDialogComponent, dialogConfig);
  }

  public handleSynergyDialog(): void {
    /* This check is to prevent the Synergy dialog showing after a user redirection from the Payment page.
    We are removing this after the In App Payment refactor (in iFrame payment) from the Cosmote Payments Team. */
    const previousUrl = this.routerHistoryService.getPreviousUrl();
    const afterPayment = previousUrl.startsWith('/payment');
    if (afterPayment) return;
    const notTriggeredByClientBanners = this.getCheckoutSynergyBanners();
    if (!notTriggeredByClientBanners?.length) return;
    this.openSynergyDialog(this.shopService.getShop(), notTriggeredByClientBanners)
      .afterOpened()
      .subscribe(() => {
        const currentNames = this.checkoutStateService.getPromoCampaignsShowed();
        const newNames = notTriggeredByClientBanners.map((banner) => banner.campaignName);
        this.checkoutStateService.setPromoCampaignsShowed([...currentNames, ...newNames]);
      });
  }

  public earnPointsAnalyticsEvent(order: Order): void {
    /* This block of code ensures that if the shop has Auto Accept, the Event will get registered
    before going to Order Progress Page */
    const pointsEarned = order.marketPlacePoints?.collected;
    if (!pointsEarned || order.shopResponse.status !== 'ACCEPTED') return;
    this.analyticsService.addGACustomEvent('earnPoint', { pointsAmount: pointsEarned, screen: 'Checkout Page' });
  }

  public handleOrderLoyaltyStatus(order: Order): void {
    const int = interval(5000);
    this.loaderService.setState(true);
    const intSub = int.subscribe(() =>
      this.ordersService.fetchOrder(order.friendlyId).subscribe({
        next: (orderResponse) => {
          if (orderResponse.loyaltyCollectionStatus === 'PENDING') return;
          this.loaderService.setState(false);
          intSub?.unsubscribe();
          this.ordersService.setOrder(orderResponse);
        },
        error: (error: APIError) => {
          this.dialogService.openErrorDialog(error);
          this.loaderService.setState(false);
          intSub?.unsubscribe();
        }
      })
    );
  }

  public completeAcceptedOrderFlow(order: Order): void {
    this.earnPointsAnalyticsEvent(order);
    this.userService.addPoints(order.marketPlacePoints.collected);
    this.shopService.clearMenuItemsQuantities();
    this.cartService.clearCartAndShop();
    void this.router.navigate(['/checkout', 'order-status'], { queryParams: { friendlyId: order.friendlyId } });
  }

  public completeDeclinedOrderFlow(order: Order): void {
    this.shopService.clearMenuItemsQuantities();
    this.cartService.clearCartAndShop();
    void this.router.navigate(['/checkout', 'order-status'], { queryParams: { friendlyId: order.friendlyId } });
  }

  public checkMarketOrder(): Observable<MarketOrderCheck> {
    const { offers: cartOffers, products: cartProducts } = this.cartService.getCart();
    if (!cartOffers?.length && !cartProducts?.length) return of(null);
    const loyaltyCard = this.getSuperMarketLoyaltyCard();
    const userAddress: Address = this.addressesService.getAddress();
    const options: MarketOrderCheckOptions = {
      products: getProductsForOrder(cartProducts).map((p) => ({
        _id: p._id,
        quantity: p.quantity
      })) as Partial<Product[]>,
      offers: getOffersForOrder(cartOffers).map((ο) => ({
        _id: ο._id,
        quantity: ο.quantity
      })) as Partial<Offer[]>,
      shopId: this.shopService.getShop()._id,
      cardId: loyaltyCard?.cardId,
      customerToken: loyaltyCard?.customerToken,
      latitude: userAddress?.latitude,
      longitude: userAddress?.longitude,
      supermarket: this.shopService.getShop().integrator?.company
    };
    this.loaderService.setState(true);
    return this.marketLoyaltyService.checkOrder(options).pipe(
      tap((marketOrderCheck) => this.checkoutStateService.setMarketOrderCheck(marketOrderCheck)),
      finalize(() => this.loaderService.setState(false)),
      catchError((error: APIError) => {
        this.dialogService.openErrorDialog(error);
        return of(null);
      })
    );
  }

  public handleOrderResponse(order: Order): void {
    if (!order) return;
    if (isOrderRejected(order)) {
      this.completeDeclinedOrderFlow(order);
    }

    const shopStatus = order.shopResponse.status;
    if (shopStatus === 'PAYMENT_NOT_CONFIRMED') return; // user is informed in payment resolver
    if (shopStatus === 'PAYMENT_PENDING') return this.handlePayment(order);

    if (isOrderPending(order)) {
      this.cartService.clearCartAndShop();
      return void this.router.navigate(['/checkout', 'order-status'], {
        queryParams: { friendlyId: order.friendlyId }
      });
    }

    if (isOrderAccepted(order)) {
      const lowOrderProbability = isLowOrderProbabilityUser(this.userService.getUser());
      if (lowOrderProbability) this.onLowProbabilitySuccessfulOrder();
      this.completeAcceptedOrderFlow(order);
    }

    this.handleOrderLoyaltyStatus(order);
  }

  public handlePayment(order: Order): void {
    this.loaderService.setState(true);

    this.paymentGatewayService
      .getPaymentToken(order._id, this.shopService.getShop().businessVertical)
      .pipe(
        map((paymentTokenResponse) =>
          getInitiatePaymentOptions(paymentTokenResponse, this.paymentTypesService.getPaymentType(), order._id)
        ),
        switchMap((initiatePaymentOptions) =>
          this.paymentGatewayService
            .initiatePayment(initiatePaymentOptions, this.shopService.getShop().businessVertical)
            .pipe(
              map((initiatePaymentResponse) => ({
                merchantRef: initiatePaymentResponse.merchantRef,
                secToken: initiatePaymentOptions.secToken
              }))
            )
        )
      )
      .subscribe({
        next: ({ merchantRef, secToken }) => {
          const paymentUrl = this.paymentGatewayService.getPaymentGatewayInfo()?.paymentUrls?.paymentGatewayUrl;
          const redirectionURL = `${paymentUrl}?merchantRef=${merchantRef}&secToken=${secToken}`;
          window.location.replace(redirectionURL);
        },
        error: (error: APIError) => {
          this.loaderService.setState(false);
          this.dialogService.openErrorDialog(error);
          this.ordersService.cancelOrder(order._id).subscribe({
            next: () => this.ordersService.clearOrder(),
            error: (cancelError: APIError) => this.dialogService.openErrorDialog(cancelError)
          });

          /* This block of code should be replaced when the BE fixes the gap we have with the
          dummy coupons and the WU Synergy. That should not take more than a month. If it does,
          please contact the Team to fix it asap */
          const dummyCoupon = this.checkoutCouponsService.getCoupon();
          if (isCouponDummy(dummyCoupon)) {
            this.checkoutCouponsService.updateCheckoutCoupons().subscribe({
              next: () => {
                const coupons = this.checkoutCouponsService.getCoupons();
                const coupon = coupons.find((coupon) => coupon.synergy === dummyCoupon.synergy);
                if (coupon) this.checkoutCouponsService.setCoupon(coupon);
              },
              error: (error: APIError) => {
                this.sentryService.captureException(error, {
                  domain: 'Checkout',
                  domainDetails: 'Dummy Coupon Reselection',
                  severity: 'error'
                });
              }
            });
          }
        }
      });
  }

  public getHappyHourExpiration$(): Observable<null> {
    const campaigns = this.promoCampaignsService.getPromoCampaigns();
    return this.campaignTimerService.whenCampaignIsExpired$('happy_hour').pipe(
      switchMap(() =>
        this.dialogService
          .openInfoDialog({
            title: 'box_hour_ended',
            messages: ['box_hour_ended_for_today', getHappyHourExpirationMessage(campaigns, translate) ?? '']
          })
          .afterClosed()
      ),
      mapTo(null)
    );
  }

  public shouldShowCheckOrderDifferenceDialog(): boolean {
    /* positive when we have a discount, negative when the price is raised */
    const diff =
      this.checkoutStateService.getCheckoutOrderPreview()?.cartPriceWithPureDiscountsBeforeCheckOrderUpdate -
      this.checkoutStateService.getMarketOrderCheck()?.orderPrice;
    return diff && diff < -10;
  }

  public getBagsPrice(): number {
    const bagsState = this.checkoutStateService.getBagsState();
    return bagsState?.checked && bagsState?.price > 0 ? bagsState.price : 0;
  }

  public updateCheckoutOrderPreview(): void {
    const checkOrderDiscount = this.checkoutStateService.getMarketOrderCheck()?.priceDiscount ?? 0;

    const options: CheckoutOrderPreviewOptions = {
      coupon: this.checkoutCouponsService.getCoupon(),
      pointsDiscount: this.checkoutStateService.getPointsDiscount() ?? 0,
      smLoyaltyDiscount: this.checkoutStateService.getMarketPointsChecked() ? checkOrderDiscount : 0,
      bankLoyaltyDiscount: this.checkoutStateService.getBankLoyaltyRedemption()?.discount ?? 0,
      checkOrderPrice: this.checkoutStateService.getMarketOrderCheck()?.orderPrice,
      bagsPrice: this.getBagsPrice(),
      tip: this.checkoutStateService.getTip(),
      donation: this.checkoutStateService.getDonation() ?? 0
    };

    const checkoutOrderPreview = this.generateCheckoutOrderPreview(options);
    this.checkoutStateService.setCheckoutOrderPreview(checkoutOrderPreview);
  }

  public openCheckOrderPriceConfirmDialog(): MatDialogRef<BoxConfirmDialogComponent> {
    const pointsDiscount = this.checkoutStateService.getPointsDiscount() ?? 0;
    const checkOrderPrice = this.checkoutStateService.getMarketOrderCheck()?.orderPrice;
    const checkOrderDiscount = this.checkoutStateService.getMarketOrderCheck()?.priceDiscount ?? 0;
    const smLoyaltyDiscount = this.checkoutStateService.getMarketPointsChecked() ? checkOrderDiscount : 0;
    const coupon = this.checkoutCouponsService.getCoupon();
    const bagsPrice = this.getBagsPrice();
    const priceBeforeCouponDiscount = checkOrderPrice + bagsPrice - pointsDiscount - smLoyaltyDiscount;
    const couponDiscount = getCouponDiscount(coupon, priceBeforeCouponDiscount);
    const totalDiscount = pointsDiscount + smLoyaltyDiscount + couponDiscount;
    const tip = this.checkoutStateService.getTip();
    const donation = this.checkoutStateService.getDonation();
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const deliveryFee = getShopDeliveryFee(this.shopService.getShop(), deliveryMethod, checkOrderPrice);
    const totalAdditions = deliveryFee + tip + donation + bagsPrice;
    const totalPrice = Math.max(0, checkOrderPrice + totalAdditions - totalDiscount);

    const currencyCode = this.currencyService.getCurrencyCode();
    const priceText = currencyFormat(totalPrice, { currencyCode });
    const translatedMessage = this.languageService.getTextByKey('the_new_final_price_of_your_order_is', {
      _PRICE_TEXT: priceText
    });
    return this.dialogService.openConfirmDialog({
      title: 'products_refresh',
      messages: [translatedMessage],
      confirmText: 'next_',
      cancelText: 'back_'
    });
  }
}
