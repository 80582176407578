<div class="rewards-preview-overlay">
  <div class="pointer-block"></div>
  <header>
    <figure>
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.06 16.967c-.632.643-7.333 3.729-12.381 4.992a1.337 1.337 0 0 1-1.282-.362 1.39 1.39 0 0 1-.357-1.303C3.283 15.16 6.32 8.349 6.95 7.706l9.11 9.26Z"
          fill="#F0C419"
        />
        <path
          d="M16.056 16.966c.662-.673-.84-3.291-3.355-5.848S7.611 7.034 6.95 7.707c-.662.673.84 3.29 3.356 5.848 2.514 2.557 5.09 4.084 5.751 3.411Z"
          fill="#D25627"
        />
        <path
          d="M5.512 19.118a.684.684 0 0 0 .678-.689c0-.38-.303-.69-.678-.69a.684.684 0 0 0-.678.69c0 .38.304.69.678.69ZM9.827 10.975a.339.339 0 0 1-.313-.213.349.349 0 0 1 .074-.375l2.157-2.193c1.16-1.18 1.308-2.082.48-2.925a1.556 1.556 0 0 0-1.41-.544 1.97 1.97 0 0 0-.988.544.335.335 0 0 1-.475-.004.349.349 0 0 1-.004-.483 2.627 2.627 0 0 1 1.35-.736 2.194 2.194 0 0 1 2.006.736c1.113 1.131.952 2.444-.48 3.899l-2.156 2.193a.336.336 0 0 1-.24.101Z"
          fill="#955BA5"
        />
        <path
          d="M18.696 14.63a.34.34 0 0 1-.313-.212.349.349 0 0 1 .073-.376c.345-.307.543-.752.543-1.218 0-.467-.198-.91-.543-1.219a1.419 1.419 0 0 0-1.441-.489 3.384 3.384 0 0 0-1.435.977l-1.917 1.95a.335.335 0 0 1-.48 0 .349.349 0 0 1 0-.488l1.918-1.95a4.01 4.01 0 0 1 1.741-1.155c.766-.2 1.577.06 2.094.668 1.324 1.346.623 2.778 0 3.412a.333.333 0 0 1-.24.1Z"
          fill="#FF5364"
        />
        <path
          d="M14.382 8.294a.339.339 0 0 1-.313-.212.349.349 0 0 1 .073-.375c.088-.091 2.156-2.253.455-4.416a.349.349 0 0 1-.065-.349.337.337 0 0 1 .593-.08c2.084 2.647-.477 5.307-.504 5.333a.333.333 0 0 1-.24.1Z"
          fill="#F0C419"
        />
        <path
          d="M17.932 9.228c.562 0 1.017-.463 1.017-1.034s-.455-1.034-1.017-1.034c-.562 0-1.017.463-1.017 1.034 0 .57.455 1.034 1.017 1.034Z"
          fill="#FF5364"
        />
        <path
          d="M12.847 3.379a.684.684 0 0 0 .678-.69c0-.38-.303-.689-.678-.689a.684.684 0 0 0-.678.69c0 .38.304.689.678.689Z"
          fill="#3B97D3"
        />
        <path
          d="M20.983 15.786a.684.684 0 0 0 .678-.69c0-.38-.304-.688-.678-.688a.684.684 0 0 0-.678.689c0 .38.303.69.678.69ZM17.932 4.068a.342.342 0 0 1-.34-.345V3.38c0-.19.152-.345.34-.345.187 0 .339.154.339.345v.344c0 .19-.152.345-.34.345ZM18.949 4.757h-.339a.342.342 0 0 1-.339-.344c0-.19.152-.345.339-.345h.339c.187 0 .339.154.339.345 0 .19-.152.344-.339.344ZM17.932 5.791a.342.342 0 0 1-.34-.344v-.345c0-.19.152-.345.34-.345.187 0 .339.155.339.345v.345c0 .19-.152.344-.34.344ZM17.254 4.757h-.339a.342.342 0 0 1-.339-.344c0-.19.152-.345.34-.345h.338c.187 0 .34.154.34.345 0 .19-.153.344-.34.344Z"
          fill="#955BA5"
        />
        <path
          d="M20.644 9.927a.342.342 0 0 1-.34-.345v-.344c0-.19.152-.345.34-.345.187 0 .339.154.339.345v.344c0 .19-.152.345-.34.345ZM21.66 10.616h-.338a.342.342 0 0 1-.34-.344c0-.19.153-.345.34-.345h.339c.187 0 .339.154.339.345 0 .19-.152.344-.34.344ZM20.644 11.65a.342.342 0 0 1-.34-.345v-.344c0-.19.152-.345.34-.345.187 0 .339.155.339.345v.345c0 .19-.152.344-.34.344ZM19.966 10.616h-.339a.342.342 0 0 1-.339-.344c0-.19.152-.345.34-.345h.338c.187 0 .339.154.339.345 0 .19-.152.344-.339.344Z"
          fill="#3B97D3"
        />
        <path
          d="M13.362 18.42a66.34 66.34 0 0 1-3.027 1.302 18.953 18.953 0 0 1-3.386-2.754 18.386 18.386 0 0 1-.99-1.082A18.67 18.67 0 0 1 4.24 13.53c.44-1.13.882-2.185 1.285-3.078.36.71.77 1.39 1.23 2.037.55.781 1.155 1.52 1.81 2.213a19.793 19.793 0 0 0 1.966 1.83c.88.723 1.828 1.356 2.831 1.888Z"
          fill="#FF5364"
        />
        <path
          d="M10.532 16.53a8.846 8.846 0 0 0-.722 2.844 18.94 18.94 0 0 1-1.933-1.517 8.42 8.42 0 0 1 .689-3.157c.094.107.196.21.301.318a19.78 19.78 0 0 0 1.665 1.512Z"
          fill="#955BA5"
        />
        <path
          d="M8.566 14.7a8.42 8.42 0 0 0-.688 3.158 24.038 24.038 0 0 1-.93-.89 18.387 18.387 0 0 1-.989-1.082 8.757 8.757 0 0 1 .797-3.398c.55.781 1.154 1.52 1.81 2.213Z"
          fill="#B49ACA"
        />
        <path
          d="M5.711 21.383c-.695.217-1.38.41-2.034.576a1.336 1.336 0 0 1-1.281-.362 1.39 1.39 0 0 1-.356-1.303c.163-.665.352-1.361.566-2.068a20.342 20.342 0 0 0 3.105 3.157Z"
          fill="#FF5364"
        />
      </svg>
    </figure>
    <h1 class="header-title ods-typography-body-s-bold">{{ 'until_now_you_have_won' | translate }}</h1>
    <button class="header-close-button" mat-icon-button (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <main>
    <rewards-history-earnings></rewards-history-earnings>
  </main>
  <footer>
    <button box-button theme="insignificant" size="small" (click)="onClickCta()">
      {{ 'learn_how_to_win_more' | translate }}
    </button>
  </footer>
</div>
