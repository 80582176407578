import { Component, OnInit, Renderer2, Inject, ChangeDetectionStrategy } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Shop,
  BoxDialogValidationMode,
  SMLoyaltyConfiguration,
  MarketCardConnectDialogData,
  MarketCardConnectDialogConfig,
  MarketCardConnectDialogResponse,
  MarketCartConnectCreateType,
  GetTextByKeyType
} from '@box-types';
import { UserService } from '@box-core/services';
import { MarketCardConnectDialogService } from './market-card-connect-dialog.service';
import { LanguageService } from '@box-core/services/language.service';

const DEFAULT_OPTIONS: MarketCardConnectDialogConfig = { canSearch: true, canCreate: true };

@Component({
  selector: 'market-card-connect-dialog',
  templateUrl: './market-card-connect-dialog.component.html',
  styleUrls: ['./market-card-connect-dialog.component.scss'],
  providers: [MarketCardConnectDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketCardConnectDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  private shop: Shop;
  public validationMode: BoxDialogValidationMode;

  public title: string;
  public shopName: string;
  public loyaltyConfig: SMLoyaltyConfiguration;
  public isPhysical: boolean;

  public config: MarketCardConnectDialogConfig;
  public createButtonText: string;
  public showSeparator: boolean;
  public createMode: MarketCartConnectCreateType;

  public searchFormGroup: FormGroup;
  public findCardTermsHTML: string;
  public saveCardTermsText: string;

  public createFormGroup: FormGroup;
  public firstPersonalDataTermsHTML: string;
  public secondPersonalDataTermsHTML: string;

  public readonly t: GetTextByKeyType; // for the template to use

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<MarketCardConnectDialogComponent>,
    private userService: UserService,
    private marketCardConnectDialogService: MarketCardConnectDialogService,
    private languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) private data: MarketCardConnectDialogData
  ) {
    super(renderer);
    this.t = this.languageService.getTextByKey.bind(this.languageService);
    this.shop = this.data.shop;
    this.createMode = this.shop.isLoyaltyCardMandatoryForOrder ? 'normal' : 'guest';
    this.config = { ...DEFAULT_OPTIONS, ...this.data.config };
  }

  ngOnInit(): void {
    this.validationMode = this.shop.loyaltyCardLoginMethod;
    this.createButtonText = this.marketCardConnectDialogService.getCreateButtonText(this.createMode);
    this.showSeparator = this.config.canSearch && this.config.canCreate;
    const integratorName = this.shop.integrator.company;
    this.loyaltyConfig = this.marketCardConnectDialogService.getSMLoyaltyConfig(integratorName);
    this.isPhysical = this.loyaltyConfig.isPhysical;
    this.shopName = this.loyaltyConfig.smName;
    this.title = this.loyaltyConfig.shortCardName;
    this.findCardTermsHTML = this.marketCardConnectDialogService.getFindCardTermsHTML(this.loyaltyConfig);
    this.saveCardTermsText = this.marketCardConnectDialogService.getSaveCardTermsText(this.loyaltyConfig);
    this.firstPersonalDataTermsHTML = this.marketCardConnectDialogService.getFirstPersonalDataTermsHTML(
      this.loyaltyConfig
    );
    this.secondPersonalDataTermsHTML = this.marketCardConnectDialogService.getSecondPersonalDataTermsHTML(
      this.loyaltyConfig
    );

    // search
    const searchInputControl = new FormControl('', [
      Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
      this.marketCardConnectDialogService.getInputValidator(this.validationMode)
    ]);
    const findCardTermsControl = new FormControl(false);
    findCardTermsControl.setValidators(this.findCardTermsHTML ? Validators.requiredTrue : []); // eslint-disable-line @typescript-eslint/unbound-method
    const saveCardTermsControl = new FormControl(false);
    this.searchFormGroup = new FormGroup({ searchInputControl, findCardTermsControl, saveCardTermsControl });

    // create
    const firstPersonalDataTermsControl = new FormControl(false);
    firstPersonalDataTermsControl.setValidators(this.firstPersonalDataTermsHTML ? Validators.requiredTrue : []); // eslint-disable-line @typescript-eslint/unbound-method
    const secondPersonalDataTermsControl = new FormControl(false);
    secondPersonalDataTermsControl.setValidators(this.secondPersonalDataTermsHTML ? Validators.requiredTrue : []); // eslint-disable-line @typescript-eslint/unbound-method
    this.createFormGroup = new FormGroup({ firstPersonalDataTermsControl, secondPersonalDataTermsControl });
  }

  public onSearch(): void {
    if (this.searchFormGroup.invalid) return;
    const searchInput = this.searchFormGroup.controls['searchInputControl'].value as string;
    // We want to have the save: true default value if we can't find the canSave property on smLoyaltyInfo on configuration file
    const saveCard = this.saveCardTermsText
      ? (this.searchFormGroup.controls['saveCardTermsControl'].value as boolean)
      : true;

    if (this.validationMode === 'PIN') {
      // masoutis
      return this.closeDialog({ search: true, saveCard, validationConfig: { cardNumber: searchInput } });
    }
    if (this.validationMode === 'OTP') {
      // krhtikos
      return this.closeDialog({
        search: true,
        saveCard,
        validationConfig: { cardNumber: searchInput }
      });
    }
    if (this.validationMode === 'EMAIL') {
      // e-fresh
      return this.closeDialog({
        search: true,
        saveCard,
        validationConfig: {
          email: searchInput
        }
      });
    }
  }

  public onCreate(): void {
    if (this.createMode === 'normal') return this.createCard();
    if (this.createMode === 'guest') return this.createGuestCard();
  }

  private createCard(): void {
    if (this.createFormGroup.invalid) return;
    if (this.userService.hasVerifiedMSISDN()) {
      return this.closeDialog({ create: true });
    }

    if (this.validationMode === 'EMAIL') {
      return this.closeDialog({ needsPhoneVerificationWithReason: 'mobile_phone_initialize' });
    } else {
      return this.closeDialog({ needsPhoneVerificationWithReason: 'sm_phone_verification' });
    }
  }

  private createGuestCard(): void {
    if (this.createFormGroup.invalid) return;
    window.localStorage.setItem('Box:kritikosGuestCard', 'true');
    this.closeDialog({ guest: true });
  }

  public closeDialog(data?: MarketCardConnectDialogResponse): void {
    this.dialogRef.close(data);
  }
}
