import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CosmoteIDService, UserService, DialogService } from '@box-core/services';
import { RewardsCardDialogComponent } from '@box-rewards/components';
import { Reward, RewardsCard, APIResponse } from '@box-types';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { normalizeReward, filterGBRewardsBySegment } from '@box/utils';
import { environment } from '@box-env/environment';

@Injectable()
export class RewardsService {
  private referralAuthErrorMessage = 'register_to_box_to_invite_friends';
  private readonly BOX_API: string = environment.application.API_URL;
  private readonly rewardsSource = new BehaviorSubject<Reward[]>([]);
  public readonly rewards$ = this.rewardsSource.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialogService: DialogService,
    private userService: UserService,
    private cosmoteIDService: CosmoteIDService
  ) {}

  public fetchRewards(): Observable<Reward[]> {
    const params: HttpParams = new HttpParams().set('includeSegments', 'true');
    return this.http.get(`${this.BOX_API}/rewards`, { params }).pipe(
      map((response: APIResponse<{ rewards: Reward[] }>) => {
        const rewards = response.payload.rewards;
        return rewards.map((reward) => normalizeReward(reward));
      })
    );
  }

  public setRewards(rewards: Reward[]): void {
    return this.rewardsSource.next(rewards);
  }

  public getRewards(): Reward[] {
    return this.rewardsSource.getValue();
  }

  public getGBRewards(): Reward[] {
    const userSegment = this.userService.getUserMainSegment();
    const rewards = this.rewardsSource.getValue();
    return filterGBRewardsBySegment(rewards, userSegment);
  }

  public handleRewardsCardAction(rewardsCard: RewardsCard): void {
    if (!rewardsCard?.action) return;
    if (rewardsCard.action.type === 'tutorial') {
      if (rewardsCard.type === 'happyHourOrders') return this.showHappyHourDialog();
      return this.showRewardsCardTutorialDialog(rewardsCard);
    }
    if (rewardsCard.action.type === 'redirection') {
      if (!this.userService.isGuest) {
        return void this.router.navigate([rewardsCard.action.routerLink], { queryParams: rewardsCard.action.params });
      }
      if (rewardsCard.type === 'referral') return this.showAuthenticationDialog(this.referralAuthErrorMessage);
    }
  }

  private showRewardsCardTutorialDialog(rewardsCard: RewardsCard): void {
    const dialogConfig: MatDialogConfig = { autoFocus: false, restoreFocus: false, data: { rewardsCard } };
    this.dialogService.openDialog(RewardsCardDialogComponent, dialogConfig);
  }

  private showHappyHourDialog(): void {
    this.dialogService
      .openConfirmDialog({
        title: 'happy_hour_offer_is_out_of_hours',
        messages: ['you_can_find_other_shops_and_order_from_box'],
        confirmText: 'discover_',
        cancelText: 'close_'
      })
      .afterClosed()
      .subscribe((response) => {
        if (response.accepted) void this.router.navigate(['/discover'], { queryParams: { vertical: 'food' } });
      });
  }

  private showAuthenticationDialog(message: string): void {
    this.dialogService
      .openInfoDialog({
        title: 'register_login',
        messages: [message],
        btnText: 'register_login'
      })
      .afterClosed()
      .subscribe(() => this.cosmoteIDService.redirectToCosmoteId());
  }
}
