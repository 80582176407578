import { getGreeklish } from 'greek-utilities';
import { GetTextByKeyType, Offer } from '@box-types';
import { filterItemsBySearchTerms } from '../core';

export { getOfferSearchTerms, decorateOfferWithSearchTerms, filterOffersBySearchTerms };

function getOfferSearchTerms(offer: Offer, translateFn: GetTextByKeyType): string {
  const searchProperties = [];
  if (offer.name) searchProperties.push(offer.name);
  if (offer.description) searchProperties.push(offer.description);
  searchProperties.push(translateFn('offers_'));
  return getGreeklish(searchProperties.join(' ')) as string; //eslint-disable-line  @typescript-eslint/no-unsafe-call
}

function decorateOfferWithSearchTerms(offer: Offer, translateFn: GetTextByKeyType): Offer {
  const searchTerms = getOfferSearchTerms(offer, translateFn);
  return { ...offer, searchTerms };
}

function filterOffersBySearchTerms(offers: Offer[], term: string): Offer[] {
  return filterItemsBySearchTerms(offers, term);
}
