import { Injectable } from '@angular/core';
import { AnalyticsService, ConfigurationService } from '@box-core/services';
import { GAClickTipBannerConfig } from '@box-types';
import { generateCheckoutAmountDescription } from '@box/utils';
import { currencyCode } from '@box-core/services/currency.service';

@Injectable()
export class CheckoutTipService {
  private readonly DEFAULT_TIP_AMOUNTS = [75, 100, 200];
  private readonly DEFAULT_TIP_MINIMUM_AMOUNT = 20;
  private readonly DEFAULT_TIP_MAXIMUM_AMOUNT = 2000;
  private readonly DEFAULT_TIP_DESCRIPTION = 'deliveryman_tip_selection_range';

  constructor(private configurationService: ConfigurationService, private analyticsService: AnalyticsService) {}

  public getMinimumAmount(): number {
    const configuration = this.configurationService.getConfiguration();
    return configuration?.tip?.minimumAmount ?? this.DEFAULT_TIP_MINIMUM_AMOUNT;
  }

  public getMaximumAmount(): number {
    const configuration = this.configurationService.getConfiguration();
    return configuration?.tip?.maximumAmount ?? this.DEFAULT_TIP_MAXIMUM_AMOUNT;
  }

  public getDescription(): string {
    const configuration = this.configurationService.getConfiguration();
    if (!configuration?.tip?.descriptionTemplate) return this.DEFAULT_TIP_DESCRIPTION;
    const tipConfiguration = {
      minimumAmount: this.DEFAULT_TIP_MINIMUM_AMOUNT,
      maximumAmount: this.DEFAULT_TIP_MAXIMUM_AMOUNT,
      amounts: this.DEFAULT_TIP_AMOUNTS,
      ...configuration.tip
    };
    return generateCheckoutAmountDescription(tipConfiguration, currencyCode);
  }

  public getAmounts(): number[] {
    const configuration = this.configurationService.getConfiguration();
    if (!configuration?.tip?.amounts?.length) return [0, ...this.DEFAULT_TIP_AMOUNTS];
    const configurationAmounts = configuration.tip.amounts.slice(0, 3);
    return [0, ...configurationAmounts];
  }

  public triggerAnalyticsEvent(tip: number, formattedTip: string): void {
    const tip_option = tip > 0 ? 'on' : 'off';
    const gaConfig = { tip_option, tip_amount: formattedTip } as GAClickTipBannerConfig;
    this.analyticsService.addGACustomEvent('click_tip_banner', gaConfig);
  }
}
