<ng-container *ngIf="productPromo">
  <div class="product-myo-details-synergy">
    <figure class="product-myo-details-synergy-image">
      <img [src]="productPromo.detailsImage" alt="productPromo.name" />
    </figure>
    <div class="product-myo-details-synergy-text ods-typography-body-s-regular">{{ productPromo.detailsText }}</div>
  </div>
  <div class="product-myo-details-divider box-divider"></div>
</ng-container>
<div class="product-myo-details-wrapper">
  <div class="product-myo-details-info">
    <div class="product-myo-details-name-and-badge">
      <promo-badge *ngIf="badgeOptions" [options]="badgeOptions"></promo-badge>
      <div class="product-myo-details-name">{{ productName }}</div>
    </div>
    <div class="product-myo-details-content">
      <span *ngIf="productDescription" class="product-myo-details-description">{{ productDescription }}</span>
      <span *ngIf="!isSuperMarket && productLimits" class="product-myo-details-limits">{{ productLimits }}</span>
      <span *ngIf="envFee > 0" class="product-myo-details-env-fee">
        {{ 'the_product_includes_env_fee' | translate }} {{ envFee | currencyPipe }}
      </span>
      <product-description-badges
        *ngIf="productTags || productBrand"
        [brand]="productBrand"
        [tags]="productTags"
      ></product-description-badges>

      <div *ngIf="showPrice" class="product-myo-details-price-container">
        <div class="product-myo-details-price">{{ productInstancePrice | currencyPipe }}</div>
        <div *ngIf="productBeginPrice > 0" class="product-myo-details-starting-price">
          {{ productBeginPrice | currencyPipe }}
        </div>
      </div>
    </div>
  </div>
  <div class="product-myo-details-actions">
    <div class="product-myo-details-uom-step">{{ stepToUomDescription }}</div>
    <box-quantity
      *ngIf="showQuantity"
      [subtraction]="productInstanceQuantity > 1"
      [quantity]="productInstanceQuantity"
      (add)="onProductAdd()"
      (remove)="onProductRemove()"
    ></box-quantity>
  </div>
</div>
