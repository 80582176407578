<div class="checkout-invoice-wrapper">
  <div class="checkout-invoice-header">
    <h4 class="checkout-invoice-title">{{ 'invoice_issue' | translate }}</h4>
    <div class="checkout-invoice-slide-container">
      <mat-slide-toggle
        color="primary"
        class="checkout-invoice-slide"
        [(ngModel)]="invoiceChecked"
        (change)="onModeChange()"
      ></mat-slide-toggle>
    </div>
  </div>
  <div *ngIf="invoiceChecked && invoiceString" class="checkout-invoice-description hoverable" (click)="onEdit()">
    <span>{{ invoiceString }}</span>
  </div>
</div>
