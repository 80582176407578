import { Injectable } from '@angular/core';
import { TimeslotsService, ShopService } from '@box-core/services';
import {
  timeslotToText,
  timeslotsExactMatch,
  getNextTimeslot,
  getOnlyFutureTimeslotsStarting,
  getTimeslotsStatus
} from '@box/utils';
import { Timeslot } from '@box-types';
import { TimeslotsDialogsService } from '@box-core/services/timeslots-dialogs.service';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class CheckoutTimeslotService {
  constructor(
    private shopService: ShopService,
    private timeslotsService: TimeslotsService,
    private timeslotsDialogsService: TimeslotsDialogsService,
    private languageService: LanguageService
  ) {}

  public generateTimeslotText(): string {
    const isSuperMarket = this.shopService.getShop().isSuperMarket;
    const timeslot = this.timeslotsService.getTimeslot();
    const timeslots = this.timeslotsService.getTimeslots();
    if (!isSuperMarket) return timeslotToText(timeslot);
    if (!timeslots?.length) return 'no_delivery_times_available';

    const nextAvailableTimeslot = getNextTimeslot(timeslots);
    if (timeslotsExactMatch(timeslot, nextAvailableTimeslot)) {
      return this.languageService.getTextByKey('asap_') + ' - ' + timeslotToText(timeslot);
    }

    return timeslotToText(timeslot);
  }

  public setFutureTimeslots(beginDate: Timeslot): void {
    const timeslots = this.timeslotsService.getTimeslots();
    const futureTimeslots = getOnlyFutureTimeslotsStarting(beginDate, timeslots);
    this.timeslotsService.setTimeslots(futureTimeslots);
  }

  public getTimeslotMatIcon(): string {
    const timeslot = this.timeslotsService.getTimeslot();
    return timeslot ? 'today' : 'schedule';
  }

  public checkTimeslotAvailability(): void {
    const shop = this.shopService.getShop();
    const timeslot = this.timeslotsService.getTimeslot();
    const timeslotStatus = getTimeslotsStatus(shop, timeslot);
    switch (timeslotStatus.code) {
      case 'NO_TIMESLOTS':
        this.timeslotsDialogsService.showCheckoutNoTimeslotSupportDialog(shop);
        break;
      case 'TIMESLOT_INCLUDED':
        this.timeslotsDialogsService.showTimeslotIncludedDialog(shop, timeslotStatus.nextAvailableTimeslot);
        break;
      case 'FUTURE_TIMESLOT_AVAILABLE':
        this.timeslotsDialogsService.showFutureTimeslotAvailableDialog(shop, timeslotStatus.nextAvailableTimeslot);
        break;
      case 'TIMESLOTS_UNAVAILABLE':
        this.timeslotsDialogsService.showCheckoutTimeslotUnavailabilityDialog(shop);
    }
  }
}
