import { Shop, Tag } from '@box-types';

export { shopMatchesTagId, shopMatchesTag };

function shopMatchesTagId(shop: Shop, tagId: string): boolean {
  if (!shop || !tagId) return false;
  const shopTagIds = shop.tagIds?.map((shopTagId) => shopTagId.tagId) ?? [];
  const shopTagIdsByMerchant = shop.tagsByMerchant?.map((tagId) => tagId._id) ?? [];
  return [...shopTagIds, ...shopTagIdsByMerchant].includes(tagId);
}
function shopMatchesTag(shop: Shop, tag: Tag): boolean {
  return shopMatchesTagId(shop, tag._id);
}
