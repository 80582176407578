import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DialogService, CosmoteIDService } from '@box-core/services';

@Injectable({ providedIn: 'root' })
export class RedirectResolver implements Resolve<any> {
  constructor(
    private cosmoteIDService: CosmoteIDService,
    private router: Router,
    private dialogService: DialogService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const failed = route.queryParams.failed as boolean;
    const returnURL: string = this.cosmoteIDService.getReturnUrl();
    void this.router.navigateByUrl(returnURL);
    if (failed) {
      this.dialogService.openInfoDialog({
        title: 'BOX',
        messages: ['something_went_wrong_when_connecting_to_your_cosmote_id'],
        btnText: 'ok_'
      });
    }
    return of(undefined);
  }
}
