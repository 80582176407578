<div class="cart-offer-wrapper">
  <span *ngIf="inactive" class="cart-offer-inactive-text ods-typography-body-s-bold">{{ inactiveText }}</span>
  <div class="cart-offer-header">
    <promo-badge class="cart-offer-promo-badge" *ngIf="offer.badgeOptions" [options]="offer.badgeOptions"></promo-badge>
    <span class="cart-offer-name">{{ offer.name }}</span>
  </div>
  <div class="cart-offer-instances">
    <cart-offer-instance
      *ngFor="let instance of offer.cartInstances"
      [instance]="instance"
      [disabled]="disabled"
      [inactive]="inactive"
      [class.hoverable]="!disabled"
      (add)="onAdd($event)"
      (remove)="onRemove($event)"
      (click)="onEdit(instance)"
    ></cart-offer-instance>
  </div>
  <span *ngIf="inactive" class="cart-offer-inactive-info-text">{{ 'offer_cost_is_excluded' | translate }}</span>
</div>
