import { Shop, ShopOperatingStateType, DiscoverSearchItems, GetTextByKeyType } from '@box-types';
import { getShopCardFutureOpenText, isShopInWorkingHours } from './timetable.utils';

function getShopClosedText(shop: Shop, translateFn: GetTextByKeyType): string {
  switch (shop.operatingState) {
    case 'CLOSED':
      return 'closed_';
    case 'OUTSIDE_TIMETABLE':
      return getShopCardFutureOpenText(shop, translateFn) ?? 'closed_';
    case 'TEMPORARILY_CLOSED':
      return 'temporarily_closed';
  }
}

function getShopOperatingState(shop: Shop): ShopOperatingStateType {
  if (!shop.available) {
    if (shop.monitoringInfo?.revertSwitchBackOn > 0) return 'TEMPORARILY_CLOSED';
    return 'CLOSED';
  }
  /* We are skipping the check for the timetable if the shop is a Super Market with Timeslots
  that covers the case of instant SMs that have ASAP delivery but no scheduled order. The SMs
  that have Timeslots, are handled like a 24h shop */
  const isSMWithTimeslots = shop.isSuperMarket && shop.hasTimeSlots;
  if (!isSMWithTimeslots && !isShopInWorkingHours(shop)) return 'OUTSIDE_TIMETABLE';
  if (!shop.merchantConnected) return 'TEMPORARILY_CLOSED';
  return 'OPEN';
}

function sortShopsBasedOnOperatingState(shops: Shop[]): Shop[] {
  const openShops = shops.filter((s) => s.operatingState === 'OPEN');
  const outShops = shops.filter((s) => s.operatingState === 'OUTSIDE_TIMETABLE');
  const tempShops = shops.filter((s) => s.operatingState === 'TEMPORARILY_CLOSED');
  const closedShops = shops.filter((s) => s.operatingState === 'CLOSED');
  return [...openShops, ...outShops, ...tempShops, ...closedShops];
}

function sortSearchResultsByOperatingState<T>(searchResults: DiscoverSearchItems<T>[]): DiscoverSearchItems<T>[] {
  const openShopResults = searchResults.filter((r) => r.shop.operatingState === 'OPEN');
  const tempShopResults = searchResults.filter((r) => r.shop.operatingState === 'TEMPORARILY_CLOSED');
  const outShopResults = searchResults.filter((r) => r.shop.operatingState === 'OUTSIDE_TIMETABLE');
  const closedShopResults = searchResults.filter((r) => r.shop.operatingState === 'CLOSED');
  return [...openShopResults, ...tempShopResults, ...outShopResults, ...closedShopResults];
}

export { getShopClosedText, getShopOperatingState, sortShopsBasedOnOperatingState, sortSearchResultsByOperatingState };
