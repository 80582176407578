<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="create_new_card" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="market-card-create-dialog-content" [formGroup]="createFormGroup">
      <div class="market-card-create-dialog-image-container">
        <figure class="market-card-create-dialog-image">
          <img [src]="logo" [alt]="logoAlt" />
        </figure>
      </div>

      <ng-container *ngIf="validationMode === 'EMAIL'; else pinotp">
        <p class="market-card-create-dialog-text ods-typography-body-s-regular">
          {{ 'enter_the_email_and_create_new_card'| translate : { _SHOP_NAME: shopName, _CARD_NAME: cardName } }}
        </p>
      </ng-container>
      <ng-template #pinotp>
        <p class="market-card-create-dialog-text ods-typography-body-s-regular">
          {{ 'enter_the_mobile_phone_and_create_new_card'| translate : { _SHOP_NAME: shopName, _CARD_NAME: cardName } }}
        </p>
      </ng-template>

      <ng-container *ngIf="validationMode === 'EMAIL'; else pinotpinput">
        <mat-form-field class="market-card-create-dialog-field">
          <mat-label>e-mail</mat-label>
          <input
            #mobileNumber
            matInput
            autocomplete="off"
            name="mobileNumber"
            (keydown.enter)="onCreate()"
            formControlName="inputControl"
          />
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.required"
            >{{ 'email_field_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.pattern">{{
            'invalid_email' | translate
          }}</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-template #pinotpinput>
        <mat-form-field class="market-card-create-dialog-field">
          <mat-label>{{ 'mobile_phone' | translate }}</mat-label>
          <input
            #mobileNumber
            matInput
            autocomplete="off"
            name="mobileNumber"
            (keydown.enter)="onCreate()"
            formControlName="inputControl"
          />
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.required"
            >{{ 'contact_telephone_required' | translate }}
          </mat-error>
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.invalidPhone"
            >{{ 'contact_telephone_is_invalid' | translate }}
          </mat-error>
        </mat-form-field>
      </ng-template>

      <mat-checkbox
        *ngIf="saveCardTermsText"
        class="market-card-create-dialog-checkbox"
        color="primary"
        formControlName="saveCardTermsControl"
      >
        <span class="market-card-create-dialog-checkbox-content ods-typography-body-s-regular">{{
          saveCardTermsText
        }}</span>
      </mat-checkbox>

      <button
        box-button
        theme="primary"
        class="market-card-create-dialog-button"
        [disabled]="createFormGroup.invalid"
        (click)="onCreate()"
      >
        {{ 'next_' | translate }}
      </button>
    </div>
  </main>
</div>
