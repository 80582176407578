import { Category, Product, ProductsByCategory, SelectedCategory, Shop, Offer } from '@box-types';
import { getOfferMaxItemsPerOrder } from '../offers';
import { appConfig } from '@box-configs/app.config';
import { generateOffersSubCategories } from '../market.utils';
import orderBy from 'lodash-es/orderBy';
import { getShopProductBuyLimit } from './shop.utils';

export {
  getFrequentProducts,
  getPopularProducts,
  getSuggestedProducts,
  filterDisplayableProducts,
  populateOffersCategoryWithChildren,
  clearCategoryFromEmptyChildren,
  getSelectedCategoryById,
  sortCategories,
  getShopMenuProductMaxItemsPerOrder,
  decorateProductWithMaxItemsQuantity,
  getShopMenuOfferMaxItemsPerOrder,
  decorateOfferWithMaxItemsQuantity
};

function getFrequentProducts(products: Product[], usersFrequentPlates: string[]): Product[] {
  if (!products?.length || !usersFrequentPlates?.length) return [];
  const MAXIMUM_FREQUENT_PRODUCTS = appConfig.shops.menu.MAXIMUM_FREQUENT_PRODUCTS;
  return products.filter((product) => usersFrequentPlates.includes(product._id)).slice(0, MAXIMUM_FREQUENT_PRODUCTS);
}

function getPopularProducts(products: Product[]): Product[] {
  if (!products?.length) return [];
  const popularProducts = products.filter((product) => product.plateScore);
  const MAXIMUM_POPULAR_PRODUCTS = appConfig.shops.menu.MAXIMUM_POPULAR_PRODUCTS;
  return popularProducts.slice(0, MAXIMUM_POPULAR_PRODUCTS);
}

function getSuggestedProducts(products: Product[], usersFrequentPlates?: string[]): Product[] {
  if (!products?.length) return [];
  const frequentProducts = getFrequentProducts(products, usersFrequentPlates);
  const frequentProductsIDs = frequentProducts.map((product) => product._id);
  const remainingProducts = products.filter((product) => !frequentProductsIDs.includes(product._id));
  const popularProducts = getPopularProducts(remainingProducts);
  const MAXIMUM_SUGGESTED_PRODUCTS = appConfig.shops.menu.MAXIMUM_SUGGESTED_PRODUCTS;
  return [...frequentProducts, ...popularProducts].slice(0, MAXIMUM_SUGGESTED_PRODUCTS);
}

function filterDisplayableProducts(
  products: Product[],
  categories: Category[],
  ignoreCategoryMatching = false
): Product[] {
  if (!products?.length) return [];
  const displayableProducts = products.filter((p) => p.displayable && Boolean(p.category));
  if (ignoreCategoryMatching) return displayableProducts;
  if (!categories?.length) return [];
  const enabledCategoriesIDs = categories
    .flatMap((category) => [category, ...(category.children || [])])
    .filter((category) => category.enabled)
    .map((category) => category._id);
  return displayableProducts.filter((p) => enabledCategoriesIDs.includes(p.category._id));
}

function sortCategories(categories: Category[]): Category[] {
  if (!categories?.length) return [];
  const categoriesSortedParents = orderBy(categories, 'categoryIndex', 'asc');
  return categoriesSortedParents.map((category) => {
    if (!category?.children?.length) return category;
    category.children = orderBy(category.children, 'categoryIndex', 'asc');
    return category;
  });
}

function populateOffersCategoryWithChildren(
  categories: Category[],
  products: Product[],
  offers: Offer[],
  shop: Shop
): Category[] {
  if (!categories?.length) return [];
  const offersSubCategories = generateOffersSubCategories(products, offers, categories, shop);
  return categories.map((cat) => {
    if (cat._id !== 'client_offers_category') return cat;
    return { ...cat, children: offersSubCategories };
  });
}

function clearCategoryFromEmptyChildren(category: Category, productsByCategory: ProductsByCategory): Category {
  if (!productsByCategory) return category;
  if (!category?.children?.length) return category;
  const children = category.children.filter((subCategory) => {
    return (
      Boolean(productsByCategory[subCategory._id]?.available.length) || subCategory.name === 'its_worth_it_for_you'
    );
  });
  return {
    ...category,
    children
  };
}

function getSelectedCategoryById(categories: Category[], categoryId: string, subCategoryId?: string): SelectedCategory {
  if (!categories?.length || !categoryId) return;
  const parentCategory = categories.find((category) => category._id === categoryId);
  if (!parentCategory) return;
  if (parentCategory && !parentCategory.children?.length) return { parentCategory };
  const subCategory = parentCategory.children.find((category) => category._id === subCategoryId);
  if (!subCategory) return { parentCategory };
  return { parentCategory, subCategory };
}

function getShopMenuProductMaxItemsPerOrder(product: Product, shop: Shop): number {
  const shopProductBuyLimit = getShopProductBuyLimit(shop);
  const { maxItemsPerOrder } = product;
  if (shopProductBuyLimit && maxItemsPerOrder) return Math.min(shopProductBuyLimit, maxItemsPerOrder);
  if (shopProductBuyLimit) return shopProductBuyLimit;
  if (maxItemsPerOrder) return maxItemsPerOrder;
}

function decorateProductWithMaxItemsQuantity(product: Product, shop: Shop): Product {
  const maxItemsQuantity = getShopMenuProductMaxItemsPerOrder(product, shop);
  return { ...product, maxItemsQuantity };
}

function getShopMenuOfferMaxItemsPerOrder(offer: Offer, shop: Shop): number {
  const shopProductBuyLimit = getShopProductBuyLimit(shop);
  const maxItemsPerOrder = getOfferMaxItemsPerOrder(offer);
  if (shopProductBuyLimit && maxItemsPerOrder) return Math.min(shopProductBuyLimit, maxItemsPerOrder);
  if (shopProductBuyLimit) return shopProductBuyLimit;
  if (maxItemsPerOrder) return maxItemsPerOrder;
}

function decorateOfferWithMaxItemsQuantity(offer: Offer, shop: Shop): Offer {
  const maxItemsQuantity = getShopMenuOfferMaxItemsPerOrder(offer, shop);
  return { ...offer, maxItemsQuantity };
}
