<div class="market-item-image-container">
  <promo-tag *ngIf="tagOptions" [options]="tagOptions"></promo-tag>
  <figure *ngIf="thumbnail" class="market-item-image-figure">
    <img loading="lazy" [src]="thumbnail" [alt]="name" />
  </figure>
</div>
<div class="market-item-content">
  <div class="market-item-details">
    <div class="market-item-name" [title]="name">{{ name }}</div>
    <div class="market-item-description">{{ description }}</div>
    <div class="market-item-price-container">
      <div class="market-item-price">{{ price | currencyPipe }}</div>
      <div *ngIf="originalPrice" class="market-item-starting-price">{{ originalPrice | currencyPipe }}</div>
      <div class="market-item-measurement-unit">{{ stepToUomDescription }}</div>
    </div>
  </div>
  <div class="market-item-quantity">
    <box-quantity [large]="true" [quantity]="quantity" (add)="onAdd()" (remove)="onRemove()"></box-quantity>
  </div>
</div>
