import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { APIError } from '@box-types';
import { AuthenticationService } from '@box-core/services';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly BOX_API_URL: string = environment.application.API_URL;
  private readonly ORDERS_API_URL: string = environment.application.ORDERS_API_URL;

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isBoxApi = request.url.startsWith(this.BOX_API_URL) || request.url.startsWith(this.ORDERS_API_URL);
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response?.status === 401) {
          this.authenticationService.signOut();
          void this.router.navigate(['/']);
        }
        if (!isBoxApi) return throwError(() => response);
        return throwError(() => (response?.error as { error: APIError })?.error ?? response);
      })
    );
  }
}
