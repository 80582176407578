import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromoCampaignsService } from '@box-core/services';
import { Product, PromoCampaign, ShopSuggestionBanner, SuggestionDialogBanner, Offer } from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { Subscription } from 'rxjs';
import { ShopSuggestionDialogService } from './shop-suggestion-dialog.service';
import { ShopMenuPageService } from '../../services';
import { getSuggestionDialogBanner } from '@box/utils';

@Component({
  selector: 'shop-suggestion-dialog',
  templateUrl: './shop-suggestion-dialog.component.html',
  styleUrls: ['./shop-suggestion-dialog.component.scss'],
  providers: [ShopSuggestionDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopSuggestionDialogComponent
  extends BoxDialogWrapperComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public products: Product[];
  public offers: Offer[];
  public suggestionDialogBanner: SuggestionDialogBanner;
  private itemsSubscription: Subscription;
  private campaign: PromoCampaign;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<ShopSuggestionDialogComponent>,
    private promoCampaignsService: PromoCampaignsService,
    private shopSuggestionDialogService: ShopSuggestionDialogService,
    private shopMenuPageService: ShopMenuPageService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: { suggestionBanner: ShopSuggestionBanner }
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setDialogData(this.data);
    this.setItemsSubscription();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  ngOnDestroy(): void {
    if (this.itemsSubscription) this.itemsSubscription.unsubscribe();
  }

  public onProductClick(index: number, product: Product): void {
    this.shopMenuPageService.onProductSelect({ item: product, itemsType: 'regular', index });
  }

  public onProductAdd(product: Product): void {
    this.shopMenuPageService.onProductAdd({ item: product, itemsType: 'regular' });
  }

  public onProductRemove(product: Product): void {
    this.shopMenuPageService.onProductRemove({ item: product, itemsType: 'regular' });
  }

  public onOfferAdd(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductAdd({
        item: item as Product,
        itemsType: 'regular'
      });
    }
    this.shopMenuPageService.onOfferAdd({ item: item as Offer, itemsType: 'regular' });
  }

  public onOfferRemove(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductRemove({
        item: item as Product,
        itemsType: 'regular'
      });
    }
    this.shopMenuPageService.onOfferRemove({ item: item as Offer, itemsType: 'regular' });
  }

  public onOfferClick(index: number, item: Offer | Product): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductSelect({
        item: item as Product,
        itemsType: 'regular',
        index
      });
    }
    this.shopMenuPageService.onOfferSelect({ item: item as Offer, itemsType: 'regular', index });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private setDialogData(data: { suggestionBanner: ShopSuggestionBanner }): void {
    const { campaignName } = data.suggestionBanner;
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    this.campaign = promoCampaigns.find((c) => c.name === campaignName);
    this.suggestionDialogBanner = getSuggestionDialogBanner(this.campaign);

    this.products = data.suggestionBanner.products;
    this.offers = data.suggestionBanner.offers;
  }

  private setItemsSubscription(): void {
    this.itemsSubscription = this.shopSuggestionDialogService.cartChanges$().subscribe(() => {
      const { products: bannerProducts, offers: bannerOffers } = this.data.suggestionBanner;
      this.offers = bannerOffers.map((offer) =>
        this.shopSuggestionDialogService.syncOfferCartQuantityWithCartState(offer)
      );
      this.products = bannerProducts.map((product) =>
        this.shopSuggestionDialogService.syncProductCartQuantityWithCartState(product)
      );
      this.changeDetectorRef.detectChanges();
    });
  }

  public trackById(index: number, item: Product | Offer): string {
    return item._id;
  }
}
