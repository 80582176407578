<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-points-dialog-main-wrapper">
      <p class="checkout-points-dialog-instructions">{{ 'drag_the_bar_and_start_earning' | translate }}</p>
      <checkout-points-slider-data
        class="checkout-points-dialog-slider-data"
        [activeIndex]="activeIndex"
        [steps]="pointsStepsTexts"
        [alternativeSteps]="eurosStepsTexts"
      >
        <box-slider
          [step]="1"
          [value]="selectedSlideStep"
          [claimableValue]="claimableStep"
          [availableValue]="availableStep"
          [maxValue]="maximumStep"
          (valueChange)="onValueChange($event)"
          (inputChange)="onInputChange($event)"
        ></box-slider>
      </checkout-points-slider-data>
      <div class="checkout-points-dialog-total">
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">{{
            'total_' | translate
          }}</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            cartPrice | currencyPipe
          }}</span>
        </div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">{{
            'win_from_the_discount' | translate
          }}</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s"
            >-&nbsp;{{ totalDiscount | currencyPipe }}</span
          >
        </div>
        <div class="box-divider"></div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">{{
            'final_products_price' | translate
          }}</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            (cartPrice - totalDiscount) | currencyPipe
          }}</span>
        </div>
      </div>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer checkout-points-dialog-footer">
    <button box-button theme="primary" class="checkout-points-dialog-footer-button" (click)="onSubmit()">
      {{ 'apply_' | translate }}
    </button>
  </footer>
</div>
