import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function addressFormValidator(isAddressValid: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const street = control?.value as string;
    if (!street) return { invalid: { missingAddress: 'street_field_is_required' } };

    if (isAddressValid) return null;
    return { invalid: { addressNotFound: 'address_not_found' } };
  };
}
