<div class="cookies-sliders">
  <div class="cookies-slider-row">
    <mat-slide-toggle
      disabled
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="mandatoryAccepted"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>{{ 'cookies_toggle_title_1' | translate }}:</strong> {{ 'cookies_toggle_description_1' | translate }}
    </div>
  </div>
  <div class="cookies-slider-row">
    <mat-slide-toggle
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="performanceAccepted"
      (change)="onChangeConsent('performance', $event)"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>{{ 'cookies_toggle_title_2' | translate }}:</strong> {{ 'cookies_toggle_description_2' | translate }}
    </div>
  </div>
  <div class="cookies-slider-row">
    <mat-slide-toggle
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="adAccepted"
      (change)="onChangeConsent('ad', $event)"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>{{ 'cookies_toggle_title_3' | translate }}:</strong> {{ 'cookies_toggle_description_3' | translate }}
    </div>
  </div>
  <div class="cookies-slider-row">
    <mat-slide-toggle
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="uxAccepted"
      (change)="onChangeConsent('ux', $event)"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>{{ 'cookies_toggle_title_4' | translate }}:</strong> {{ 'cookies_toggle_description_4' | translate }}
    </div>
  </div>
</div>
<footer class="legal-cookies-footer">
  <button box-button theme="primary" class="legal-cookies-button" (click)="onAcceptAll()">
    {{ 'accept_cookies' | translate }}
  </button>
  <button box-button theme="primary" class="legal-cookies-button" (click)="onRejectAll()">
    {{ 'reject_cookies' | translate }}
  </button>
  <button box-button theme="primary" class="legal-cookies-button" (click)="onSave()">
    {{ 'save_options' | translate }}
  </button>
</footer>
