<div class="review-avatar">
  <div class="review-avatar-frame">
    <svg width="36" height="34" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M17 0c9.205 0 16.667 6.625 16.667 14.796 0 8.172-7.462 14.797-16.667 14.797-2.981 0-5.78-.695-8.2-1.912a3.112 3.112 0 0 1-.28.271c-2.51 1.919-5.208 2.248-7.087 1.954-.624-.098-.756-.952-.187-1.232 1.72-.855 2.801-2.55 3.432-3.922-2.7-2.623-4.345-6.119-4.345-9.956C.333 6.625 7.795 0 17 0Z"
          id="a"
        />
      </defs>
      <g transform="translate(1 2)" fill="none" fill-rule="evenodd">
        <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
        <g mask="url(#b)"><path d="M-3-4h40v40H-3z" fill="#09CC94" fill-rule="nonzero" /></g>
      </g>
    </svg>
  </div>

  <span class="review-avatar-initials ods-typography-body-s-bold">{{ initials }}</span>
</div>

<div class="review-text">
  <p class="review-text-name ods-typography-body-s-regular">{{ name }}.</p>
  <ng-container *ngIf="!isPending; else pendingContent">
    <p class="review-text-comment ods-typography-body-s-regular" *ngIf="comment">&quot;{{ comment }}&quot;</p>
    <p class="review-text-recommendation" *ngIf="recommendation">{{ 'i_suggest' | translate }} {{ recommendation }}</p>
  </ng-container>
  <ng-template #pendingContent>
    <p class="review-text-comment ods-typography-body-s-regular">{{ 'no_comment' | translate }}</p>
  </ng-template>
</div>

<div class="review-rating ods-typography-microcopy-regular">
  <div class="review-rating-value">
    <span class="review-rating-value-number">{{ rating | number: '1.1' }}</span>
    <rating [rating]="rating" [disabled]="true"></rating>
  </div>
  <p class="review-rating-date">{{ date }}</p>
</div>
