import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { Offer, APIError, APIResponse } from '@box-types';
import { normalizeOffer } from '@box/utils';
import { pickBy } from 'lodash-es';
import { map, Observable } from 'rxjs';
import { FetchOfferOptions } from '@box-delivery/delivery.types';

@Injectable()
export class OffersService {
  private readonly BOX_API = environment.application.API_URL;

  constructor(private http: HttpClient) {}

  public fetchOffer(id: string, options: FetchOfferOptions): Observable<Offer> {
    const fromObject = pickBy(options);
    const params = new HttpParams({ fromObject });
    return this.http.get(`${this.BOX_API}/offers/details/${id}`, { params }).pipe(
      map((response: APIResponse<{ offer: Offer }>) => {
        const offer = response.payload.offer;
        if (!offer?._id) throw { userMessage: 'try_again_in_a_bit' } as APIError;
        return normalizeOffer(offer);
      })
    );
  }
}
