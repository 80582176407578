<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      title="choose_replacement_method"
      alignment="center"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <section class="checkout-product-replacement-dialog-section">
      <div class="checkout-product-replacement-dialog-description">
        {{ 'when_a_product_you_have_ordered_is_not_available' | translate }}
      </div>
    </section>
    <section class="checkout-product-replacement-dialog-section">
      <mat-radio-group class="scheduleDialog-options-radio" [(ngModel)]="currentOption">
        <mat-radio-button
          color="primary"
          class="box-radio-button checkout-product-replacement-dialog-option"
          *ngFor="let option of replacementOptions"
          [value]="option"
        >
          <div class="checkout-product-replacement-dialog-option-content">
            <span class="checkout-product-replacement-dialog-option-label">{{ option.label | translate }}</span>
            <span class="checkout-product-replacement-dialog-option-description">{{
              option.description | translate
            }}</span>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </section>
  </main>

  <footer #dialogFooter class="box-dialog-footer">
    <button class="checkout-product-replacement-dialog-submit" box-button theme="primary" (click)="onSubmit()">
      {{ 'confirm_' | translate }}
    </button>
  </footer>
</div>
