import {
  Inject,
  Component,
  OnInit,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address, ConfirmDialogResponse, APIError, BoxTheme } from '@box-types';
import {
  AddressEditDialogData,
  AddressEditDialogResponseAction,
  AddressEditDialogResponse
} from './address-edit-dialog.component.types';
import {
  MapsService,
  DialogService,
  AddressesService,
  PlacesService,
  GeolocationService,
  SentryService
} from '@box-core/services';
import {
  areAddressesEqual,
  isAddressReadyForDelivery,
  placeResultToAddress,
  generatePlacePredictionQuery,
  decorateAddressWithDescription,
  isPlaceResultDeliveryCompliant,
  getPlaceResultAddressComponent,
  getAddressEditMapStyles
} from '@box/utils';
import { omitBy, isNil } from 'lodash-es';
import { Observable, of, switchMap, map, Subscription } from 'rxjs';
import { BoxDialogWrapperComponent } from '../box-dialog-wrapper/box-dialog-wrapper.component'; // todo investigate
import { finalize, skip } from 'rxjs/operators';
import { addressFormValidator } from '@box-shared/components/address-edit-dialog/address-street.validator';
import { GoogleMap } from '@angular/google-maps';
import { ThemingService } from '@box-core/services/theming.service';

const DEFAULT_ADDRESS: Address = { type: 'home_' };

const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  zoom: 17,
  mapTypeControl: false,
  fullscreenControl: false,
  scaleControl: true,
  streetViewControl: false
};

@Component({
  templateUrl: './address-edit-dialog.component.html',
  styleUrls: ['./address-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressEditDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('map') private map: GoogleMap;

  public readonly NAME_AT_BELL_MAX_LENGTH = 37;
  public readonly DEBOUNCE_TIME = 2000;

  public loadingMapsApi: boolean;
  public loadingPlacesResults: boolean;
  public mapsAPILoaded: boolean;

  public address: Address;
  public addressForm: FormGroup;
  public mapOptions: google.maps.MapOptions;
  public verbose: boolean;
  public emptyStateMapUrl: string;

  private querySearchSubscription: Subscription;
  private mapClickSubscription: Subscription;
  private themeSubscription: Subscription;

  constructor(
    public renderer: Renderer2,
    private mapsService: MapsService,
    private dialogService: DialogService,
    private placesService: PlacesService,
    private addressesService: AddressesService,
    private dialogRef: MatDialogRef<AddressEditDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private geolocationService: GeolocationService,
    private sentryService: SentryService,
    private themingService: ThemingService,
    @Inject(MAT_DIALOG_DATA) public data: AddressEditDialogData
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.verbose = Boolean(this.data?.verbose);
    this.address = { ...(this.verbose && DEFAULT_ADDRESS), ...this.data?.address };
    this.addressForm = this.generateAddressFormGroup(this.address, true);
    this.loadingMapsApi = true;
    this.setThemeSubscription();
    this.changeDetectorRef.detectChanges();
    this.mapsService.loadAPI().subscribe((mapsAPILoaded) => {
      this.loadingMapsApi = false;
      this.mapsAPILoaded = mapsAPILoaded;
      this.setMapOptions(this.address, this.themingService.getTheme());
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.querySearchSubscription?.unsubscribe();
    this.mapClickSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  private getAction(): AddressEditDialogResponseAction {
    /* the incoming data address being long, when the component is initialized,
     means that it already exists on cosmote's BE, therefore we have to edit it.
     Otherwise if the incoming address is short we have to create it. */
    const isShortAddress = !isAddressReadyForDelivery(this.data?.address);
    const addresses = this.addressesService.getAddresses();
    const readyForDeliveryAddresses = addresses.filter((address) => isAddressReadyForDelivery(address));
    const addressAlreadyExists = readyForDeliveryAddresses.some((addr) => areAddressesEqual(addr, this.address));
    if (addressAlreadyExists && !isShortAddress) return 'edit';
    if (!addressAlreadyExists) return 'create';
  }

  public closeDialog(data?: AddressEditDialogResponse): void {
    this.dialogRef.close(data);
  }

  public onAddressTypeChange(type: string): void {
    this.addressForm.patchValue({ type });
  }

  public onSaveAddress(): void {
    const streetNo = (this.addressForm.value as Address).streetNo;
    of(streetNo)
      .pipe(
        switchMap((streetNumber) => {
          if (streetNumber) return of(true);
          return this.showStreetNOConfirmation().pipe(map((response) => response.accepted));
        })
      )
      .subscribe((submit) => {
        if (!submit) return;
        const address = { ...this.address, ...this.addressForm.value } as Address;
        const decoratedAddress = decorateAddressWithDescription(address);
        this.closeDialog({ action: this.getAction(), address: decoratedAddress });
      });
  }

  public onMapDragend(): void {
    this.loadingPlacesResults = true;
    this.changeDetectorRef.detectChanges();
    this.mapClickSubscription = this.geolocationService
      .reverseGeocodePosition$(this.map.getCenter())
      .pipe(
        finalize(() => {
          this.loadingPlacesResults = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (result) => {
          this.handlePlaceResult(result);
        },
        error: (error: APIError) => {
          this.sentryService.captureException(error, {
            domain: 'Address',
            domainDetails: 'Address Edit',
            severity: 'info'
          });
          this.handlePlaceResult(null);
        }
      });
  }

  public onStreetInput(): void {
    this.loadingPlacesResults = true;
    this.changeDetectorRef.detectChanges();
  }

  public onStreetDebounceInput(): void {
    const query = generatePlacePredictionQuery(this.addressForm);
    this.loadingPlacesResults = true;
    this.changeDetectorRef.detectChanges();
    this.querySearchSubscription = this.placesService
      .getPlacePredictions(query)
      .pipe(
        finalize(() => {
          this.loadingPlacesResults = false;
          this.changeDetectorRef.detectChanges();
        }),
        switchMap((predictions) => {
          if (!predictions?.length) return of(null);
          return this.placesService.getPlaceDetails(predictions[0].place_id);
        })
      )
      .subscribe({
        next: (result) => {
          this.handlePlaceResult(result);
        },
        error: (error: APIError) => {
          this.sentryService.captureException(error, {
            domain: 'Address',
            domainDetails: 'Address Edit',
            severity: 'info'
          });
          this.handlePlaceResult(null);
        }
      });
  }

  private handlePlaceResult(result: google.maps.places.PlaceResult): void {
    if (this.isPlaceResultProblematic(result)) {
      const address = this.formToAddress();
      this.addressForm = this.generateAddressFormGroup(address, false);
      // to show validation errors
      this.addressForm.markAllAsTouched();
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.address = this.placeResultToAddress(result);
    this.addressForm = this.generateAddressFormGroup(this.address, true);
    this.setMapOptions(this.address, this.themingService.getTheme());
    this.changeDetectorRef.detectChanges();
  }

  private placeResultToAddress(result: google.maps.places.PlaceResult): Address {
    return {
      ...placeResultToAddress(result),
      comments: this.verbose ? (this.addressForm.controls.comments?.value as string) : null,
      floor: this.verbose ? (this.addressForm.controls.floor?.value as string) : null,
      nameAtBell: this.verbose ? (this.addressForm.controls.nameAtBell?.value as string) : null,
      type: this.verbose ? (this.addressForm.controls.type?.value as string) : null
    };
  }

  private formToAddress(): Address {
    return {
      street: this.addressForm.controls.street?.value as string,
      streetNo: this.addressForm.controls.streetNo?.value as string,
      city: this.addressForm.controls.city?.value as string,
      postalCode: this.addressForm.controls.postalCode?.value as string,
      comments: this.verbose ? (this.addressForm.controls.comments?.value as string) : null,
      floor: this.verbose ? (this.addressForm.controls.floor?.value as string) : null,
      nameAtBell: this.verbose ? (this.addressForm.controls.nameAtBell?.value as string) : null,
      type: this.verbose ? (this.addressForm.controls.type?.value as string) : null
    };
  }

  private isPlaceResultProblematic(result: google.maps.places.PlaceResult): boolean {
    if (!result || !isPlaceResultDeliveryCompliant(result)) return true;
    const hasStreetNo = Boolean(this.addressForm.controls.streetNo.value as string);
    if (hasStreetNo && !getPlaceResultAddressComponent(result, 'street_number')) return true;
    return false;
  }

  public getStreetFormFieldErrorMessage(): string {
    const errors: ValidationErrors = this.addressForm.controls.street?.errors;
    if (!errors?.invalid) return;
    if (errors.invalid['missingAddress']) return errors.invalid['missingAddress'] as string; // eslint-disable-line
    if (errors.invalid['addressNotFound']) return errors.invalid['addressNotFound'] as string; // eslint-disable-line
  }

  private generateAddressFormGroup(address: Address, isAddressValid: boolean): FormGroup {
    const street = new FormControl(address.street, [
      Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
      addressFormValidator(isAddressValid)
    ]);
    const streetNo = new FormControl(address.streetNo);
    const city = new FormControl({ value: address.city, disabled: true }, [Validators.required]); // eslint-disable-line @typescript-eslint/unbound-method
    const postalCode = new FormControl({ value: address.postalCode, disabled: true }, [Validators.required]); // eslint-disable-line @typescript-eslint/unbound-method
    const floor = this.verbose ? new FormControl(address.floor, [Validators.required]) : null; // eslint-disable-line @typescript-eslint/unbound-method
    const nameAtBell = this.verbose
      ? new FormControl(address.nameAtBell, [
          Validators.required, // eslint-disable-line @typescript-eslint/unbound-method
          Validators.maxLength(this.NAME_AT_BELL_MAX_LENGTH)
        ])
      : null;
    const comments = this.verbose ? new FormControl(address.comments) : null;
    const type = this.verbose ? new FormControl(address.type, [Validators.required]) : null; // eslint-disable-line @typescript-eslint/unbound-method
    const controls = omitBy({ street, streetNo, city, postalCode, floor, nameAtBell, comments, type }, isNil);
    return new FormGroup(controls);
  }

  private setMapOptions(address: Address, theme: BoxTheme): void {
    const center = new google.maps.LatLng(address.latitude, address.longitude);
    const styles = getAddressEditMapStyles(theme);
    const emptyMapImagePath =
      theme !== 'dark' ? '/assets/images/general/empty-map-light.png' : '/assets/images/general/empty-map-dark.png';
    this.emptyStateMapUrl = `url(${emptyMapImagePath})`;
    this.mapOptions = { ...DEFAULT_MAP_OPTIONS, center, styles };
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.pipe(skip(1)).subscribe((theme) => {
      this.setMapOptions(this.address, theme);
      this.changeDetectorRef.detectChanges();
    });
  }

  private showStreetNOConfirmation(): Observable<ConfirmDialogResponse> {
    return this.dialogService
      .openConfirmDialog({
        title: 'address_number',
        messages: ['are_you_sure_your_address_doesnt_include_a_number'],
        confirmText: 'confirm_',
        cancelText: 'back_'
      })
      .afterClosed();
  }
}
