<ng-container *ngIf="state === 'AVAILABLE'; else waitingState">
  <span class="verification-resend-code-text ods-typography-body-s-regular"
    >{{ 'you_did_not_receive_the' | translate }} {{ otpMethod?.email?.length ? 'email' : 'SMS' }};</span
  >
  <button
    box-button
    theme="secondary"
    size="small"
    borders="none"
    class="verification-resend-code-button"
    (click)="onSend()"
  >
    {{ 'send_again' | translate }}
  </button>
</ng-container>
<ng-template #waitingState>
  <span class="verification-resend-code-text ods-typography-body-s-regular">{{ 'send_again_in' | translate }}:</span>
  <span class="verification-resend-code-timer ods-typography-body-s-bold">{{ remainingTime }}</span>
</ng-template>
