import { CheckoutSuggestionGroup, Product, PromoCampaign, Offer, SuggestionDialogBanner } from '@box-types';
import { getOffersSelectedProducts } from '../offers';
import { isEnabledByTimeRanges, isWithinWeekDays, intersectArrays, formatPoints } from '../core';
import {
  promoCampaignHasImageKey,
  promoCampaignHasTextKey,
  getPromoCampaignText,
  getPromoCampaignImage
} from '../promo-campaigns';
import { generateImageSrc } from '../media';

export {
  isDrinkPromoCampaign,
  isCuisineCartSuggestion,
  promoCampaignToCheckoutSuggestionGroup,
  isSuggestionCampaignEnabledNow,
  isPromoCampaignCartSuggestion,
  isDrinkCompatibleWithPromoCampaign,
  isProductIncludedInPromoCampaignCategory,
  filterProductsThatBelongToPromoCampaignCategories,
  filterProductsCompatibleWithPromoCampaign,
  isOfferCompatibleWithPromoCampaign,
  filterOffersCompatibleWithPromoCampaign,
  cartHasCampaign,
  getSuggestionDialogBanner
};

function isDrinkPromoCampaign(promoCampaign: PromoCampaign): boolean {
  return promoCampaign.type === 'collection_drink';
}

function isCuisineCartSuggestion(promoCampaign: PromoCampaign): boolean {
  return Boolean(promoCampaign.cartSuggestion?.cuisines?.length);
}

function promoCampaignToCheckoutSuggestionGroup(
  promoCampaign: PromoCampaign,
  products: Product[],
  offers: Offer[]
): CheckoutSuggestionGroup {
  const suggestionDialogBanner = getSuggestionDialogBanner(promoCampaign);
  return { ...suggestionDialogBanner, products, offers };
}

function getSuggestionDialogBanner(promoCampaign: PromoCampaign): SuggestionDialogBanner {
  const campaignName = promoCampaign.name;
  const description = getPromoCampaignText(promoCampaign, 'dialogBannerText');
  const multiplier = promoCampaign.multiplier ? `x${formatPoints(promoCampaign.multiplier)}` : null;
  const pointsAmount = promoCampaign.marketPlacePointsAmount
    ? `+${formatPoints(promoCampaign.marketPlacePointsAmount)}`
    : null;
  const image = generateImageSrc(getPromoCampaignImage(promoCampaign, 'drinksListLogo'));
  return { campaignName, description, image, multiplier, pointsAmount };
}

function isSuggestionCampaignEnabledNow(campaign: PromoCampaign): boolean {
  const cartSuggestion = campaign?.cartSuggestion;
  if (!cartSuggestion) return false;
  return isWithinWeekDays(cartSuggestion.enabledOnWeekDays) && isEnabledByTimeRanges(cartSuggestion.timeRanges);
}

function isPromoCampaignCartSuggestion(promoCampaign: PromoCampaign): boolean {
  /* The Promo Campaign should be active at that time to be a valid Cart Suggestion */
  const isAvailable = isSuggestionCampaignEnabledNow(promoCampaign);
  if (!isAvailable) return false;
  /* Promo Campaigns should have inShopPlacementImage and reminderTitle to be valid
    for Cart Suggestions */
  const hasImage = promoCampaignHasImageKey(promoCampaign, 'inShopPlacementImage');
  if (!hasImage) return false;
  const hasText = promoCampaignHasTextKey(promoCampaign, 'reminderTitle');
  if (!hasText) return false;
  /* Finally we check if the Cart Suggestion is enabled */
  const hasCartSuggestions = promoCampaign.cartSuggestion?.enabled;
  if (!hasCartSuggestions) return false;
  return true;
}

function isDrinkCompatibleWithPromoCampaign(promoCampaign: PromoCampaign, product: Product): boolean {
  const promoCampaignDrinkIds = promoCampaign.drinkIds;
  if (!promoCampaignDrinkIds?.length) return false;
  const productDrinkId = product.drinkId;
  if (!productDrinkId) return false;
  if (product.isInsideOffer && !promoCampaign.validForProductsInOffers) return false;
  return promoCampaignDrinkIds.includes(productDrinkId);
}

function isProductIncludedInPromoCampaignCategory(promoCampaign: PromoCampaign, product: Product): boolean {
  if (!product) return false;
  const productCategory = product.category;
  if (!productCategory) return false;
  const campaignCuisines = promoCampaign.cartSuggestion?.cuisines;
  if (!campaignCuisines?.length) return false;
  const productCategoriesIds = [];
  if (productCategory._id) productCategoriesIds.push(productCategory._id);
  if (productCategory.parent) productCategoriesIds.push(productCategory.parent);
  if (productCategory.parentCategoryId) productCategoriesIds.push(productCategory.parentCategoryId);
  if (!productCategoriesIds.length) return false;
  const intersectedCategories = intersectArrays([campaignCuisines, productCategoriesIds]);
  if (!intersectedCategories.length) return false;
  return true;
}

function filterProductsThatBelongToPromoCampaignCategories(
  promoCampaign: PromoCampaign,
  products: Product[]
): Product[] {
  if (!products?.length) return [];
  const isCuisineCampaign = isCuisineCartSuggestion(promoCampaign);
  if (!isCuisineCampaign) return [];
  return products.filter(
    (product) =>
      product.promoCampaigns?.includes(promoCampaign.name) &&
      isProductIncludedInPromoCampaignCategory(promoCampaign, product)
  );
}

function filterProductsCompatibleWithPromoCampaign(promoCampaign: PromoCampaign, products: Product[]): Product[] {
  if (!products?.length) return [];
  const isDrinkCampaign = isDrinkPromoCampaign(promoCampaign);
  if (isDrinkCampaign) return products.filter((p) => isDrinkCompatibleWithPromoCampaign(promoCampaign, p));
  const isCuisineCampaign = isCuisineCartSuggestion(promoCampaign);
  if (isCuisineCampaign) return filterProductsThatBelongToPromoCampaignCategories(promoCampaign, products);
  // product.promos is decorated with 'product_promo', 'product_cuisine_promo' campaign types and checks for validForProductsInOffers
  return products.filter((product) => {
    if (!product.promos?.length) return false;
    const promoNames = product.promos.map((promo) => promo.name);
    return promoNames.includes(promoCampaign.name);
  });
}

function offerActivatesCampaign(promoCampaign: PromoCampaign, cartOffer: Offer): boolean {
  if (!cartOffer) return false;
  if (cartOffer.promoCampaigns?.includes(promoCampaign.name)) return true;
  const cartOffersProducts = getOffersSelectedProducts([cartOffer]);
  if (!cartOffersProducts?.length) return false;
  const compatibleOfferProducts = filterProductsCompatibleWithPromoCampaign(promoCampaign, cartOffersProducts);
  if (!compatibleOfferProducts.length) return false;
  return true;
}

/* Checks if offer has Promo Campaign and if any product on the offer activates the Promo Campaign,
based on the selectedProducts */
function filterOffersThatActivatePromoCampaign(promoCampaign: PromoCampaign, offers: Offer[]): Offer[] {
  if (!offers?.length) return [];
  return offers.filter((offer) => offerActivatesCampaign(promoCampaign, offer));
}

function isOfferCompatibleWithPromoCampaign(promoCampaign: PromoCampaign, offer: Offer): boolean {
  if (!offer?.groups?.length) return false;
  if (offer.promoCampaigns?.includes(promoCampaign.name)) return true;
  const offerProducts = offer.groups.flatMap((group) => group.products).filter((product) => product.available);
  if (!offerProducts?.length) return false;
  const compatibleOfferProducts = filterProductsCompatibleWithPromoCampaign(promoCampaign, offerProducts);
  if (!compatibleOfferProducts.length) return false;
  return true;
}

/* Checks if offer has Promo Campaign and if any product on the offer is compatible with the Promo Campaign,
regardless of the selectedProducts */
function filterOffersCompatibleWithPromoCampaign(promoCampaign: PromoCampaign, offers: Offer[]): Offer[] {
  if (!offers?.length) return [];
  return offers.filter((offer) => isOfferCompatibleWithPromoCampaign(promoCampaign, offer));
}

/* On Cart Refactor, we should replace the products and offers with the cart object */
function cartHasCampaign(promoCampaign: PromoCampaign, cartProducts: Product[], cartOffers: Offer[]): boolean {
  if (!cartProducts?.length && !cartOffers?.length) return false;

  const compatibleProducts = filterProductsCompatibleWithPromoCampaign(promoCampaign, cartProducts);
  if (compatibleProducts?.length) return true;

  const compatibleOffers = filterOffersThatActivatePromoCampaign(promoCampaign, cartOffers);
  return Boolean(compatibleOffers?.length);
}
