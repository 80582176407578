<h4 class="order-status-header-title">{{ 'order_progress' | translate }}</h4>
<ng-container *ngIf="showMap; else dontShowMap">
  <live-tracking-map></live-tracking-map>
</ng-container>
<ng-template #dontShowMap>
  <div class="order-status-header-background">
    <picture *ngIf="isDaas" class="order-status-header-background">
      <img [src]="mapHeaderBackgroundImageUrl" [srcset]="mapHeaderBackgroundImageSrcSet" alt="Order Tracking Map" />
    </picture>
    <order-status-logo [order]="order"></order-status-logo>
  </div>
</ng-template>
