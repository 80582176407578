import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { RewardsCard } from '@box-types';

@Component({
  selector: 'rewards-header-card',
  templateUrl: './rewards-header-card.component.html',
  styleUrls: ['./rewards-header-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsHeaderCardComponent implements OnInit {
  @Input() public rewardsCard: RewardsCard;
  @HostBinding('class') public hostClass = 'rewards-header-card';

  public headerCardImageUrl: string;

  ngOnInit(): void {
    this.headerCardImageUrl = this.rewardsCard?.image ? `url(${this.rewardsCard.image})` : null;
  }
}
