<div class="address-wrapper">
  <div class="address-content">
    <div class="address-name ods-typography-body-m-bold">
      <svg-icon [svgPath]="addressIconPath" class="address-type-image"></svg-icon>
      <span>{{ address.type ?? 'your_address' | translate }}</span>
    </div>
    <div class="address-description ods-typography-body-s-regular">{{ address.description }}</div>
    <div class="address-description ods-typography-body-s-regular">
      <span *ngIf="address.floor">{{ 'floor_' | translate }}: {{ address.floor }}</span>
      <span *ngIf="address.nameAtBell">&nbsp;{{ 'name_at_bell' | translate }}: {{ address.nameAtBell }}</span>
    </div>
  </div>
</div>
