import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'box-cookies',
  templateUrl: './box-cookies.component.html',
  styleUrls: ['./box-cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxCookiesComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<BoxCookiesComponent>, private router: Router) {}

  accept(): void {
    this.bottomSheetRef.dismiss({ accepted: true });
  }

  decline(): void {
    this.bottomSheetRef.dismiss({ accepted: false });
  }

  onCookiesClick(): void {
    void this.router.navigate(['/account/personal-data/cookies']);
    this.bottomSheetRef.dismiss();
  }
}
