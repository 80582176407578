import { Component, Input, OnInit } from '@angular/core';
import { SuggestionDialogBanner } from '@box-types';

@Component({
  selector: 'suggestion-dialog-banner',
  templateUrl: './suggestion-dialog-banner.component.html',
  styleUrls: ['./suggestion-dialog-banner.component.scss']
})
export class SuggestionDialogBannerComponent implements OnInit {
  @Input() private suggestionDialogBanner: SuggestionDialogBanner;

  public imageSrc: string;
  public imageAlt: string;
  public multiplier: string;
  public pointsAmount: string;
  public description: string;

  ngOnInit(): void {
    this.imageSrc = this.suggestionDialogBanner.image;
    this.imageAlt = this.suggestionDialogBanner.campaignName;
    this.multiplier = this.suggestionDialogBanner.multiplier;
    this.pointsAmount = this.suggestionDialogBanner.pointsAmount;
    this.description = this.suggestionDialogBanner.description;
  }
}
