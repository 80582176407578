<div
  (swiperight)="onSwipeRight()"
  (swipeleft)="onSwipeLeft()"
  class="category-menu-items-wrapper"
  scrollSpy
  [spiedTags]="['SECTION']"
  [scrollSpyOffset]="scrollOffset"
  (sectionChange)="onSectionChange($event)"
>
  <section id="orders_category" class="category-menu-items-category" *ngIf="orders?.length > 0">
    <h4 class="category-menu-items-category-name">{{ 'recent_orders' | translate }}</h4>
    <div class="category-menu-items-category-items">
      <shop-orders
        [orders]="orders"
        class="category-menu-page-section"
        [shop]="shop"
        [title]="'recent_orders' | translate"
        visibilityObserver
        [triggerOnce]="true"
        [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
        (enteredViewport)="onShopOrdersEnteredViewport()"
      >
      </shop-orders>
    </div>
  </section>

  <section id="reminder_category" class="category-menu-items-category">
    <div class="category-menu-items-category-items">
      <shop-reminders [title]="'combine_and_win' | translate" class="category-menu-page-section"></shop-reminders>
    </div>
  </section>

  <ng-container *ngIf="currentCategory; else categoriesOverview">
    <h4 [attr.id]="currentCategory._id" class="category-menu-items-category-name">
      {{ currentCategory?.name | translate }}
    </h4>
    <ng-container *ngIf="currentCategory.children?.length; else noChildren">
      <section
        *ngFor="let subCategory of currentCategory.children; trackBy: trackById"
        [attr.id]="subCategory._id"
        class="category-menu-items-sub-category"
      >
        <ng-container *ngIf="subCategory.name === 'its_worth_it_for_you'; else productsView">
          <h4 class="category-menu-items-sub-category-name">{{ subCategory.name | translate }}</h4>
          <div class="category-menu-items-sub-category-separator"></div>
          <div class="category-menu-items-sub-category-items">
            <market-item
              class="category-menu-items-market-item"
              *ngFor="let offer of offers; trackBy: trackByIdAndQuantity; let i = index"
              [item]="offer"
              [isOffer]="true"
              (add)="onItemAdd($event)"
              (remove)="onItemRemove($event)"
              (click)="onItemClick(offer, i)"
            ></market-item>
          </div>
        </ng-container>
        <ng-template #productsView>
          <ng-container
            *ngIf="productsByCategory && productsByCategory[subCategory._id]?.available.length; else emptyState"
          >
            <h4 class="category-menu-items-sub-category-name">{{ subCategory.name }}</h4>
            <div class="category-menu-items-sub-category-separator"></div>
            <div class="category-menu-items-sub-category-items">
              <market-item
                class="category-menu-items-market-item"
                *ngFor="
                  let product of productsByCategory[subCategory._id].available;
                  trackBy: trackByIdAndQuantity;
                  let i = index
                "
                [item]="product"
                (add)="onItemAdd($event)"
                (remove)="onItemRemove($event)"
                (click)="onItemClick(product, i)"
              ></market-item>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #emptyState>
          <ng-container *ngIf="!loading">
            <h4 class="category-menu-items-sub-category-name">{{ subCategory.name }}</h4>
            <div class="category-menu-items-sub-category-separator"></div>
            <div class="category-menu-items-empty-state">
              <span class="category-menu-items-empty-state-text ods-typography-body-s-regular">{{
                'no_products_found_in_this_category' | translate
              }}</span>
              <mat-icon class="category-menu-items-empty-state-icon" fontSet="material-icons-round">search</mat-icon>
            </div>
          </ng-container>
        </ng-template>
      </section>
    </ng-container>
    <ng-template #noChildren>
      <ng-container
        *ngIf="productsByCategory && productsByCategory[currentCategory._id]?.available.length; else emptyState"
      >
        <section class="category-menu-items-sub-category">
          <div class="category-menu-items-sub-category-items">
            <market-item
              class="category-menu-items-market-item"
              *ngFor="
                let product of productsByCategory[currentCategory._id].available;
                trackBy: trackByIdAndQuantity;
                let i = index
              "
              [item]="product"
              (add)="onItemAdd($event)"
              (remove)="onItemRemove($event)"
              (click)="onItemClick(product, i)"
            ></market-item>
          </div>
        </section>
      </ng-container>
      <ng-template #emptyState>
        <div *ngIf="!loading" class="category-menu-items-empty-state">
          <span class="category-menu-items-empty-state-text ods-typography-body-s-regular">{{
            'no_products_found_in_this_category' | translate
          }}</span>
          <mat-icon class="category-menu-items-empty-state-icon" fontSet="material-icons-round">search</mat-icon>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-template #categoriesOverview>
    <section
      *ngIf="previewItems?.length"
      id="categories_overview"
      class="category-menu-items-category category-menu-items-offers-preview"
    >
      <div class="category-menu-items-offers-preview-header">
        <span class="category-menu-items-offers-preview-header-name">{{ 'offers_' | translate }}</span>
        <button
          (click)="onSeeAllOffersClick()"
          box-button
          theme="insignificant"
          class="category-menu-items-offers-preview-header-cta ods-typography-body-s-bold"
        >
          <span>{{ 'all_' | translate }}</span>
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <div class="category-menu-items-offers-preview-separator"></div>
      <div class="category-menu-items-swiper-container">
        <div [swiper]="swiperOptions" class="category-menu-items-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let item of previewItems; trackBy: trackByIdAndQuantity; let i = index">
              <market-item
                class="category-menu-items-offers-preview-item"
                [item]="item"
                [isOffer]="item.groups?.length"
                (add)="onItemAdd($event)"
                (remove)="onItemRemove($event)"
                (click)="onItemClick(item, i)"
              ></market-item>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="category-menu-items-parent-category" *ngFor="let cat of previewCategories; let last = last">
        <div mat-ripple class="category-menu-items-parent-category-action hoverable" (click)="onCategoryClick(cat)">
          <figure *ngIf="cat.image" class="category-menu-items-parent-category-action-image">
            <img loading="lazy" [src]="getCategoryImageUrl(cat)" [alt]="cat.name" />
          </figure>
          <span class="category-menu-items-parent-category-action-name">{{ cat.name }}</span>
          <mat-icon>chevron_right</mat-icon>
        </div>
        <div *ngIf="!last" class="category-menu-items-parent-category-separator"></div>
      </div>
    </section>
  </ng-template>
  <section>
    <allergens-info [shopPhone]="shop.phone"></allergens-info>
  </section>
</div>
