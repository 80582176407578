import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SentryService } from '.';
import { LanguageService } from '@box-core/services/language.service';

@Injectable({ providedIn: 'root' })
export class MapsService {
  private readonly MAPS_API_KEY = environment.google.MAPS_API_KEY;
  private apiLoaded: boolean;

  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private languageService: LanguageService
  ) {}

  public loadAPI(): Observable<boolean> {
    if (this.apiLoaded) return of(true);
    const langCode = this.languageService.getLanguageCode();
    return this.http
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.MAPS_API_KEY}&region=gr&language=${langCode}&libraries=places,geocoding`,
        'callback'
      )
      .pipe(
        map(() => (this.apiLoaded = true)),
        catchError((error: Error) => {
          this.sentryService.captureException(error, {
            domain: 'Google Maps',
            domainDetails: 'Google Maps API JS Error',
            severity: 'error'
          });
          return of(false);
        })
      );
  }
}
