<div *ngIf="reviews?.length; else emptyState" class="product-myo-reviews-container">
  <ng-container *ngFor="let review of reviews; let last = last">
    <review [review]="review"></review>
    <div *ngIf="!last" class="box-divider"></div>
  </ng-container>
</div>
<ng-template #emptyState>
  <div class="product-myo-reviews-empty-state">
    <p class="product-myo-reviews-empty-state-text">{{ 'no_reviews_found_for_this_product' | translate }}</p>
    <div class="product-myo-reviews-empty-state-icon">
      <svg width="104" height="40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <path
            d="m30.253 10.783-8.71-.866c-.55-.053-1.024-.42-1.248-.972l-3.12-7.96a1.467 1.467 0 0 0-2.77 0l-3.095 7.96c-.2.551-.699.92-1.248.972l-8.71.866c-1.298.132-1.823 1.84-.85 2.759l6.565 6.068c.424.394.599.972.474 1.55L5.57 29.67c-.3 1.34 1.074 2.444 2.222 1.735l7.263-4.493a1.445 1.445 0 0 1 1.522 0l7.263 4.493c1.148.709 2.52-.368 2.221-1.734l-1.947-8.511a1.621 1.621 0 0 1 .475-1.55l6.564-6.068c.948-.92.399-2.627-.899-2.759Z"
            id="a"
          />
          <path
            d="m30.253 10.783-8.71-.866c-.55-.053-1.024-.42-1.248-.972l-3.12-7.96a1.467 1.467 0 0 0-2.77 0l-3.095 7.96c-.2.551-.699.92-1.248.972l-8.71.866c-1.298.132-1.823 1.84-.85 2.759l6.565 6.068c.424.394.599.972.474 1.55L5.57 29.67c-.3 1.34 1.074 2.444 2.222 1.735l7.263-4.493a1.445 1.445 0 0 1 1.522 0l7.263 4.493c1.148.709 2.52-.368 2.221-1.734l-1.947-8.511a1.621 1.621 0 0 1 .475-1.55l6.564-6.068c.948-.92.399-2.627-.899-2.759Z"
            id="c"
          />
          <path
            d="m30.253 10.783-8.71-.866c-.55-.053-1.024-.42-1.248-.972l-3.12-7.96a1.467 1.467 0 0 0-2.77 0l-3.095 7.96c-.2.551-.699.92-1.248.972l-8.71.866c-1.298.132-1.823 1.84-.85 2.759l6.565 6.068c.424.394.599.972.474 1.55L5.57 29.67c-.3 1.34 1.074 2.444 2.222 1.735l7.263-4.493a1.445 1.445 0 0 1 1.522 0l7.263 4.493c1.148.709 2.52-.368 2.221-1.734l-1.947-8.511a1.621 1.621 0 0 1 .475-1.55l6.564-6.068c.948-.92.399-2.627-.899-2.759Z"
            id="e"
          />
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(72 8)">
            <path d="M0 0h31.629v31.629H0z" />
            <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
            <g mask="url(#b)" fill="#EEE"><path d="M0 0h31.94v31.94H0z" /></g>
          </g>
          <g transform="translate(36)">
            <path d="M0 0h31.629v31.629H0z" />
            <mask id="d" fill="#fff"><use xlink:href="#c" /></mask>
            <g mask="url(#d)" fill="#EEE"><path d="M0 0h31.94v31.94H0z" /></g>
          </g>
          <g transform="translate(0 8)">
            <path d="M0 0h31.629v31.629H0z" />
            <mask id="f" fill="#fff"><use xlink:href="#e" /></mask>
            <g mask="url(#f)" fill="#EEE"><path d="M0 0h31.94v31.94H0z" /></g>
          </g>
        </g>
      </svg>
    </div>
    <p class="product-myo-reviews-empty-state-text">
      {{ 'order_from_shop_and_give_your_reviews' | translate }}
    </p>
  </div>
</ng-template>
