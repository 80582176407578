import { Injectable } from '@angular/core';
import { CartService } from '@box-core/services';
import { Product, Offer } from '@box-types';
import { syncOfferCartQuantityWithCart, syncProductCartQuantityWithCart } from '@box/utils';
import { Observable } from 'rxjs';

@Injectable()
export class ShopSuggestionDialogService {
  constructor(private cartService: CartService) {}

  public cartChanges$(): Observable<{ products: Product[]; offers: Offer[] }> {
    return this.cartService.cart$;
  }

  public syncProductCartQuantityWithCartState(product: Product): Product {
    const cart = this.cartService.getCart();
    return syncProductCartQuantityWithCart(product, cart);
  }

  public syncOfferCartQuantityWithCartState(offer: Offer): Offer {
    const cart = this.cartService.getCart();
    return syncOfferCartQuantityWithCart(offer, cart);
  }
}
