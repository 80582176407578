<button box-button theme="muted" borders="none" [matMenuTriggerFor]="menu">{{ 'my_profile' | translate }}</button>
<mat-menu #menu="matMenu" xPosition="before" class="header-menu">
  <button *ngFor="let item of menuItems" (click)="onClick(item)" mat-menu-item class="box-navigation-menu-item">
    <svg-icon
      [svgPath]="item.icon"
      [attr.aria-label]="item.title | translate"
      class="box-navigation-menu-item-icon"
    ></svg-icon>
    <span class="box-navigation-menu-item-text ods-typography-body-m-bold">{{ item.title | translate }}</span>
  </button>
</mat-menu>
