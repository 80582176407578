<div class="box-swiper">
  <div class="swiper-section-header">
    <div class="swiper-section-header-content">
      <h3 class="swiper-section-title">{{ title }}</h3>
      <button *ngIf="showInfo" mat-icon-button class="home-shops-section-info-button" (click)="onOpenInfo()">
        <mat-icon fontSet="material-icons-round">info_outline</mat-icon>
      </button>
    </div>
    <div #navButtonContainer class="swiper-section-navigation">
      <button #prevBtn mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div [swiper]="swiperOptions" *ngIf="shops?.length">
    <div class="swiper-wrapper home-shops-section-swiper-wrapper">
      <ng-container [ngSwitch]="shopViewType">
        <ng-container *ngFor="let shop of shops">
          <div class="swiper-slide home-shops-section-slide-compact" *ngSwitchCase="'COMPACT'">
            <shop-tile-compact
              visibilityObserver
              [triggerOnce]="true"
              [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
              (enteredViewport)="onShopEnteredViewport(shop)"
              [shop]="shop"
              (click)="onShopCardClick(shop)"
              class="hoverable"
              layout="VERTICAL"
            ></shop-tile-compact>
          </div>

          <div class="swiper-slide home-shops-section-slide-detailed" *ngSwitchCase="'DETAILED'">
            <shop-tile-detailed
              visibilityObserver
              [triggerOnce]="true"
              [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
              (enteredViewport)="onShopEnteredViewport(shop)"
              [shop]="shop"
              (click)="onShopCardClick(shop)"
              class="hoverable"
            ></shop-tile-detailed>
          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="showActionButton" class="swiper-slide home-shops-section-action-slide">
        <div class="home-shops-section-action-wrapper">
          <button
            box-button
            theme="secondary"
            class="home-shops-section-action ods-typography-button-l"
            [class.home-shops-section-action-large]="shopViewType === 'DETAILED'"
            (click)="onAction()"
          >
            {{ buttonText | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
