<div data-mode="light" class="rewards-card-dialog-wrapper">
  <button mat-icon-button class="rewards-card-dialog-button" (click)="closeDialog()">
    <mat-icon fontSet="material-icons-round">close</mat-icon>
  </button>
  <div class="rewards-card-dialog-header">
    <img src="/assets/images/rewards/euro-icon.svg" class="rewards-card-dialog-header-image" />
    <span class="rewards-card-dialog-header-text ods-typography-body-s-regular">{{ title | translate }}</span>
  </div>
  <img [src]="contentSrc" width="268" height="204" />
</div>
