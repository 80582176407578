import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService, CouponsService, OrdersService, SEOService, UserService } from '@box-core/services';
import { Order, SingleBannerConfiguration } from '@box-types';
import { shouldShowBAFOrderAcceptanceBanner, isOrderAccepted, isOrderRejected, isOrderReviewable } from '@box/utils';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { LanguageService } from '@box-core/services/language.service';

@Injectable()
export class OrderStatusService implements OnDestroy {
  private readonly ORDER_POLLING_INTERVAL_NUMBER = 10 * 1000; // 10 seconds
  private readonly orderSource = new BehaviorSubject<Order>(undefined);
  public readonly order$ = this.orderSource.asObservable();

  private pollingIntervalSubscription: Subscription;

  constructor(
    private activatedRouter: ActivatedRoute,
    private ordersService: OrdersService,
    private configService: ConfigurationService,
    private userService: UserService,
    private seoService: SEOService,
    private couponsService: CouponsService,
    private languageService: LanguageService
  ) {}

  ngOnDestroy(): void {
    this.pollingIntervalSubscription?.unsubscribe();
  }

  public getOrder(): Order {
    return this.orderSource.getValue();
  }

  public setOrder(order: Order): void {
    this.orderSource.next(order);
  }

  public fetchOrder(): Observable<Order> {
    const friendlyId = this.activatedRouter.snapshot.queryParams.friendlyId as string;
    return this.ordersService.fetchOrder(friendlyId);
  }

  public startOrderPolling(): void {
    this.pollingIntervalSubscription = interval(this.ORDER_POLLING_INTERVAL_NUMBER).subscribe(() =>
      this.fetchOrder().subscribe((order) => this.orderSource.next(order))
    );
  }

  public stopOrderPolling(): void {
    this.pollingIntervalSubscription?.unsubscribe();
  }

  public generateSingleBannerConfig(order: Order): SingleBannerConfiguration {
    const config = this.configService.getConfiguration();
    if (!isOrderAccepted(order)) return;
    if (order.user.hasOrdered) return;
    if (!config.singleBannerSectionV2) return;
    if (!shouldShowBAFOrderAcceptanceBanner()) return;
    return { ...config.singleBannerSectionV2, shortViewOnly: true };
  }

  public shouldShowOrderStatusRating(order: Order): boolean {
    return isOrderReviewable(order);
  }

  public shouldShowContactInfo(order: Order): boolean {
    // Once we Refactor the below function we would need to add the isOrderCompleted as well
    return isOrderAccepted(order);
  }

  public shouldShowGBRewards(order: Order): boolean {
    // Once we Refactor MBBAnnerComponent this will change
    return !isOrderRejected(order);
  }

  public shouldShowOrderDetails(order: Order): boolean {
    // Once we Refactor the below function we would need to add the isOrderCompleted as well
    return isOrderAccepted(order);
  }

  public shouldShowOrderCompletionRatings(order: Order): boolean {
    if (isOrderRejected(order)) return false;
    const user = this.userService.getUser();
    return Boolean(user.gdprConsent);
  }

  public addUserPointsAfterAcceptance(order: Order) {
    const collectedPoints = order.marketPlacePoints?.collected;
    if (!collectedPoints) return;
    this.userService.addPoints(collectedPoints);
  }

  public handleUserPointsRefund(order: Order) {
    if (!order.marketPlacePoints?.consumed) return;
    this.userService.setPointsBeforeConsumption(order._id);
  }

  public handleCouponsUpdate(order: Order): void {
    if (!order.coupon) return;
    this.couponsService
      .fetchAvailableCoupons$()
      .subscribe((coupons) => this.couponsService.setAvailableCoupons(coupons));
  }

  public setMetaTags(order: Order): void {
    /* For the Live Tracking, we might wanna get a more comprehensive title for the page
    generated by the Order Status (delviery estimation etc). For example, if the order is
    Accepted and the estimation is 45 minutes then the text could be something like:
    "45 minutes - {ShopName}" */
    const shopName = order.shop.name;
    const orderProgress = this.languageService.getTextByKey('order_progress');
    const title = `${orderProgress} - ${shopName}`;
    this.seoService.setTags({ title });
  }
}
