import dayjs from 'dayjs';
import { of, tap, Observable } from 'rxjs';
/**
 * @param latestTimestamp is expected to be a unix epoch timestamp in milliseconds
 * you can get that value like this: dayjs().unix();
 * @param expirationTimeInMs expiration time in milliseconds
 * */
function isTimestampExpired(latestTimestamp: number, expirationTimeInMs: number): boolean {
  if (!latestTimestamp || !expirationTimeInMs) return true;
  const latestCacheDate = dayjs.unix(latestTimestamp);
  const expirationDate = latestCacheDate.add(expirationTimeInMs, 'millisecond');
  const currentDate = dayjs();
  return currentDate.isAfter(expirationDate);
}

function getCacheKey<K>(functionArguments: any[], keyArgumentsIndexes: number[] = []): string | 'no-key' {
  if (!keyArgumentsIndexes?.length || !functionArguments?.length) return 'no-key';
  return keyArgumentsIndexes
    .map((keyArgumentIndex) => (keyArgumentIndex >= 0 ? (functionArguments[keyArgumentIndex] as K) : 'no-key'))
    .join('-');
}

type ObservableCacheValue = { value: Observable<any>; timestamp: number };

/**
 * This function is invoked at build time, when the aspect @cache is defined
 * based on this article https://www.meziantou.net/aspect-oriented-programming-in-typescript.htm
 * @param config contains:
 * functionKeyArgumentIndexes: corresponds to the indexes of the parameters that will act as the unique cache key
 * expirationTimeInMs: the time that is needed for the cache to be invalidated in milliseconds
 * invalidateSignal: is an observable that carries the cache key and is triggered when you want to erase a cache entry
 * V: is the type of the value stored in the Map
 * K: is the type of the Map key, defaults to 'no-key' if not specified
 * */
function ObservableCacheDecorator<V, K = 'no-key'>(config: {
  expirationTimeInMs?: number;
  functionKeyArgumentIndexes?: number[];
  invalidateSignal$?: Observable<string>;
}) {
  /* containerEntity: is the container class of the function
   * functionName: is the name of the function
   * descriptor: is the ProductDescriptor of the function,
   * which contains the function itself under the value property
   * */
  return function (containerEntity: any, functionName: string, descriptor: PropertyDescriptor): void {
    const originalFunction = descriptor.value;

    /* first type is the key, the second type is the return value.
     * Since the key can be an object apart from a string,
     * we use the Map type and not the Record type
     * */
    const cacheMap = new Map<string | 'no-key', ObservableCacheValue>();

    // update the original function with the aspect behavior
    descriptor.value = function (...args: unknown[]): Observable<V> {
      const cacheKey = getCacheKey<K>(args, config.functionKeyArgumentIndexes);

      if (cacheMap.has(cacheKey)) {
        const cacheValue = cacheMap.get(cacheKey);
        const isCashValid = !isTimestampExpired(cacheValue.timestamp, config.expirationTimeInMs);
        if (isCashValid) return cacheValue.value;
      }

      /* call the original function with its context
       * and arguments (they need to be an array) and store the result
       */
      const result = originalFunction.apply(this, args);
      // the value is stored when the callee calls .subscribe() on the result
      return (result as Observable<V>).pipe(
        tap((value: V) => {
          const decoratedValue: ObservableCacheValue = { value: of(value), timestamp: dayjs().unix() };
          cacheMap.set(cacheKey, decoratedValue);
        })
      );
    };

    config.invalidateSignal$?.subscribe((keyThatNeedsToBeInvalidated) => {
      if (!keyThatNeedsToBeInvalidated) return cacheMap.clear();
      cacheMap.delete(keyThatNeedsToBeInvalidated);
    });
  };
}

export { ObservableCacheDecorator, isTimestampExpired };
