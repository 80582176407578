<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="market-card-connect-dialog-content">
      <section *ngIf="config.canSearch" class="market-card-connect-dialog-section">
        <div class="market-card-connect-dialog-container" [formGroup]="searchFormGroup">
          <ng-container *ngIf="validationMode === 'EMAIL'; else pinotp">
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              {{ 'you_already_have_a_shop_card' | translate: { _SHOP_NAME: shopName } }}
              {{ 'type_your_email_for_connecting_your_card' | translate }}
            </p>
          </ng-container>
          <ng-template #pinotp>
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              {{ 'you_already_have_a_shop_card' | translate: { _SHOP_NAME: shopName } }}
              {{ 'type_your_card_number_for_connecting_your_card' | translate }}
            </p>
          </ng-template>

          <div *ngIf="config.canSearch && !config.canCreate" class="market-card-connect-dialog-rewards">
            <div class="market-card-connect-dialog-reward">
              <span class="market-card-connect-dialog-reward-icon">
                <mat-icon fontSet="material-icons-round">check</mat-icon>
              </span>
              <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                {{ 'collect_and_redeem_points_from_supermarkets' | translate: { _SHOP_NAME: shopName } }}
              </span>
            </div>
            <div class="market-card-connect-dialog-reward">
              <span class="market-card-connect-dialog-reward-icon">
                <mat-icon fontSet="material-icons-round">check</mat-icon>
              </span>
              <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                {{ 'you_can_also_use_it_in_physical_supermarkets' | translate: { _SHOP_NAME: shopName } }}
              </span>
            </div>
          </div>

          <ng-container *ngIf="validationMode === 'EMAIL'; else pinotpinput">
            <mat-form-field class="market-card-connect-dialog-field">
              <mat-label>e-mail</mat-label>
              <input matInput autocomplete="off" (keydown.enter)="onSearch()" formControlName="searchInputControl" />
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.required"
                >{{ 'email_field_is_required' | translate }}
              </mat-error>
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.pattern">{{
                'invalid_email' | translate
              }}</mat-error>
            </mat-form-field>
          </ng-container>
          <ng-template #pinotpinput>
            <mat-form-field class="market-card-connect-dialog-field">
              <mat-label>{{ 'card_number' | translate }}</mat-label>
              <input matInput autocomplete="off" (keydown.enter)="onSearch()" formControlName="searchInputControl" />
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.required"
                >{{ 'card_number_field_is_required' | translate }}
              </mat-error>
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.pattern"
                >{{ 'card_number_field_accepts_only_numbers' | translate }}
              </mat-error>
            </mat-form-field>
          </ng-template>

          <mat-checkbox
            *ngIf="findCardTermsHTML"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="findCardTermsControl"
          >
            <span
              class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular"
              [innerHTML]="findCardTermsHTML"
            ></span>
          </mat-checkbox>

          <mat-checkbox
            *ngIf="saveCardTermsText"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="saveCardTermsControl"
          >
            <span class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular">{{
              saveCardTermsText
            }}</span>
          </mat-checkbox>

          <button
            box-button
            theme="primary"
            class="market-card-connect-dialog-button"
            [disabled]="searchFormGroup.invalid"
            (click)="onSearch()"
          >
            {{ 'card_search' | translate }}
          </button>
        </div>
      </section>
      <hr
        *ngIf="showSeparator"
        class="box-hr market-card-connect-dialog-separator"
        [attr.data-title]="'alternatively_' | translate"
      />
      <section *ngIf="config.canCreate" class="market-card-connect-dialog-section">
        <div class="market-card-connect-dialog-container" [formGroup]="createFormGroup" [ngSwitch]="createMode">
          <ng-container *ngSwitchCase="'normal'">
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              {{ 'we_will_create_a_new_card_for_you_to' | translate }}
            </p>
            <div class="market-card-connect-dialog-rewards">
              <div class="market-card-connect-dialog-reward">
                <span class="market-card-connect-dialog-reward-icon">
                  <mat-icon fontSet="material-icons-round">check</mat-icon>
                </span>
                <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                  {{ 'collect_and_redeem_points_from_supermarkets' | translate: { _SHOP_NAME: shopName } }}
                </span>
              </div>

              <ng-container [ngSwitch]="isPhysical">
                <ng-container *ngSwitchCase="true">
                  <div class="market-card-connect-dialog-reward">
                    <span class="market-card-connect-dialog-reward-icon">
                      <mat-icon fontSet="material-icons-round">check</mat-icon>
                    </span>
                    <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                      {{ 'you_can_also_use_it_in_physical_supermarkets' | translate: { _SHOP_NAME: shopName } }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="market-card-connect-dialog-reward">
                    <span class="market-card-connect-dialog-reward-icon">
                      <mat-icon fontSet="material-icons-round">check</mat-icon>
                    </span>
                    <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                      {{ 'you_can_also_use_it_in_the_online_supermarkets' | translate: { _SHOP_NAME: shopName } }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'guest'">
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              {{ 'dont_worry_you_can_complete_your_order_normally' | translate: { _SHOP_NAME: shopName } }}
            </p>
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              {{ 'if_you_get_a_card_in_the_future' | translate: { _SHOP_NAME: shopName } }}
            </p>
          </ng-container>

          <mat-checkbox
            *ngIf="firstPersonalDataTermsHTML"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="firstPersonalDataTermsControl"
          >
            <span
              class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular"
              [innerHTML]="firstPersonalDataTermsHTML"
            ></span>
          </mat-checkbox>

          <mat-checkbox
            *ngIf="secondPersonalDataTermsHTML"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="secondPersonalDataTermsControl"
          >
            <span
              class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular"
              [innerHTML]="secondPersonalDataTermsHTML"
            ></span>
          </mat-checkbox>

          <button
            box-button
            theme="primary"
            class="market-card-connect-dialog-button"
            [disabled]="createFormGroup.invalid"
            (click)="onCreate()"
          >
            {{ createButtonText | translate }}
          </button>
        </div>
      </section>
    </div>
  </main>
</div>
