import { constructMediaSrc, getMediaPrefix } from './media.utils';
import { Image } from '@box-types';

export { generateImageSrc, generateImageSrcSet };

function generateImageSrc(image: Image, prefix?: string): string {
  if (!image) return '';
  if (image.exactUrl) return image.exactUrl;
  const imagePrefix = prefix ?? getMediaPrefix(window);
  return constructMediaSrc(image, imagePrefix);
}

function generateImageSrcSet(image: Image): string {
  if (!image) return '';
  const imageX1 = generateImageSrc(image, '@1x');
  const imageX2 = generateImageSrc(image, '@2x');
  const imageX3 = generateImageSrc(image, '@3x');
  return `${imageX1} x1, ${imageX2} x2, ${imageX3} x3`;
}
