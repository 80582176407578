import { CommonModule, registerLocaleData, DecimalPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { LOCALE_ID, NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PlatformModule } from '@angular/cdk/platform';
import localeEl from '@angular/common/locales/el';
import localeElExtra from '@angular/common/locales/extra/el';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

import { ShopsWidgetModule } from '@box-shops-widget/shops-widget.module';
import { SkeletonWidgetModule } from '@box-skeleton-widget/skeleton-widget.module';
import { ContestWidgetModule } from '@box-contest-widget/contest-widget.module';
import { RewardsWidgetModule } from '@box-rewards-widget/rewards-widget.module';
import { RatingWidgetModule } from '@box-rating-widget/rating-widget.module';
import { DaasWidgetModule } from '@box-daas-widget/daas-widget.module';
import { OrdersWidgetModule } from '@box-orders-widget/orders-widget.module';
import { SharedModule } from '@box-shared/shared.module';

import * as Interceptors from './interceptors';
import * as Providers from './providers';
import * as Components from './components';
import * as Pages from './pages';

@NgModule({
  declarations: [
    // Components
    Components.BoxShellComponent,
    Components.BoxHeaderComponent,
    Components.BoxHeaderDeliveryDetailsComponent,
    Components.BoxNavigationMenuComponent,
    Components.BoxCosmoteIdButtonComponent,
    Components.BoxCookiesComponent,
    Components.PromoBannerComponent,
    Components.PromoBannerDetailsDialogComponent,
    Components.PromoBannersComponent,
    Components.PromoCouponComponent,
    Components.HomeSectionInfoDialogComponent,
    Components.HomeShopsSectionComponent,
    Components.ContestsSectionComponent,
    Components.BoxMaintenanceDialogComponent,
    Components.GBPromoDialogComponent,
    Components.GDPRConsentDialogComponent,
    Components.CollectionCampaignsDialogComponent,
    Components.OnBoardingDialogComponent,
    Components.HomeDiscoverFiltersComponent,
    Components.HomeDiscoverFilterComponent,
    Components.HomeCuisinesComponent,
    Components.HomeSearchComponent,
    Components.HomeSearchResultsComponent,
    Components.NotificationComponent,
    Components.NotificationsComponent,
    Components.BreadcrumbComponent,
    Components.WhatsNewDialogComponent,
    Components.HomeBrandsComponent,
    // Pages
    Pages.FourOhFourPage,
    Pages.HomePage
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    PlatformModule,
    SkeletonWidgetModule,
    RatingWidgetModule,
    ContestWidgetModule,
    RewardsWidgetModule,
    ShopsWidgetModule,
    DaasWidgetModule,
    OrdersWidgetModule
  ],
  exports: [
    // Modules
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    PlatformModule,
    // Components
    Components.BoxShellComponent,
    Components.BoxHeaderComponent,
    Components.BoxHeaderDeliveryDetailsComponent,
    Components.BoxNavigationMenuComponent,
    Components.BoxCosmoteIdButtonComponent,
    Components.BoxCookiesComponent,
    Components.PromoBannerComponent,
    Components.PromoBannerDetailsDialogComponent,
    Components.PromoBannersComponent,
    Components.PromoCouponComponent,
    Components.HomeShopsSectionComponent,
    Components.ContestsSectionComponent,
    Components.BoxMaintenanceDialogComponent,
    Components.GBPromoDialogComponent,
    Components.CollectionCampaignsDialogComponent,
    Components.OnBoardingDialogComponent,
    Components.NotificationComponent,
    Components.NotificationsComponent,
    // Pages
    Pages.FourOhFourPage,
    Pages.HomePage
  ],
  providers: [
    // Localization
    { provide: LOCALE_ID, useValue: 'el-GR' },
    // Interceptors
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { ...new MatDialogConfig(), autoFocus: false, restoreFocus: false }
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptors.ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptors.CosmoteIDInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptors.ErrorInterceptor, multi: true },
    // Providers
    { provide: ErrorHandler, useClass: Providers.GlobalErrorHandler },
    // Services
    DecimalPipe,
    CurrencyPipe,
    DatePipe
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(localeEl, 'el-GR', localeElExtra);
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the FoodModule only');
    }
  }
}
