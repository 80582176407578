<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="my_numbers" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody class="msisdn-main box-dialog-content">
    <p class="msisdn-subtitle">
      {{ 'choose_number_where_gb_will_go_to' | translate }}
    </p>

    <mat-radio-group class="msisdn-radioGroup" [(ngModel)]="selectedAssetInfo">
      <mat-radio-button
        class="msisdn-radioGroup-button"
        color="primary"
        *ngFor="let assetInfo of assetsInfo"
        [value]="assetInfo"
      >
        <div class="msisdn-radio-content">
          <span>{{ assetInfo.msisdn }}</span>
          <figure class="msisdn-radio-content-image" *ngIf="assetInfo.segment === suggestionRewardSegment">
            <img [src]="suggestionIndicator" />
          </figure>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </main>
  <footer class="msisdn-footer">
    <button box-button theme="primary" class="msisdn-confirm" (click)="onConfirm()">{{ 'change_' | translate }}</button>
  </footer>
</div>
