<div class="shop-menu-nav-mobile-wrapper">
  <div class="shop-menu-nav-mobile-main">
    <button mat-icon-button color="primary" class="shop-menu-nav-mobile-button" (click)="onMenuButtonClick()">
      <mat-icon fontSet="material-icons-round">menu</mat-icon>
    </button>
    <ul class="shop-menu-nav-mobile-list">
      <div #mainSwiper [swiper]="swiperOptions" class="box-swiper" *ngIf="menuNav && menuNav.items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of menuNav.items; trackBy: trackByActive">
            <li
              class="shop-menu-nav-mobile-list-item primary-list-item"
              [class.active]="item.active"
              (click)="onItemClick(item)"
            >
              {{ item.name | translate }}
            </li>
          </div>
        </div>
      </div>
    </ul>
  </div>
  <div class="shop-menu-nav-mobile-secondary">
    <ul class="shop-menu-nav-mobile-list">
      <div #secondarySwiper [swiper]="swiperOptions" class="box-swiper" *ngIf="activeItem && activeItem.subItems">
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let subItem of activeItem.subItems; trackBy: trackByActive">
            <li
              class="shop-menu-nav-mobile-list-item secondary-list-item"
              [class.active]="subItem.active"
              (click)="onSecondaryItemClick(subItem)"
            >
              {{ subItem.name | translate }}
            </li>
          </div>
        </div>
      </div>
    </ul>
  </div>
</div>
