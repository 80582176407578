import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { Campaign, APIResponse } from '@box-types';
import { ConfigurationService } from './configuration.service';

const DEFAULT_LOYALTY_POINTS = 1600;

@Injectable({ providedIn: 'root' })
export class LoyaltyService {
  private BOX_API: string = environment.application.API_URL;
  public campaigns = new BehaviorSubject<Campaign[]>([]);

  constructor(private http: HttpClient, private configService: ConfigurationService) {}

  public setEvent(event: string): Observable<boolean> {
    return this.http.put(`${this.BOX_API}/users/setLoyaltyEvent`, { event }).pipe(map(() => true));
  }

  public getCampaign(): Observable<Campaign[]> {
    return this.http
      .get(this.BOX_API + '/box/loyalty/campaigns')
      .pipe(map((response: APIResponse<{ campaigns: Campaign[] }>) => response.payload.campaigns));
  }

  public setCampaigns(campaigns: Campaign[]): void {
    this.campaigns.next(campaigns);
  }

  public getClaimablePoints(event: string): number {
    const campaign: Campaign = this.campaigns.value.find((v) => v.event === event);
    if (!campaign?.marketPlacePointsAmount) return 0;
    return campaign.marketPlacePointsAmount;
  }

  public getLoyaltyPointsToRedeemRate(): number {
    const pointsToRedeemRate =
      this.configService.getConfiguration().loyaltyInfoModel?.loyaltyPointsToRedeemRate ?? DEFAULT_LOYALTY_POINTS;
    return pointsToRedeemRate;
  }
}
