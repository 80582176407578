<div class="box-dialog-wrapper" *ngIf="cartItem">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      [title]="cartItem.name"
      mode="check"
      alignment="left"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <div class="item-wrapper" [ngSwitch]="type">
      <ng-container *ngSwitchCase="'product'">
        <div *ngFor="let instance of cartItem.cartInstances" class="product-instance">
          <div class="instance-description ods-typography-body-s-regular">
            <span>{{ instance.description }}</span>
            <span *ngIf="instance.comments">, "{{ instance.comments }}"</span>
          </div>
          <div class="instance-price">
            {{ instance.price * instance.quantity | currencyPipe }}
          </div>
          <box-quantity
            class="instance-quantity small"
            [quantity]="instance.quantity"
            (add)="onInstanceAdd(instance)"
            (remove)="onInstanceRemove(instance)"
          ></box-quantity>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'offer'">
        <ng-container *ngFor="let instance of cartItem.cartInstances; let i = index">
          <div *ngIf="i !== 0" class="box-divider"></div>
          <div class="offer-instance">
            <div class="offer-instance-products">
              <div *ngFor="let group of instance.groups" class="offer-instance-product">
                <div class="offer-product-title">{{ group.selectedProduct.name }}</div>
                <div class="offer-product-description">
                  <span>{{ group.selectedProduct.cartInstances[0].description }}</span>
                  <span *ngIf="group.selectedProduct.cartInstances[0].comments">
                    , "{{ group.selectedProduct.cartInstances[0].comments }}"
                  </span>
                </div>
              </div>
            </div>
            <div class="offer-instance-actions">
              <div *ngIf="isSuperMarket" class="instance-price">
                {{ cartItem.price * instance.quantity | currencyPipe }}
              </div>
              <div *ngIf="!isSuperMarket" class="instance-price">
                {{ instance.price * instance.quantity | currencyPipe }}
              </div>
              <box-quantity
                class="instance-quantity small"
                [quantity]="instance.quantity"
                (add)="onInstanceAdd(instance)"
                (remove)="onInstanceRemove(instance)"
              ></box-quantity>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer">
    <div class="footer-wrapper">
      <button box-button theme="secondary" (click)="openMYO()">{{ 'add_new_with_other_options' | translate }}</button>
    </div>
  </footer>
</div>
