<component-loader *ngIf="loadingMapsApi"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      [title]="'where_should_we_deliver_them' | translate"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content address-dialog-container">
    <div class="address-dialog-location">
      <span class="address-dialog-location-description ods-typography-body-s-regular">{{
        'add_new_address_text' | translate
      }}</span>
      <div class="address-dialog-location-input-container" matAutocompleteOrigin #fieldOrigin="matAutocompleteOrigin">
        <mat-icon class="address-dialog-location-input-icon">place</mat-icon>
        <input
          #addressInput
          class="address-dialog-location-input-field"
          matInput
          name="address"
          type="text"
          [placeholder]="'place_your_address' | translate"
          [attr.aria-label]="'place_your_address' | translate"
          cdkFocusInitial
          debounceInput
          (input)="onAddressInput()"
          (debounceInput)="onAddressSearch($event)"
          [matAutocomplete]="locationAutoComplete"
          [matAutocompleteConnectedTo]="fieldOrigin"
          [value]="locationInputAddressDescription"
        />
        <mat-icon
          matSuffix
          class="address-dialog-location-input-clear-btn"
          *ngIf="locationInputAddressDescription"
          (click)="clearAddress()"
        >
          clear
        </mat-icon>
      </div>
      <mat-autocomplete
        #locationAutoComplete="matAutocomplete"
        autoActiveFirstOption
        [displayWith]="displayWithFunction"
        (optionSelected)="onAutoCompleteOptionSelected($event)"
      >
        <div class="address-dialog-options">
          <mat-option class="address-dialog-option" *ngFor="let option of placePredictions" [value]="option">
            <div class="address-dialog-option-wrapper">
              <span class="address-dialog-option-header ods-typography-subtitle-s">{{
                option.structured_formatting.main_text
              }}</span>
              <span class="address-dialog-option-description ods-typography-body-s-regular">
                {{ option.structured_formatting.secondary_text }}
              </span>
            </div>
          </mat-option>
          <mat-option *ngIf="showEmptyState" value="" class="autocomplete-empty-state">
            <div>
              <p>{{ 'no_results_found' | translate }}</p>
              <p>{{ 'try_typing_address_in_form' | translate }}</p>
              <p>{{ 'enter_comment_in_next_steps' | translate }}</p>
            </div>
          </mat-option>
        </div>
      </mat-autocomplete>
      <button
        box-button
        theme="primary"
        [class.loading-places]="loadingPlaces"
        [disabled]="!address || loadingPlaces"
        (click)="onOrderClick()"
      >
        {{ 'order_exclamation' | translate }}
      </button>
      <button
        class="address-dialog-location-geolocation-cta"
        theme="insignificant"
        box-button
        borders="none"
        (click)="geolocateUser()"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 8.36364C9.99091 8.36364 8.36364 9.99091 8.36364 12C8.36364 14.0091 9.99091 15.6364 12 15.6364C14.0091 15.6364 15.6364 14.0091 15.6364 12C15.6364 9.99091 14.0091 8.36364 12 8.36364ZM20.1273 11.0909C19.7091 7.3 16.7 4.29091 12.9091 3.87273V2H11.0909V3.87273C7.3 4.29091 4.29091 7.3 3.87273 11.0909H2V12.9091H3.87273C4.29091 16.7 7.3 19.7091 11.0909 20.1273V22H12.9091V20.1273C16.7 19.7091 19.7091 16.7 20.1273 12.9091H22V11.0909H20.1273ZM12 18.3636C8.48182 18.3636 5.63636 15.5182 5.63636 12C5.63636 8.48182 8.48182 5.63636 12 5.63636C15.5182 5.63636 18.3636 8.48182 18.3636 12C18.3636 15.5182 15.5182 18.3636 12 18.3636Z"
          />
        </svg>

        <span class="track-me">{{ 'find_my_location' | translate }}</span>
      </button>
    </div>
  </main>
</div>
